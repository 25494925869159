import React from 'react'
import { useSelector } from 'react-redux';
import Stat from '../../components/Stat'
import { CLIENTS_PATH, DEPENSE_PATH, FOURNISSEURS_PATH, PRODUITS_PATH, VENTES_PATH } from '../../utils/navigation_paths';
import { useMemo } from 'react';

const paths = {
    clients: CLIENTS_PATH,
    fournisseurs: FOURNISSEURS_PATH,
    produits: PRODUITS_PATH,
    ventes: VENTES_PATH,
    depenses: DEPENSE_PATH
}

const AgenceKpi = () => {
    const { currentAgence } = useSelector(state => state.agences)
    // const { allListClients } = useSelector((state) => state.clients) || [];
    // const { allFournisseur } = useSelector(state => state.fournisseurs) || []
    // const { listProductsAll } = useSelector(state => state.produits) || []
    const listVentes = useSelector((state) => state.ventes?.listVente) || [];
    const { allListDepenses } = useSelector(state => state.depenses) || []
    const venteTotal = listVentes.reduce((total, currentVente) => {
        return total + currentVente.montantTotal
    }, 0)

    // console.log({ currentAgence: currentAgence });
    // console.log({ currentAgence: Object.entries(currentAgence.kpi) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const statsData = useMemo(() => {
        // const kpis = Object.entries(currentAgence.kpi).map(kpi => {
        //     // console.log({ kpi });
        //     return { [kpi[0]]: kpi[0], valeur: kpi[1], link: paths.clients }
        // })
        // console.log({ kpis });
        return [
            {
                label: "Clients",
                valeur: currentAgence?.kpi['clients'] ?? 0,
                link: CLIENTS_PATH
            },
            {
                label: "Fournisseurs",
                valeur: currentAgence?.kpi['fournisseurs'] ?? 0,
                link: FOURNISSEURS_PATH
            },
            {
                label: "Produits",
                valeur: currentAgence?.kpi['produits'] ?? 0,
                link: PRODUITS_PATH
            },
            {
                label: "VENTES",
                valeur: venteTotal + ' CFA',
                link: VENTES_PATH
            },
            {
                label: "DEPENSES",
                valeur: allListDepenses?.length ?? 0,
                link: DEPENSE_PATH
            },
        ];
    },)

    return (
        <div className='row'>
            {statsData?.map((item, key) => (
                // <Link className="" to={item?.link}>
                <Stat key={key} {...item} />
                // </Link>
            ))}
        </div>
    )
}

export default AgenceKpi