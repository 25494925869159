import { createSlice } from "@reduxjs/toolkit/"
import { commandesGenerator } from "../../utils/data_generator"

let data = commandesGenerator(100)
let initialState = {
    currentCommande: {},
    listCommandes : data || [],
    listCommandesAll : data || []
}
const commandesSlice = createSlice({
    name:'commandes',
    initialState,
    reducers:{
        setCurrentCommande:(state,{payload})=>{
            state.currentCommande = payload
        },
        setCommandesList:(state,{payload})=>{
            state.listCommandes = payload
            state.listCommandesAll = payload
        },
        resetCurrentCommande:(state,{payload})=>{
            state.currentCommande = {}
        },
        filterCommandesBySearch:(state,{payload})=>{
            state.listCommandes = state.listCommandesAll.filter(item =>{
                let fields = payload.fields.map((field)=> item[field])
                
                let data = fields.join(' ').toLowerCase()
                return data.includes(payload.value.toLowerCase())
            })
        },
    }
})

export const { setCurrentCommande,setCommandesList,resetCurrentCommande,filterCommandesBySearch} = commandesSlice.actions
export default commandesSlice.reducer