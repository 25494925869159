import React from "react";
import { Link, useNavigate } from "react-router-dom";
import icon from "../../images/icons/icon.png";
// import './LoginPage.css';
import { ACCUEIL_PATH, MDPOUBLIE_PATH } from "../../utils/navigation_paths";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { FiAlertTriangle, FiEye, FiEyeOff } from "react-icons/fi";
import ApiController from "../../redux/api_controller/users";
import { useState } from "react";
import BtnLoading from "../../components/BtnLoading";

const LoginPage = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [unauthoriezed, setUnAuthorized] = useState("");
  const [visiblePassword, setVisiblePassword] = useState(false);

  const loginSubmit = (data) => {
    setLoading(true);
    ApiController.loginUser(data, dispatch)
      .then((user) => {
        // console.log({ user });
        if (user?.status === "PROPRIETAIRE") {
          navigate(ACCUEIL_PATH);
          return;
        } else {
          setUnAuthorized("vous n'etes pas autorise !");
          return;
        }
        // }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          setError("phone", {
            type: "manual",
            message: err.response.data.message,
          });
          setError("password", {
            type: "manual",
            message: err.response.data.message,
          });
          return;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="login-page m-0 p-0">
      {/* <div className='row login-top-bar m-0 p-0 shadow'>
                <div className='col me-auto text-start d-flex flex-row'>
                    <img src={fewnublue} alt="" />
                    <p className='custom-separator-bar-secondary barre my-2'></p>
                    <b className='custom-color-primary ps-2 pt-2 mt-2 font-Montserrat'>
                        <p className='custom-separator-bar-secondary'></p>
                        Compta & Facturation
                    </b>
                </div>
                <p className='col-3 d-none d-md-block text-end me-md-3 pt-2'>
                    <Link className='custom-color-primary text-decoration-none font-Montserrat' to="/register" >Inscrivez-vous!</Link>
                </p>
            </div> */}
      <div className="row m-0 p-0 justify-content-center">
        <div className="row  justify-content-center">
          <div className="row justify-content-center">
            <img
              className="image-fewnu-login"
              src={icon}
              alt=""
              style={{ width: 200 }}
            />
          </div>
          <div className="row">
            <hr className="login-hr" style={{ width: 250, marginBottom: 10 }} />
            <p className="text-white text-center fs-3 font-Montserrat">
              Compta & Facturation
            </p>
            <p className="text-center text-error">{unauthoriezed}</p>
          </div>
        </div>
        <div className="row mt-4 justify-content-center">
          <p className=" text-white text-center font-Montserrat">
            Connectez-vous et gérez votre business!
          </p>

          <form
            className="row m-0 p-0 justify-content-center"
            onSubmit={handleSubmit(loginSubmit)}
          >
            <div className="col-10 col-sm-8 col-md-6 col-lg-4">
              <input
                {...register("phone", {
                  required: "Veuillez saisir votre numéro téléphone",
                })}
                type="tel"
                placeholder="Numéro téléphone"
                className={"mt-1"}
              />
              {errors.phone && (
                <p className="text-error">
                  <FiAlertTriangle /> {errors.phone.message}
                </p>
              )}
              <div
                className="mt-4"
                style={{ display: "flex", alignItems: "center", gap: 10 }}
              >
                <input
                  {...register("password", {
                    required: "Veuillez saisir un mot de passe",
                  })}
                  type={visiblePassword ? "text" : "password"}
                  placeholder="Password"
                />
                <div
                  style={{
                    height: 41.5,
                    width: 50,
                    backgroundColor: "#fff",
                    display: "grid",
                    placeItems: "center",
                    border: "2px solid gray",
                    cursor: "pointer",
                  }}
                  onClick={() => setVisiblePassword((v) => !v)}
                >
                  {visiblePassword ? <FiEye /> : <FiEyeOff />}
                </div>
              </div>
              {errors.password && (
                <p className="text-error">
                  <FiAlertTriangle /> {errors.password.message}
                </p>
              )}

              <p className="text-end mt-3">
                <Link
                  className="text-end text-white custom-mouse-clickable text-deoration-none"
                  to={MDPOUBLIE_PATH}
                >
                  Mot de passe oublié?
                </Link>
              </p>
              <BtnLoading
                bgColor="#FFFFFF"
                color="#00BEC9"
                right={30}
                type="submit"
                loading={loading}
                text="Connexion"
              />
              <p
                style={{ fontWeight: "300" }}
                className="text-white text-end d-block mt-3"
              >
                Pas de compte?{" "}
                <Link
                  className="text-white fw-bold text-decoration-non"
                  to="/register"
                >
                  Inscrivez-vous!
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
