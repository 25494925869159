import React from 'react'
import ResourceDataMap from '../shared/ResourceDataMap';
import Skeleton from './Skeleton';

const ResourceDataMapperWithEmptyMessage = ({
    isLoading,
    resourceItem: ResourceItem,
    resourceName,
    resourceData = [],
    emptyMessage
}) => {
    return (
        <>
            {
                isLoading ? [1, 2, 3, 4, 5, 6].map((item) => <Skeleton key={item} />) :
                    resourceData.length ? <ResourceDataMap
                        resourceItem={ResourceItem}
                        resourceName={resourceName}
                        resourceData={resourceData}
                    /> : <p>{emptyMessage}</p>
            }
        </>
    )
}

export default ResourceDataMapperWithEmptyMessage
