import React from 'react'
// import ReactTooltip from 'react-tooltip'
// import tooltip_options from '../../../utils/tooltip_options'
import { useSelector } from 'react-redux'
import { DEFAULT_IMAGE_API } from '../../routes/api_routes'

const ProductFactureListItem = ({ product, addNewProductToFactureArticles }) => {
    const { disableProduct, isFactureForDepense } = useSelector(state => state.factureArticles)
    const { name, image, prixVente, prixAchat } = product


    // console.log({ product });

    return (
        <>
            <button
                disabled={disableProduct}
                data-tip
                data-for="venteArticle"
                style={{
                    // minWidth: '120px',
                    // maxWidth: 'max-content',
                    width: '100%',
                    height: '195px',
                    cursor: !disableProduct ? 'pointer' : 'default',
                    opacity: disableProduct ? 0.5 : 1,
                    border: 'none',
                    boxSizing: 'border-box'
                    // flex: '1',
                    // flexBasis: '100px'

                }}
                className='img-article'
                onClick={() => addNewProductToFactureArticles(product)}
            >
                <div style={{ height: '100%', border: 'none !important' }} className='card pb-2 d-flex flex-column justify-content-between'>
                    {/* width: 100px;
                    aspect-ratio: 3/2;
                    object-fit: contain;
                    mix-blend-mode: color-burn; */}


                    <div
                        style={{
                            height: '130px'
                        }}
                        className='card-img position-relative p-2'
                    >
                        <img
                            src={`${DEFAULT_IMAGE_API + image}`}
                            // onError={ }
                            alt={name}
                            style={{
                                height: '100%',
                                // aspectRatio: '3/2',
                                width: '100%',
                                objectFit: 'cover',
                                // mixBlendMode: 'color-burn'
                            }}
                            className='img-fluid'
                        />
                        {
                            !disableProduct ? <p className='add-to-articles'>Ajouter</p> : null
                        }
                    </div>
                    <div style={{ lineHeight: '18px' }} className='px-2'>
                        <p style={{ fontSize: '14px', lineHeight: '15px' }} className='truncate-title m-0'>{name}</p>
                        <p style={{ fontSize: '10px !important', fontWeight: '600' }} className='m-0'>{isFactureForDepense ? prixAchat : prixVente} F CFA</p>
                    </div>
                </div>
            </button>
            {/* <ReactTooltip className={'tooltip-product'} id="venteArticle" {...tooltip_options}  >
                Ajouter
            </ReactTooltip> */}
        </>
    )
}

export default ProductFactureListItem