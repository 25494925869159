import React from 'react'
import icon from '../images/icons/icon1.png'

const EmptyList = ({message}) => {
  return (
    <div className='w-50 mx-auto text-center'>
        <img className='img-fluid w-25' src={icon} alt="Fewnu" />
      <p style={{ fontWeight: '300' }} className='text-center'>{message}</p>
    </div>
  )
}

export default EmptyList