import React from "react";
import { Link } from "react-router-dom";

function Stat(props) {
  return (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
      <Link
        style={{ textDecoration: "none", height: "100%" }}
        className="d-block"
        to={props?.link}
      >
        <div className="card card-boutique h-100 shadow p-0">
          <div className="card-body">
            <div className="row">
              <div className="col-10">
                <div className="text-xs font-weight-bold color-white text-uppercase">
                  {props?.label}
                </div>
                <div className="para">{props?.valeur}</div>
              </div>
              <div className="col-2">
                {props.icon}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default Stat;
