import React, { useEffect, useState } from 'react'
import FactureArticles from '../../components/facture/FactureArticles';
import ApiController from '../../redux/api_controller';
import { toast } from 'react-toastify';
import { resetFactureSelectedFournisseur } from '../../redux/reducers/factureArticles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAgenceId } from '../../hooks/useAgenceId';
import CurrentUser from '../../hooks/CurrentUser';
import BtnLoading from '../../components/BtnLoading';
import SelectFactureFournisseur from '../../components/facture/fournisseur/SelectFactureFournisseur';

const AddDepenseFactue = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [isProductLoading, setIsProductLoading] = useState(false)
    let { agenceId } = useAgenceId()
    const dispatch = useDispatch()
    const { currentFacture, selectedFactureFournisseur, disableProduct } = useSelector(state => state.factureArticles)
    const currentUser = CurrentUser()

    useEffect(() => {
        setIsProductLoading(true)
        ApiController.produits.getAgenceProducts(agenceId).then((res) => {
            // console.log({ res });
        }).finally(() => {
            setIsProductLoading(false)
        })
    }, [agenceId, dispatch])

    useEffect(() => {
        dispatch(resetFactureSelectedFournisseur())
    }, [])

    const confirmFacture = () => {
        const articles = currentFacture.map(article => {
            const data = {
                productId: Number(article.product.id),
                quantity: Number(article.quantity),
                price: article.product.prixAchat
            }
            return data
        })

        const newDepense = {
            fournisseurId: selectedFactureFournisseur ? selectedFactureFournisseur.value : '',
            createdBy: currentUser.id,
            agenceId,
            articles
        }

        // console.log({ newDepense });

        setLoading(true)
        ApiController.depenses.createNewDepense(newDepense, dispatch).then(res => {
            toast.success('Dépense effectuée')
            navigate(-1)
        }).catch(error => {
            toast.error('Une erreur est survenu')
        })
            .finally(() => {
                setLoading(false)
            })

    }

    return (
        <div>
            <div className="text-start mb-3">
                <p className='fs-4 px-md-0 fw-bold'>Nouvelle facture de dépense</p>
            </div>
            <FactureArticles
                loadingResource={isProductLoading}
            >
                <SelectFactureFournisseur />
                <div className='d-flex gap-2'>
                    <BtnLoading
                        onClick={() => navigate(-1)}
                        bgColor="#bc3433"
                        color="#FFFFFF"
                        right={30}
                        loading={false}
                        text="Annuler"
                    />
                <BtnLoading
                    onClick={confirmFacture}
                    bgColor="#00BEC9"
                    color="#FFFFFF"
                    right={30}
                    loading={loading}
                    text="Enregistrer"
                />
                </div>
            </FactureArticles>
        </div>
    )
}

export default AddDepenseFactue