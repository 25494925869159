import React from 'react'
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { optionsStyle } from '../../utils/reactSelectOptions';

const AddFactureSelectForm = ({
    title,
    handleSubmit,
    value,
    onChange,
    error,
    firstCondition,
    secondCondition,
    secondConditionProove,
    concerned,
    placeholder,
    options, modal }) => {
    const { register, formState: { errors }, setError } = useForm()
    return (
        <form onSubmit={handleSubmit} className="d-flex justify-content-start mt- p-0">
            <div className="w-100">
                {/* <label className='row m-0 p-0'> */}
                {/* <span>{title}</span> */}
                <Select
                    // classNames="p-3"
                    // styles={{padding: '1rem'}}
                    styles={{ ...optionsStyle, width: '100%' }}
                    classNamePrefix="p-2"
                    autoFocus
                    placeholder={placeholder}
                    {...register(concerned)}
                    value={value}
                    onChange={onChange}
                    options={options}
                />
                {error}
                {modal}
                {/* </label> */}
            </div>
            {/* {
        !firstCondition &&
        <div className='col-12 col-sm-4 col-lg-2 text-centr text-md-start'>
          <button className='custom-button-secondary rounded-0 py-2 px-2 ms-sm-2 mt-sm-4 mt-2'>Enregistrer</button>
        </div>
      }
      {
        secondCondition && secondConditionProove &&
        <div className='col-12 col-md-3 col-lg-2 text-centr text-md-start'>
          <button className='custom-button-secondary rounded-0 py-2 px-2 ms-2 mt-4'>Enregistrer</button>
        </div>
      } */}
        </form>
    )
}

export default AddFactureSelectForm