import React, { cloneElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FilterProduct from './FilterProduct'
import Select from 'react-select';
// import { useForm } from 'react-hook-form'
import { AiOutlinePlusCircle } from 'react-icons/ai'
// import AddProduitsModal from '../../produits/AddProduitsModal'
// import ReactTooltip from 'react-tooltip'
import ResourceDataMapperWithEmptyMessage from './ResourceDataMapperWithEmptyMessage'
import ProductFactureListItem from './ProductFactureListItem';
import { tooltip_options } from '../../utils/tooltip_options';
import { optionsStyle } from '../../utils/reactSelectOptions';
import { addNewArticlesToCurrentFacture, setSelectedCategoryForProductSearch } from '../../redux/reducers/factureArticles';
import AjoutProduit from '../../pages/produits/AjoutProduitModal';

const AddProductToArticlesFacture = ({ createNewFactureArticle, loadingResource }) => {
    const dispatch = useDispatch()
    const [addModalIsOpen, setAddModalIsOpen] = useState(false)
    const { productsNotAddedToCurrentFacture, isUpdatingFacture, selectedCategoryForProductSearch } = useSelector(state => state.factureArticles)
    const listCategories = useSelector(state => state.categories.listCategories) || []
    // const { register } = useForm()
    // console.log({ productsNotAddedToCurrentFacture });
    // console.log({ isUpdatingFacture });
    const addNewProductToFactureArticles = (product) => {
        if (isUpdatingFacture) {
            return createNewFactureArticle(product)
        }
        dispatch(addNewArticlesToCurrentFacture(product))
    }

    const addNewCategory = () => {
        setAddModalIsOpen(true)
    }
    const categoriesOptions = listCategories?.map(category => {
        return { value: category.id, label: category.categoryName }
    })
        .concat([{ value: null, label: 'Toutes les catégories' }])
        .reverse();

    const ClonedProductFactureListItem = ({ product }) => cloneElement(<ProductFactureListItem product={product} />, { addNewProductToFactureArticles })

    return (
        <div className='pt-0 card'>
            {/* <AddProduitsModal modalIsOpen={addModalIsOpen} setIsOpen={setAddModalIsOpen} /> */}
            <AjoutProduit
                modalIsOpen={addModalIsOpen}
                setModalIsOpen={setAddModalIsOpen}
            />
            {/* <AddCategoryModal modalIsOpen={categoryModalIsOpen} setIsOpen={setAddModalIsOpen}></AddCategoryModal> */}
            <h5 className='p-2 py-3 m-0 bg-light text-start fs-6 fw-400'>Section Produit</h5>
            <div className='d-flex flex-wrap gap-3 columns-gap-2 p-2 '>
                <div style={{ flex: '2', minWidth: '250px' }} className=''>
                    <Select
                        styles={optionsStyle}
                        classNamePrefix="p-2"
                        placeholder="--Sélectionner la catégorie--"
                        autoFocus
                        value={selectedCategoryForProductSearch}
                        onChange={(value) => dispatch(setSelectedCategoryForProductSearch(value))}
                        options={categoriesOptions}
                    />
                </div>
                <div style={{ flex: '1' }} className='d-flex gap-2'>
                    <div style={{ flex: 1, minWidth: '180px' }} className=''>
                        <FilterProduct />
                    </div>
                    <div className=''>
                            <button
                                onClick={addNewCategory}
                                type="button"
                                className='btn btn-add rounded-circle p-2 py1 w-100'
                            >
                                <AiOutlinePlusCircle
                                    data-tip
                                    data-for="addProduct"
                                    size={23}
                                />
                            </button>
                            {/* <ReactTooltip id="addProduct" {...tooltip_options}  >
                                Nouveau produit
                            </ReactTooltip> */}
                    </div>
                </div>
            </div>
            <div className='articles-grid card-body p-2'>
                <ResourceDataMapperWithEmptyMessage
                    isLoading={loadingResource}
                    resourceItem={ClonedProductFactureListItem}
                    resourceName="product"
                    resourceData={productsNotAddedToCurrentFacture}
                    emptyMessage="Pas encore de produit"
                />
            </div>
        </div>
    )
}

export default AddProductToArticlesFacture