import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import AjouterGerant from '../pages/gerants/AjouterGerant';
import ApiController from '../redux/api_controller';
import AgenceGerantInfos from '../pages/agences/components/AgenceGerantInfos';
import ResourceDataMap from './shared/ResourceDataMap';
import { useEffect } from 'react';
import { useAgenceGerant } from '../hooks/useAgenceGerant';
import SelectGerant from '../pages/agences/components/SelectGerant';

const AgenceDetailsBanner = ({ banner, nomAgence, fullName, phone, email, adresse }) => {

  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const { listgerant: listGerants } = useSelector(state => state.gerants)
  const currentGerant= useSelector(state => state.gerants.currentgerant)
  const [selectedGerantId, setSelectedGerantId] = useState(null)
  const [gearntInfo, setGerantInfo] = useState(null)
  const { currentAgence } = useSelector(state => state.agences)
  const { gerant } = useAgenceGerant()
  // console.log({ gerant });

  const [isLoading, setisLoading] = useState(false)

  function handleGerantSelect(event) {
    setSelectedGerantId(event.target.value);
  }

  // console.log({ currentAgence });

  function openAddModal() {
    setAddModalIsOpen(true);
  }

  useEffect(() => {
    ApiController.agences.getCurrentAgence(currentAgence.id)
  }, [])

  const handleGerant = (e) => {
    // console.log({ e: e.target.value });
    const gerantId = Number(e.target.value)
    let agenceId = currentAgence.id
    ApiController.gerants.updategerant(gerantId, { agenceId }).then((res) => {
      // console.log("gerant reponse ", res)
      setGerantInfo(res.data)
      setisLoading(true)
    }).catch((err) =>console.log(err))
  }


  const gerantData = {
      fullName,
      phone,
      email,
      adresse
  }

  const hasGerant = gearntInfo ? gearntInfo : !!fullName ? gerantData : null; // Vérifie si un nom de gérant est présent


  return (
    <div style={{ background: `linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(10,0,5,0.5)  50%, rgba(40,35,30,0.1) 100%), url(${banner})`, backgroundPosition: 'center', backgroundSize: 'cover' }} className='agence-banner position-relative p-2 d-flex flex-column justify-content-between'>
      <h1 className='text-light'>Bienvenue sur <span style={{ color: '#00BEC9' }}>{nomAgence}</span></h1>
      <div className='d-flex'>
        <div className='agence-logo--container shadow'>
          <img src={banner} alt="Agence" />
        </div>
        <div className='agence_gerant--description'>
          {gerant ? (
            <>
              <AgenceGerantInfos
                gerant={
                  gerant
                }
              />
            </>
          ) : (
              <SelectGerant onChange={(e) => handleGerant(e)} />
            // <div className="d-flex align-items-center mb-2">
            //     <select
            //       className="form-select me-2"
            //       onChange={(e) => handleGerant(e)}
            //     >
            //       <option value="">Affecter à un gérant</option>
            //       <ResourceDataMap
            //         resourceItem={({ gerant }) => <option value={gerant.id}>{gerant.fullName}</option>}
            //         resourceName="gerant"
            //         resourceData={listGerants}
            //       />
            //     </select>
            //   </div>
          )}
        </div>
      </div>
    </div>
  )
}


export default AgenceDetailsBanner