import React, { useState } from "react";
// import './RegisterPage.css';
import { useForm } from "react-hook-form";
import { FiAlertTriangle, FiEye, FiEyeOff } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ApiController from ".././../redux/api_controller";
import { useDispatch } from "react-redux";
import icon from "../../images/icons/icon.png";
import BtnLoading from "../../components/BtnLoading";

const RegisterPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);

  const registerSubmit = (data) => {
    if (data.password !== data.confirmpassword) {
      setError("confirmpassword", {
        type: "manual",
        message: "Les deux mot de passe ne correspondent pas!",
      });
      return;
    }
    // !start loading
    setIsLoading(true);
    let user = {
      phone: data.phone,
      password: data.password,
      email: data.email,
      fullName: data.firstName + " " + data.lastName,
      raisonSocial: null,
      adresse: "null",
      region: null,
      ville: null,
    };
    ApiController.users
      .registerUser(user, dispatch)
      .then((response) => {
        navigate("/login");
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 400) {
          if (err?.response?.data?.message?.phone) {
            return setError("phone", {
              type: "manual",
              message: "Le numéro de telephone existe déjà",
            });
          }
          if (err?.response?.data?.message?.email) {
            return setError("email", {
              type: "manual",
              message: "L'email' existe déjà",
            });
          }
          return;
        }
      })
      .finally(() => {
        // !end loading
        setIsLoading(false);
      });
  };

  return (
    <div className="row register-page m-0 p-0">
      {/* <div className="row login-top-bar m-0 p-0 shadow">
        <div className="col me-auto text-start d-flex flex-row">
          <img src={fewnublue} alt="" />
          <p className="custom-separator-bar-secondary barre my-2"></p>
          <b className="custom-color-primary ps-2 pt-2 mt-2 font-Montserrat">
            Compta & Facturation
          </b>
        </div>
      </div> */}
      <div className="row mt-4 justify-content-center">
        <div className="row  justify-content-center">
          <div className="row justify-content-center">
            <img
              className="image-fewnu-login"
              src={icon}
              alt=""
              style={{ width: 200 }}
            />
          </div>
          <div className="row">
            <hr className="login-hr" style={{ width: 250, marginBottom: 10 }} />
          </div>
        </div>
        <div className="row">
          <p className="fs-1 text-white text-center">Inscrivez-vous!</p>
        </div>
        <form
          onSubmit={handleSubmit(registerSubmit)}
          className="row m-0 p-0 justify-content-center register mb-5"
        >
          <div className="col-10 col-sm-8 col-md-6 col-lg-4">
            <input
              {...register("firstName", {
                required: "Veuillez saisir votre prénom",
              })}
              type="text"
              placeholder="Prénom"
              className=""
            />
            {errors.firstName && (
              <p className="text-error">
                <FiAlertTriangle /> {errors.firstName.message}
              </p>
            )}

            <input
              {...register("lastName", {
                required: "Veuillez saisir votre nom",
              })}
              type="text"
              placeholder="Nom"
              className=" mt-3"
            />
            {errors.lastName && (
              <p className="text-error">
                <FiAlertTriangle /> {errors.lastName.message}
              </p>
            )}

            <input
              {...register("phone", {
                required: "Veuillez saisir votre numéro",
              })}
              type="tel"
              placeholder="Numéro de téléphone"
              className=" mt-3"
            />
            {errors.phone && (
              <p className="text-error">
                <FiAlertTriangle /> {errors.phone.message}
              </p>
            )}

            {/* <input {...register("adresse",{required:'Veuillez saisir votre adresse'})} type="text" placeholder='Adresse' className=' mt-3'/>
                        {errors.adresse && <p className='text-error'><FiAlertTriangle/> {errors.adresse.message}</p>}
                         */}
            <input
              {...register("email", { required: "Veuillez saisir un email" })}
              type="email"
              placeholder="Email"
              className=" mt-3"
            />
            {errors.email && (
              <p className="text-error">
                <FiAlertTriangle /> {errors.email.message}
              </p>
            )}
            {/* 
                        <input {...register("raisonSocial",{required:'Veuillez saisir votre raison sociale'})} type="text" placeholder='Votre raison socilae' className=' mt-3'/>
                        {errors.email && <p className='text-error'><FiAlertTriangle/> {errors.email.message}</p>}

                        <input {...register("region",{required:'Veuillez saisir votre region'})} type="text" placeholder='Votre region' className=' mt-3'/>
                        {errors.email && <p className='text-error'><FiAlertTriangle/> {errors.email.message}</p>}

                        <input {...register("ville",{required:'Veuillez saisir votre ville'})} type="text" placeholder='Votre ville' className=' mt-3'/>
                        {errors.email && <p className='text-error'><FiAlertTriangle/> {errors.email.message}</p>} */}

            <div
              className="mt-3"
              style={{ display: "flex", alignItems: "center", gap: 10 }}
            >
              <input
                {...register("password", {
                  required: "Veuillez saisir un mot de passe",
                })}
                type={visiblePassword ? "text" : "password"}
                placeholder="Password"
              />
              <div
                style={{
                  height: 41.5,
                  width: 50,
                  backgroundColor: "#fff",
                  display: "grid",
                  placeItems: "center",
                  border: "2px solid gray",
                  cursor: "pointer",
                }}
                onClick={() => setVisiblePassword((v) => !v)}
              >
                {visiblePassword ? <FiEye /> : <FiEyeOff />}
              </div>
            </div>
            {errors.password && (
              <p className="text-error">
                <FiAlertTriangle /> {errors.password.message}
              </p>
            )}

            <div
              className="mt-3"
              style={{ display: "flex", alignItems: "center", gap: 10 }}
            >
              <input
                {...register("confirmpassword", {
                  required: "Veuillez confirmer le mot de passe",
                })}
                type={visibleConfirmPassword ? "text" : "password"}
                placeholder="Confirmer le mot de passe"
              />
              <div
                style={{
                  height: 41.5,
                  width: 50,
                  backgroundColor: "#fff",
                  display: "grid",
                  placeItems: "center",
                  border: "2px solid gray",
                  cursor: "pointer",
                }}
                onClick={() => setVisibleConfirmPassword((v) => !v)}
              >
                {visibleConfirmPassword ? <FiEye /> : <FiEyeOff />}
              </div>
            </div>
            {errors.confirmpassword && (
              <p className="text-error">
                <FiAlertTriangle /> {errors.confirmpassword.message}
              </p>
            )}

            <div className="mt-3">
              <BtnLoading
                bgColor="#FFFFFF"
                color="#00BEC9"
                right={30}
                type="submit"
                loading={isLoading}
                text="Inscription"
              />
            </div>
            <p className="text-white my-3 text-end">
              Vous avez déjà un compte.{" "}
              <Link to="/login" className="fw-bold text-white">
                Connectez-vous
              </Link>
            </p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RegisterPage;
