import React, { useEffect, useMemo, useState } from 'react'
import ConfirmAction from '../../components/ConfirmAction'
import FactureDetails from '../../components/facture/details/FactureDetails'
import { UPDATE_VENTE_PATH } from '../../utils/navigation_paths'
import ApiController from '../../redux/api_controller'
import { toast } from 'react-toastify'
import { setFactureSelectedFournisseur, setIsUpdatingFacture } from '../../redux/reducers/factureArticles'
import { useAgenceName } from '../../hooks/useAgenceName'
import { useAgenceId } from '../../hooks/useAgenceId'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const DepenseDetailsPage = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loadingFacture, setLoadingFacture] = useState(false)
    const { currentDepense, currentDepenseDetails } = useSelector(state => state.depenses)
    const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);
    let factureDate = new Date(currentDepense?.created_at)
    let total = currentDepenseDetails?.montantTotal || 0
    const depenseTableFormat = currentDepenseDetails?.articles
    const { agenceId: idAgence } = useAgenceId()
    const { nomAgence } = useAgenceName()

    console.log({ currentDepense });

    useEffect(() => {
        dispatch(setIsUpdatingFacture(false))
        // dispatch(initializeCurrentDepenseDetails())
        setLoadingFacture(true)
        ApiController.depenses.getDepenseByID(currentDepense.id, dispatch).finally(() => {
            setLoadingFacture(false)
        })
    }, []);

    function openPaymentModal() {
        setPaymentModalIsOpen(true);
    }

    const columns = useMemo(() => [
        {
            dataField: "product.name",
            text: "Désignation",
            headerClasses: 'custom-table-column text-white'
        },
        {
            dataField: "quantity",
            text: "Quantité",
            headerClasses: 'custom-table-column text-white'
        },
        {
            dataField: "product.prixVente",
            text: "Prix Unitaire",
            headerClasses: 'custom-table-column text-white'
        },
        {
            dataField: "montant",
            text: "Total",
            headerClasses: 'custom-table-column text-white',
        }
    ], [])

    const onEdit = () => {
        // console.log({ currentDepense });
        // ! on active la mise a jour
        dispatch(setIsUpdatingFacture(true))
        if (currentDepense.fournisseur) {
            let fournisseurId = currentDepense.fournisseur.id
            let fullName = currentDepense.fournisseur.firstName + ' ' + currentDepense.fournisseur.lastName
            // ! we need the selected fournisseur to show in the select form when we edit
            // dispatch(setSelectedfournisseur({ value: fournisseurId, label: fullName }))
            dispatch(setFactureSelectedFournisseur({ value: fournisseurId, label: fullName }))
        }
        navigate(`/agences/${idAgence}/${nomAgence}/depenses/update`)
    }

    const payerVente = () => {
        // setLoading(true);
        let data = { status: "PAYE" };
        ApiController.depenses.updateDepense(currentDepense.id, data, dispatch)
            .then((response) => {
                ApiController.depenses.getDepenseByID(currentDepense.id)
                toast.success("Facture payée avec succès");
            })
            .catch((err) => {
                console.log(err);
                // closeModal();
                toast.error("La payment n'a pas réuissi");
            })
            .finally(() => {
                setPaymentModalIsOpen(false)
            });
    };

    return (
        <div>
            <ConfirmAction
                modalText="Paiement vente"
                text={<p>Le montant total a payer est de <b>{total} XOF</b></p>}
                modalIsOpen={paymentModalIsOpen}
                cancel={() => setPaymentModalIsOpen(false)}
                closeModal={() => setPaymentModalIsOpen(false)}
                validate={payerVente}
            />
            <FactureDetails
                factureFor="Fournisseur"
                loadingFacture={loadingFacture}
                dataTable={depenseTableFormat}
                columns={columns}
                // factureProgressBar={FactureProgressBar}
                transactionStatus={currentDepense.status}
                totalTransaction={currentDepense?.montantTotal || 0}
                // logo={currentCompany.logo}
                fullName={currentDepense.fournisseur?.firstName ? currentDepense.fournisseur?.firstName + ' ' + currentDepense?.fournisseur?.lastName : "......................................."}
                phone={currentDepense.fournisseur?.telephone ?? '.............................'}
                // messageOnEmptyFullName="Pas de client"
                factureDate={factureDate ?? '.....................'}
                numbreJoursRestants={0}
                navigateToEdit={UPDATE_VENTE_PATH}
                currentTransactionId={currentDepense.id}
                transactionDate={currentDepense.date}
                openPaymentModal={openPaymentModal}
                onEdit={onEdit}
            />
        </div>
    )
}

export default DepenseDetailsPage