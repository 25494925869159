import React from 'react'
import { NavLink } from 'react-router-dom'
import { AGENCE_PARAMETRE_PATH, CLIENTS_PATH, DEPENSE_PATH, FOURNISSEURS_PATH, PRODUITS_PATH, VENTES_PATH } from '../utils/navigation_paths'
import { useMemo } from 'react'
import ResourceDataMap from './shared/ResourceDataMap';

const AgenceTab = () => {

    const tabs = useMemo(() => {
        return [
            {
                to: '',
                text: "Acceuil"
            },
            {
                to: CLIENTS_PATH,
                text: "Clients"
            },
            {
                to: FOURNISSEURS_PATH,
                text: "Fournisseurs"
            },
            {
                to: PRODUITS_PATH,
                text: "Produits"
            },
            {
                to: VENTES_PATH,
                text: "Ventes"
            },
            {
                to: DEPENSE_PATH,
                text: "Dépenses"
            },
            {
                to: AGENCE_PARAMETRE_PATH,
                text: "Paramètres"
            }
        ].reverse()
    }, [])

    return (
        <header className='agence-tab shadow ps-5'>
            <ul style={{ marginLeft: '5rem' }} className='d-flex align-items-center py-3'>
                <ResourceDataMap
                    resourceData={tabs}
                    resourceName="item"
                    resourceItem={({ item }) => <li>
                        <NavLink to={item.to} end>{item.text}</NavLink>
                    </li>}
                />
            </ul>
        </header>
    )
}

export default AgenceTab