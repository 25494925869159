import axios from "axios"
import { ADD_PRODUCT_API_ROUTE, DELETE_PRODUCT_API_ROUTE, GET_PRODUCTS_BY_AGENCE_API_ROUTE, UPDATE_PRODUCT_API_ROUTE } from "../../routes/api_routes"
import { addNewProduct, pushToProductList, setProductsList } from "../reducers/products"
import { store } from "../store/store"
import { addNewProductToArticles, setProductsNotAddedToCurrentVente } from "../reducers/factureArticles"

const dispatch = store.dispatch
const produits = {
    getProductsByCreatedBy(createdBy) {
        return new Promise((resolve, reject) => {
            axios.get('' + createdBy + '/')
                .then(response => {
                    if (response.status === 200) {
                        let produits = response.data
                        dispatch(setProductsList(produits))
                        resolve(produits)
                    } else {
                        reject(response)
                    }
                })
                .catch(res => reject(res))
        })
    },
    getProductsListByAgence(idsAgence, createdBy) {
        /**
         * !This api call is different to others because it gets all produits based on the agenceId.
         */
        idsAgence.forEach(agenceId => {
            console.log({ agenceId });
            return new Promise((resolve, reject) => {
                axios.get('************' `${agenceId}/`)
                    .then(response => {
                        if (response.status === 200) {
                            let produits = response.data
                            /**
                             * ! Instead of seting directly the clientList from the client reducer, we just have to push in this this
                             * !Then we filter to get only produits that are not created by "Proprietaire"
                            */
                            const productsThatAreNotAddedByProprietaire = produits.filter(client => {
                                return client.createdBy !== createdBy
                            })
                            // console.log({ productsThatAreNotAddedByProprietaire });
                            dispatch(pushToProductList(productsThatAreNotAddedByProprietaire))
                            resolve(produits)
                        } else {
                            reject(response)
                        }
                    })
                    .catch(res => reject(res))
            })
        })
    },
    getAgenceProducts(agenceId) {
        return new Promise((resolve, reject) => {
            axios.get(GET_PRODUCTS_BY_AGENCE_API_ROUTE + agenceId)
                .then(response => {
                    if (response.status === 200) {
                        let produits = response.data.data
                        dispatch(setProductsList(produits))
                        dispatch(setProductsNotAddedToCurrentVente(produits))
                        resolve(produits)
                    } else {
                        reject(response)
                    }
                })
                .catch(res => reject(res))
        })
    },
    addProduct(data) {
        return new Promise((resolve, reject) => {
            axios.post(ADD_PRODUCT_API_ROUTE, data)
                .then(response => {
                    console.log({ response });
                    if (response.status === 201) {
                        let product = response.data.data
                        dispatch(addNewProduct(product))
                        dispatch(addNewProductToArticles(product))
                        resolve(product)
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },
    updateProduct(data, productId, agenceId) {
        return new Promise((resolve, reject) => {
            axios.put(`${UPDATE_PRODUCT_API_ROUTE}${productId}/`, data)
                .then(response => {
                    if (response.status === 200) {
                        let product = response.data.data
                        this.getAgenceProducts(agenceId)
                        resolve(product)
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },
    deleteProduct(productId, agenceId) {
        return new Promise((resolve, reject) => {
            axios.delete(`${DELETE_PRODUCT_API_ROUTE + productId}/`)
                .then(response => {
                    console.log({ response });
                    if (response.status === 204) {
                        resolve(response)
                        this.getAgenceProducts(agenceId)
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },
}
export default produits