import React from 'react'
import Input from '../../components/shared/Input'
import { useForm } from 'react-hook-form'
import BtnLoading from '../../components/BtnLoading'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import ApiController from '../../redux/api_controller'

const EditAgence = ({ setIsEdit }) => {
    const { currentAgence } = useSelector(state => state.agences)
    const [loading, setLoading] = useState(false)
    const { register, reset, formState: { errors }, handleSubmit, setError } = useForm({
        defaultValues: {
            nomAgence: currentAgence.nomAgence,
            phone: currentAgence.phone,
            email: currentAgence.email,
            addresse: currentAgence.addresse
        },
        values: {
            nomAgence: currentAgence.nomAgence,
            phone: currentAgence.phone,
            email: currentAgence.email,
            addresse: currentAgence.addresse
        }
    })

    console.log({ currentAgence });

    const editAgence = (data) => {
        // console.log({ data });
        setLoading(true)
        ApiController.agences.updateAgence({ data, agence_id: currentAgence?.id })
            .then(res => {
                // console.log({ res });
                setIsEdit(false)
            })
            .catch((err) => {
                console.log({ err });
            })
            .finally(() => {
                setLoading(false)
            })
        // setLoading(true)
    }

    return (
        <form
            onSubmit={handleSubmit(editAgence)}
        >
            <Input
                label="Nom Agence"
                type="text"
                htmlFor="nomAgence"
                register={{ ...register("nomAgence", { required: 'nomAgence' }) }}
                error={errors?.nomAgence}
                errorMessage={errors.phone?.nomAgence}
                className="col-12 mt-3"
            />
            <Input
                label="Téléphone"
                type="text"
                htmlFor="phone"
                register={{ ...register("phone", { required: 'phone' }) }}
                error={errors?.phone}
                errorMessage={errors.phone?.phone}
                className="col-12 mt-3"
            />
            <Input
                label="Email"
                type="email"
                htmlFor="email"
                register={{ ...register("email", { required: 'email' }) }}
                error={errors?.email}
                errorMessage={errors.phone?.email}
                className="col-12 mt-3"
            />
            <Input
                label="Adresse"
                type="text"
                htmlFor="addresse"
                register={{ ...register("addresse", { required: 'addresse' }) }}
                error={errors?.addresse}
                errorMessage={errors.phone?.addresse}
                className="col-12 mt-3"
            />
            <div className='mt-3'>
                <BtnLoading
                    bgColor="#00BEC9"
                    color="#FFFFFF"
                    right={30}
                    type="submit"
                    loading={loading}
                    text="Modifier"
                />
            </div>
        </form>
    )
}

export default EditAgence