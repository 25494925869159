import React from "react";
import { Link } from "react-router-dom";
import foto from "../../../images/foto.png";
import { dayJsFormat } from "../../../utils/dayJsFormat";
import { FiClock } from "react-icons/fi";
import { styles } from "../../../utils/styles";

const dayjs = require("dayjs");

dayJsFormat();

const AgenceCardItem = ({ agence, setCurrentAgenceFunc }) => {
  // console.log({ agence });
  const { id, nomAgence, addresse, phone, created_at } = agence;
  return (
    <div
      style={{ minHeight: "300px" }}
      key={id}
      className="col-12 col-sm-6 col-md-6 col-lg-3 gy-4"
      onClick={() => {
        setCurrentAgenceFunc(agence)
      }}
    >
      <Link
        style={{ height: "100%", display: "block" }}
        to={`${id}/${nomAgence}`}
      >
        <div
          className="card agences-card border-0 shadow"
          style={{ width: "100%", height: "100%" }}
        >
          <img src={foto} className="card-img-top" alt={nomAgence} />
          <div className="card-body">
            <h5 style={{ color: styles.primary }} className="card-title">
              {nomAgence}
            </h5>
            <p className="card-text">Adresse: {addresse}</p>
            <p className="card-text">Tel : {phone ?? ""}</p>
            <p className="card-text">
              <FiClock /> Créée il y a {dayjs(created_at).fromNow(true)}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default AgenceCardItem;
