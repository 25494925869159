import { createSlice } from "@reduxjs/toolkit/";
// import { gerantGenerator } from "../../utils/data_generator"

// let data = gerantGenerator(100)
/**
 * ! listgerant is used to display data in the ui
 * ! allListGerants is used for filtering and others stuff so that "gerant" data won't be lost
 */
let initialState = {
  currentgerant: {},
  listgerant: [],
  allListGerants: [],
  selectedGerant: null,
};
const gerantSlice = createSlice({
  name: "gerants",
  initialState,
  reducers: {
    setListgerants: (state, { payload }) => {
      state.listgerant = payload;
      state.allListGerants = payload;
    },
    addNewgerant: (state, { payload }) => {
      state.listgerant = [...state.allListGerants, payload];
      state.allListGerants = [...state.allListGerants, payload];
    },
    setCurrentgerant: (state, { payload }) => {
      state.currentgerant = payload;
      let listgerant = state.allListGerants.filter(
        (item) => item.id !== payload.id
      );
      state.listgerant = [...listgerant, payload];
    },
    resetCurrentgerant: (state, { payload }) => {
      state.currentgerant = payload;
    },
    deleteCurrentgerant: (state, { payload }) => {
      state.listgerant = state.allListGerants.filter(
        (item) => item.id !== payload
      );
      state.listgerant = state.allListGerants.filter(
        (item) => item.id !== payload
      );
    },
    filterGerantsList: (state, { payload }) => {
      state.listgerant = state.allListGerants.filter((item) => {
        let data = [item.fullName, item.adresse, item.email, item.phone]
          .join(" ")
          .toLowerCase();
        return data.includes(payload.toLowerCase());
      });
    },
    setSelectedGerant: (state, action) => {
      state.selectedGerant = action.payload;
    },
    blockGerant: (state, action) => {
    //   const gerantIndex = state.listgerant.findIndex(
    //     (gerant) => gerant.id === action.payload.id
    //   );
    //   if (gerantIndex !== -1) {
    //     state.listgerant[gerantIndex].is_active = false;
    //   }
      state.listgerant = state.listgerant.map((gerant) =>
        gerant.id === action.payload.id
          ? { ...gerant, is_active: false }
          : gerant
      );
    },
    unblockGerant: (state, action) => {
      const gerantIndex = state.listgerant.findIndex(
        (gerant) => gerant.id === action.payload.id
      );
      if (gerantIndex !== -1) {
        state.listgerant[gerantIndex].is_active = true;
      }
    },
  },
});

export const {
  addNewgerant,
  setListgerants,
  blockGerant,
  unblockGerant,
  setCurrentgerant,
  deleteCurrentgerant,
  resetCurrentgerant,
  filterGerantsList,
} = gerantSlice.actions;
export default gerantSlice.reducer;
