import React, { useState } from "react";
import Modal from "../../components/Modal";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import ApiController from "../../redux/api_controller";
import { toast } from "react-toastify";
import BtnLoading from "../../components/BtnLoading";

const AddFournisseur = ({ modalIsOpen, setIsOpen, onClosed }) => {
  const currentUser = useSelector((state) => state.users.currentUser);
  const { currentAgence } = useSelector((state) => state.agences);
  const [loading, setLoading] = useState(false)
  const defaultMessage = 'Ce champ est obligatoire !'
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm();

  function closeModal() {
    reset()
    setIsOpen(false);
  }

  const submit = (data) => {
    setLoading(true)
    const newData = {
      adresse: data.adresse,
      agenceId: currentAgence.id,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      telephone: data.telephone,
      createdBy: currentUser.id
    };
    ApiController.fournisseurs.registerfournisseur(newData)
      .then((res) => {
        closeModal()
        toast.success('Fournisseur ajoute avec succes')
      })
      .catch((err) => {
        if (err.response.status === 400) {
          if (err.response.data.message.phone) {
            console.log('reorr inside');
            return setError("phone", { type: 'custom', message: "Le numéro de téléphone est déjà pris" })
          }
          if (err.response.data.message.email) {
            return setError("email", { type: 'custom', message: "L'email est déjà pris" })
          }

        }
      })
      .finally(() => {
        setLoading(false)
      })
  };

  return (
    <Modal
      opened={modalIsOpen}
      title="Creer un nouveau fournisseur"
      className="modal-container"
      onClosed={closeModal}
    >
      <form className="py-0 my-0" onSubmit={handleSubmit(submit)}>
        <div className="row my-md-3">
          <div className="col-12 col-md ">
            <label className="add-client-input">
              Prénom
              <input
                type="text"
                className=""
                {...register("firstName", { required: defaultMessage })}
              />
              {errors.firstName && <span className='text-error--danger'>{errors.firstName.message}</span>}
            </label>
          </div>
          <div className="col-12 col-md">
            <label className="add-client-input">
              Nom
              <input
                type="text"
                className=""
                {...register("lastName", { required: defaultMessage })}
              />
              {errors.lastName && <span className='text-error--danger'>{errors.lastName.message}</span>}

            </label>
          </div>
        </div>
        <div className="row my-md-3">
          <div className="col-12 col-md ">
            <label className="add-client-input">
              Email
              <input
                type="email"
                className=""
                {...register("email")}
              />
              {errors.email && <span className='text-error--danger'>{errors.email.message}</span>}

            </label>
          </div>
          <div className="col-12 col-md add-client-input">
            <label className="add-client-input">
              Mobile
              <input
                type="text"
                className=""
                {...register("telephone", { required: defaultMessage })}

              />
              {errors.telephone && <span className='text-error--danger'>{errors.telephone.message}</span>}

            </label>
          </div>
        </div>
        <div className="row my-md-3">
          {/* <div className="col-12 col-md">
            <label htmlFor="">
              Selectionnez une agence
              <select
                className="py-2"
                aria-label="Default select example"
                {...register("agenceId", { required: defaultMessage })}
              >

                {agenceList.map((item) => {
                  return (
                    <>
                      <option value={item.id}>{item.nomAgence}</option>
                      {errors.agenceId && <span className='text-error--danger'>{errors.agenceId.message}</span>}

                    </>
                  );
                })}
              </select>
            </label>
          </div> */}
          <div className="col-12 col-md ">
            <label className="">
              Adresse
              <input
                type="text"
                className=""
                {...register("adresse")}
              />
              {errors.adresse && <span className='text-error--danger'>{errors.adresse.message}</span>}

            </label>
          </div>

        </div>
        <div className="mt-4">
          <BtnLoading bgColor="#00BEC9" color="#FFFFFF" right={30} type="submit" loading={loading} text="Enregistrer" />
        </div>
      </form>
    </Modal>
  );
};

export default AddFournisseur;


