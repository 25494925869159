import React from 'react'

const Input = ({ label, type, htmlFor, register, error, errorMessage, className }) => {
    return (
        <div className={className ?? `col-12 col-md-6 mt-3`}>
            <label
                htmlFor={htmlFor}
                className="add-client-input">
                {label}
                <input
                    id={htmlFor}
                    type={type}
                    className=""
                    {...register} />
                {error && <span className='text-error--danger'>{errorMessage}</span>}
            </label>
        </div>
    )
}

export default Input