import React, { useState } from "react";
import DataDisplayTemplate from "../../components/DataDisplayTemplate";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import AddFournisseur from "./AddFournisseur"
import DeleteFournisseur from "./DeleteFournisseur";
import UpdateFournisseur from "./UpdateFournisseur";
import { filterFournisseursList, setCurrentfournisseur } from "../../redux/reducers/fournisseurs";

const FournisseursPage = () => {
  const dispatch = useDispatch();
  const { listfournisseur, allFournisseur } = useSelector(state => state.fournisseurs)
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  // useEffect(() => {
  //   // !getting currentAgence fournisseur
  //   setIisFournsseursFetching(true)
  //   ApiController.fournisseurs.getFournisseursByAgence(agenceId).finally(() => { setIisFournsseursFetching(false) })
  // }, [agenceId])

  const showUpdateModal = (fournisseur) => {
    dispatch(setCurrentfournisseur(fournisseur))

    openUpdateClientModal();
  };

  function openAddFournisseur() {
    setAddModalIsOpen(true);
  }
  function openUpdateClientModal() {
    setUpdateModalIsOpen(true);
  }
  function openDeleteFourniseur(fournisseur) {
    dispatch(setCurrentfournisseur(fournisseur))
    setDeleteModalIsOpen(true);
  }

  const showActionButtons = (cell, row) => {
    return (
      <div >
        {/* <IoMdEye data-tip data-for="detailsClientTip" className='custom-mouse-clickable mx-3 fs-5 custom-eye-icon' onClick={()=>showDetailsClient(row)}/> */}
        <FiEdit data-tip data-for="editClientTip" className='custom-mouse-clickable mx-3 fs-5 custom-edit-icon' onClick={() => showUpdateModal(row)} />
        <RiDeleteBinLine data-tip data-for="deleteClientTip" className='custom-mouse-clickable mx-3 fs-5 custom-delete-icon' onClick={() => openDeleteFourniseur(row)} />

      </div>
    )
  }


  const columns = [
    {
      dataField: 'id',
      text: 'Fournisseur ID'
    },
    {
      dataField: 'firstName',
      text: 'Prenom',
      // filter: textFilter()

    },
    {
      dataField: 'lastName',
      text: 'Nom',
      // filter: textFilter()

    },
    {
      dataField: 'email',
      text: 'Email',
      // filter: textFilter()

    },
    {
      dataField: 'telephone',
      text: 'Telephone'
    },
    {
      dataField: 'adresse',
      text: 'Adresse'
    },
    {
      dataField: 'agence.nomAgence',
      text: 'Agence',
      // filter: textFilter()

    },
    {
      dataField: 'actions',
      text: 'Actions',
      formatter: showActionButtons
    },
  ];

  return (
    <div className="">
      <AddFournisseur modalIsOpen={addModalIsOpen} setIsOpen={setAddModalIsOpen} />
      <UpdateFournisseur modalIsOpen={updateModalIsOpen} setIsOpen={setUpdateModalIsOpen} />
      {/* <UpdateClient modalIsOpen={updateModalIsOpen} setIsOpen={setUpdateModalIsOpen} />
    <DeleteClient modalIsOpen={deleteModalIsOpen} setIsOpen={setDeleteModalIsOpen} /> */}
      <DeleteFournisseur modalIsOpen={deleteModalIsOpen} setIsOpen={setDeleteModalIsOpen} />


      <DataDisplayTemplate
        isDataFetching={false}
        title="Fournisseurs"
        description="Créez, consultez et gérez vos enregistrements de fournisseurs. "
        onChange={(e) => dispatch(filterFournisseursList(e.target.value))}
        onClick={openAddFournisseur}
        btnText=""
        allData={allFournisseur}
        data={listfournisseur}
        columns={columns}
        messageOnEmpty="Pas de fournisseurs"

      />
    </div>
  );
};

export default FournisseursPage;
