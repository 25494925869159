import { createSlice } from "@reduxjs/toolkit/"

let initialState = {
    allListDepenses: [],
    listDepense: [],
    currentDepense: {},
    currentDepenseDetails: null
}
const depenseSlice = createSlice({
    name: 'depenses',
    initialState,
    reducers: {
        setListDepense: (state, { payload }) => {
            state.listDepense = payload
            state.allListDepenses = payload
        },
        addNewDepense: (state, { payload }) => {
            state.listDepense = [...state.allListDepenses, payload]
            state.allListDepenses = [...state.allListDepenses, payload]
        },
        pushToDepenseList: (state, { payload }) => {
            state.listDepense = [...state.allListDepenses, ...payload]
        },
        setCurrentDepense: (state, { payload }) => {
            state.currentDepense = payload

        },
        setCurrentDepenseDetails: (state, { payload }) => {
            console.log({ payload });
            state.currentDepenseDetails = payload
        },
        updateDepense: (state, { payload }) => {
            state.currentDepense = payload
            let listDepense = state.allListDepenses.filter(item => item.id !== payload.id)
            state.listDepense = [...listDepense, payload].reverse()
        },

        deleteCurrentDepense: (state, { payload }) => {
            state.listDepense = state.allListDepenses.filter(item => item.id !== payload)
        },
        clearCurrentDepense: (state) => {
            state.currentDepense = {}
        },
        filterDepenseList: (state, { payload }) => {
            state.listDepense = state.allListDepenses.filter(item => {
                let data = [item.fournisseur?.firstName, item.fournisseur?.lastName].join(' ').toLowerCase()
                return data.includes(payload.toLowerCase())
            })
        },
    }
})

export const { setListDepense, addNewDepense, setCurrentDepenseDetails, pushToDepenseList, setCurrentDepense, updateDepense, deleteCurrentDepense, filterDepenseList } = depenseSlice.actions
export default depenseSlice.reducer