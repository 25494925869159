import AccueilPage from "../pages/accueil/AccueilPage";
import AgencesPage from "../pages/agences/AgencesPage";
import LoginPage from "../pages/auth/LoginPage";
import MdpOublier from "../pages/auth/MdpOublier";
import RecupMdp from "../pages/auth/RecupMdp";
import RegisterPage from "../pages/auth/RegisterPage";
import ReinitMdp from "../pages/auth/ReinitMdp";
import ClientsPage from "../pages/clients/ClientsPage";
import DepensesPage from "../pages/depenses/ListeDepensesPage";
import FournisseursPage from "../pages/fournisseurs/FournisseursPage";
import GerentsPage from "../pages/gerants/GerentsPage";
import ParametresPage from "../pages/parametres/ParametresPage";
import AjouterGerant from "../pages/gerants/AjouterGerant";
import {
  ACCUEIL_PATH,
  DEFAULT_PATH,
  LOGIN_PATH,
  REGISTER_PATH,
  AGENCES_PATH,
  GERANT_PATH,
  CLIENTS_PATH,
  FOURNISSEURS_PATH,
  VENTES_PATH,
  DEPENSE_PATH,
  // STOCK_PATH,
  // INVENTAIRE_PATH,
  PARAMETRE_PATH,
  // BILAN_PATH,
  MDPOUBLIE_PATH,
  RECUPMDP_PATH,
  REINITMDP_PATH,
  AJOUTGERANT_PATH,
  PRODUITS_PATH,
  CATEGORIES_PATH,
  AGENCES_DETAIL_PATH,
  DETAILGERANT_PATH,
  AGENCE_PARAMETRE_PATH,
  NEW_VENTE_PATH,
  UPDATE_VENTE_PATH,
  NEW_DEPENSE_PATH,
  UPDATE_DEPENSE_PATH,
  DETAILS_VENTEE_PATH,
  DETAILS_DEPENSE_PATH,
} from "../utils/navigation_paths";
import Produits from "../pages/produits/ProduitsPage";
import Category from "../pages/category/CategoryPage";
import DetailsAgencePage from "../pages/agences/DetailsAgencePage";
import Page404 from "../components/Page404";
import GerantDetail from "../pages/gerants/GerantDetail";
import AgenceKpi from "../pages/agences/AgenceKpi";
import AgenceParametresPage from "../pages/agences/parametres/AgenceParametresPage";
import AddVenteFacture from "../pages/ventes/AddVenteFacture";
import VenteHomePage from "../pages/ventes/VenteHomePage";
import UpdateVentePage from "../pages/ventes/UpdateVentePage";
import VenteKpi from "../pages/ventes/VenteKpi";
import ProduitsKpi from "../pages/produits/ProduitsKpi";
import ClientsKpi from "../pages/clients/ClientsKpi";
import FournisseursKpi from "../pages/fournisseurs/FournisseursKpi";
import VentesPage from "../pages/ventes/AgenceVentesPage";
import DepensesHomePage from "../pages/depenses/DepensesHomePage";
import AddDepenseFactue from "../pages/depenses/AddDepenseFactue";
import UpdateDepensePage from "../pages/depenses/UpdateDepensePage";
import VenteDetailsPage from "../pages/ventes/VenteDetailsPage";
import DepenseDetailsPage from "../pages/depenses/DepenseDetailsPage";

const { Navigate } = require("react-router-dom");

export const navigation_private_routes = [
  {
    path: "",
    element: <Navigate to={LOGIN_PATH} />,
  },
  {
    path: DEFAULT_PATH,
    element: <Page404 />,
  },
  {
    path: ACCUEIL_PATH,
    element: <AccueilPage />,
  },
  {
    path: AGENCES_PATH,
    element: <AgencesPage />,
  },
  {
    path: AGENCES_DETAIL_PATH,
    element: <DetailsAgencePage />,
    children: [
      {
        path: "",
        element: <AgenceKpi />,
      },
      {
        path: CLIENTS_PATH,
        element: <ClientsPage />,
      },
      {
        path: FOURNISSEURS_PATH,
        element: <FournisseursPage />,
      },
      {
        path: PRODUITS_PATH,
        element: <Produits />,
      },
      {
        path: VENTES_PATH,
        element: <VentesPage />,
        children: [
          {
            path: '',
            element: <VenteHomePage />
          },
          {
            path: NEW_VENTE_PATH,
            element: <AddVenteFacture />
          },
          {
            path: UPDATE_VENTE_PATH,
            element: <UpdateVentePage />
          },
          {
            path: DETAILS_VENTEE_PATH,
            element: <VenteDetailsPage />
          }
        ]
      },
      {
        path: DEPENSE_PATH,
        element: <DepensesHomePage />,
        children: [
          {
            path: '',
            element: <DepensesPage />,
          },
          {
            path: NEW_DEPENSE_PATH,
            element: <AddDepenseFactue />,
          },
          {
            path: UPDATE_DEPENSE_PATH,
            element: <UpdateDepensePage />
          },
          {
            path: DETAILS_DEPENSE_PATH,
            element: <DepenseDetailsPage />
          }
        ]
      },
      {
        path: AGENCE_PARAMETRE_PATH,
        element: <AgenceParametresPage />,
      },
      // AGENCE_PARAMETRE_PATH
    ],
  },
  {
    path: GERANT_PATH,
    element: <GerentsPage />,
  },
  {
    path: AJOUTGERANT_PATH,
    element: <AjouterGerant />,
  },
  {
    path: DETAILGERANT_PATH,
    element: <GerantDetail />,
  },
  {
    path: CLIENTS_PATH,
    element: <ClientsKpi />,
  },
  {
    path: CATEGORIES_PATH,
    element: <Category />,
  },
  {
    path: FOURNISSEURS_PATH,
    element: <FournisseursKpi path={FOURNISSEURS_PATH} />,
  },
  {
    path: VENTES_PATH,
    element: <VenteKpi path={VENTES_PATH} />,
  },
  {
    path: DEPENSE_PATH,
    element: <DepensesPage path={DEPENSE_PATH} />,
  },
  // {
  //   path: STOCK_PATH,
  //   element: <StockPage path={STOCK_PATH} />,
  // },
  // {
  //   path: INVENTAIRE_PATH,
  //   element: <InventairesPage path={INVENTAIRE_PATH} />,
  // },
  {
    path: PRODUITS_PATH,
    element: <ProduitsKpi path={PRODUITS_PATH} />,
  },
  {
    path: PARAMETRE_PATH,
    element: <ParametresPage path={PARAMETRE_PATH} />,
  },
  // {
  //   path: BILAN_PATH,
  //   element: <BilanPage path={BILAN_PATH} />,
  // },
];

export const navigation_public_routes = [
  {
    path: LOGIN_PATH,
    element: <LoginPage />,
  },
  {
    path: MDPOUBLIE_PATH,
    element: <MdpOublier />,
  },
  {
    path: RECUPMDP_PATH,
    element: <RecupMdp />,
  },
  {
    path: REINITMDP_PATH,
    element: <ReinitMdp />,
  },
  {
    path: LOGIN_PATH,
    element: <LoginPage />,
  },
  {
    path: REGISTER_PATH,
    element: <RegisterPage />,
  },
];
