import axios from "axios"
import { ADD_CATEGORY_API_ROUTE, GET_CATEGORIES_API_ROUTE, GET_CATEGORIES_BY_AGENCE_API_ROUTE } from "../../routes/api_routes"
import { addNewCategory, setCategoriesList } from "../reducers/categories"
import { store } from "../store/store"

const dispatch = store.dispatch
const categories = {
    getCategoriesByCreatedBy(createdBy) {
        return new Promise((resolve, reject) => {
            axios.get(GET_CATEGORIES_API_ROUTE + createdBy)
                .then(response => {
                    if (response.status === 200) {
                        let categories = response.data.data
                        dispatch(setCategoriesList(categories))
                        resolve(categories)
                    } else {
                        reject(response)
                    }
                })
                .catch(res => reject(res))
        })
    },
    getCategoriesListByAgence(agenceId, dispatch, token) {
        return new Promise((resolve, reject) => {
            axios.get(`${GET_CATEGORIES_BY_AGENCE_API_ROUTE}${agenceId}`, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    // console.log({ response });
                    if (response.data) {
                        let categories = response.data.data
                        dispatch(setCategoriesList(categories))
                        resolve(categories)
                    } else {
                        reject(response)
                    }
                })
        })
    },
    addNewCategory(data) {
        return new Promise((resolve, reject) => {
            axios.post(ADD_CATEGORY_API_ROUTE, data)
                .then(response => {
                    if (response.status === 201) {
                        let category = response.data.data
                        dispatch(addNewCategory(category))
                        resolve(category)
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },
    // updategerant(data,gerant_id){
    //     return new Promise((resolve,reject)=>{
    //         axios.put(`${gerant_API_ROUTE}/${gerant_id}`,data)
    //         .then(response => {
    //             console.log(response);
    //             if(response.status ===200){
    //                 let gerant = response.data
    //                 dispatch(setCurrentgerant(gerant))
    //                 resolve(gerant)
    //             }else{
    //                 reject(response)
    //             }
    //         })
    //         .catch(error => reject(error))
    //     })
    // },
    // deletegerant(gerant_id){
    //     return new Promise((resolve,reject)=>{
    //         axios.delete(`${gerant_API_ROUTE + gerant_id}/`)
    //         .then(response => {
    //             if(response.status ===204){
    //                 resolve(response)
    //             }else{
    //                 reject(response)
    //             }
    //         })
    //         .catch(error => reject(error))
    //     })
    // },
}
export default categories