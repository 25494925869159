import React from "react";
import f_loo from "../../images/f_loo.png";
import { Link } from "react-router-dom";
// import './RecupMdp.css';

function RecupMdp() {
  return (
    <div>
      <div className="row mdp-top-bar m-0 p-0 shadow">
        <div className="col me-auto text-start d-flex flex-row">
          <img src={f_loo} alt="" />
          <p className="custom-separator-bar-secondary barre-mdp my-2"></p>
          <b className="custom-color-primary ps-2 pt-2 mt-2 font-Montserrat text-white">
            Fewnu Pack
          </b>
        </div>
        <p className="col-3 d-none d-md-block text-end me-md-3 pt-2">
          <Link
            className="custom-color-primary text-decoration-none font-Montserrat text-white"
            to="/register"
          >
            Inscrivez-vous!
          </Link>
        </p>
      </div>
      <div className="container">
        <div className="pt-5 mt-5 ">
          <div className="card  px-3 py-4 border-0 card-middle">
            <h5 className="pt-2">Définissez un nouveau mot de passe</h5>
            <input
              type="text"
              placeholder="Définir mot de passe"
              className="form-control my-2 border-0"
            />
            <input
              type="text"
              placeholder="Confirmation du mot de passe"
              className="form-control my-2 border-0"
            />
            <button
              type="submit"
              className="btn btn-default send mt-4 ms-auto text-bold"
            >
              Confirmer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecupMdp;
