export const optionsStyle = {
    control: styles => ({
        ...styles,
        backgroundColor: 'white',
        padding: '0.15rem !important',
        cursor: 'pointer !important',
        width: '100% !important'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        //   const color = chroma(data.color);
        return {
            ...styles,
            // backgroundColor: isDisabled ? 'red' : 'blue',
            // color: 'red',
            // cursor: isDisabled ? 'not-allowed' : 'default',
            padding: '.2rem !important',
            cursor: 'pointer !important',
        };
    },
};