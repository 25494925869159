import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import ApiController from "../../redux/api_controller";
import { useAgenceId } from "../../hooks/useAgenceId";

const VentesPage = () => {
  const { agenceId } = useAgenceId()

  // console.log({ allListVentes, listVente });

  useEffect(() => {
    ApiController.ventes.getventesList(agenceId)
  }, [])

  return (
    <div className="p-0">
      <Outlet />
    </div>
  );
};
export default VentesPage;
