import users from "./users";
import clients from "./clients";
import fournisseurs from "./fournisseurs";
import agences from "./agences";
import gerants from "./gerants";
import commandes from "./commandes";
import produits from "./produits";
import categories from "./categories";
import ventes from "./ventes";
import depenses from "./depenses";
import kpis from "./kpis";

const ApiController = {
  users,
  agences,
  gerants,
  clients,
  fournisseurs,
  produits,
  commandes,
  categories,
  ventes,
  depenses,
  kpis,
};
export default ApiController;
