import React from 'react'

const Page404 = () => {
    return (
        <div className='vh-100 justify-content-center align-items-center'>
            <h1>Page not found</h1>
        </div>
    )
}

export default Page404