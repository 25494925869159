import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AiOutlinePlusCircle } from 'react-icons/ai'
import AddFactureSelectForm from '../AddFactureSelectForm';
import ApiController from '../../../redux/api_controller/index';
import { setFactureSelectedClient } from '../../../redux/reducers/factureArticles';
import AddClient from '../../../pages/clients/AddClient';

const SelectFactureClient = ({ isUpdating }) => {
    const dispatch = useDispatch()
    // const token = useToken()
    const { currentVente } = useSelector(state => state.ventes)
    const { selectedFactureClient } = useSelector(state => state.factureArticles)
    const { listClient: clientList, allListClients } = useSelector((state) => state.clients) || [];
    const [clientModalIsOpen, setClientModalIsOpen] = useState(false)
    // console.log({ allListClients });
    const addNewClient = () => {
        setClientModalIsOpen(true)
    }
    // console.log({ selectedFactureClient });
    const clientsOptions = allListClients?.map(fournisseur => {
        return { value: fournisseur.id, label: fournisseur.firstName + ' ' + fournisseur.lastName }
    })
    // .concat([{ value: '+', label: <p className='custom-mouse-clickable text-secondary p-0 m-0' onClick={addNewClient}><AiOutlinePlusCircle /> Ajouter un client</p> }])
    // .reverse();

    const changeSelectedClient = (client) => {
        //! on selectionne un client pour la vente
        // console.log({ client });
        dispatch(setFactureSelectedClient(client))
        if (isUpdating) {
            // if (selectedFactureClient) {
            ApiController.ventes.updatevente(currentVente.id, { clientId: client.value, }, dispatch).then((res) => {

                })
            // }
            return
        }
    }
    return (
        <div className='d-flex flex-wrap p-2 gap-2'>
            <div style={{ minWidth: '250px', flex: '2' }} className=''>
                <AddFactureSelectForm
                    title="Clients"
                    concerned="client"
                    placeholder="--selectionner client--"
                    value={selectedFactureClient}
                    onChange={(client) => client.value !== '+' && client.value !== selectedFactureClient?.value && changeSelectedClient(client)}
                    options={clientsOptions}
                    modal={<AddClient modalIsOpen={clientModalIsOpen} setIsOpen={setClientModalIsOpen} />}
                // firstCondition={isVenteCreated}
                // secondCondition={isClientChanged}
                // secondConditionProove={isVenteCreated}
                />
            </div>
            <div style={{ flex: '1' }} className=''>
                <button
                    onClick={addNewClient}
                    type="button"
                    className='btn btn-add p-2 px- w-100'
                >
                    + Client
                </button>
            </div>
        </div>
    )
}

export default SelectFactureClient