import React, { useEffect } from "react";
import "./AccueilPage.css";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  ArcElement,
  Title,
  Tooltip,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import { statsData } from "../../utils/fakedata";
import Stat from "../../components/Stat";
import ApiController from "../../redux/api_controller";
import CurrentUser from "../../hooks/CurrentUser";
import { useSelector } from "react-redux";
import { AGENCES_PATH } from "../../utils/navigation_paths";
import { Link } from "react-router-dom";
import { useMemo } from "react";
import { AiOutlineShop } from "react-icons/ai";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const AccueilPage = ({ path }) => {
  const agenceList = useSelector((state) => state?.agences?.listAgence);
  const kpis = useSelector((state) => state?.kpis?.listKpis);
  const user = CurrentUser();
  const userId = user?.id;
  const proprietaireKpis = useSelector((state) => state.proprietaire);
  const statsData = useMemo(() => {
    return [
      {
        label: "Boutiques",
        valeur: agenceList.length,
        link: AGENCES_PATH,
        icon: <AiOutlineShop className="text-light" size={20} />,
      },
      // {
      //   label: "VENTES",
      //   valeur: "2300000 FCFA",
      // },
      // {
      //   label: "DEPENSES",
      //   valeur: "03",
      // },
      // {
      //   label: "BENEFICES",
      //   valeur: "300000 FCFA",
      // }
    ];
  }, [agenceList.length]);

  const data = {
    labels: ["Ventes", "Dépenses", "Factures impayés", "Dettes"],
    datasets: [
      {
        label: "Fewnu",
        data: ["25", "25", "25", "25"],
        backgroundColor: ["#43DA8E", "#008FA0", "#FF9A7F", "#F8C47E"],
      },
    ],
  };

  const dataBar = {
    labels: ["Trimestre 1", "Trimestre 2", "Trimestre 3", "Trimestre 4"],
    datasets: [
      {
        label: "Ventes",
        data: ["25", "50", "44", "75"],
        backgroundColor: ["#FF9A7F"],
      },
      {
        label: "Depenses",
        data: ["40", "35", "70", "80"],
        backgroundColor: ["#008FA0"],
      },
    ],
  };

  useEffect(() => {
    ApiController?.agences?.getAgenceList(userId);
  }, [userId]);
  // useEffect(() => {
  //   ApiController?.agences?.getAgenceList(userId).finally(() => setLoading(false))
  // }, [userId])

  useEffect(() => {
    ApiController?.kpis?.gets(userId);
  }, [userId]);

  // console.log(Object.entries(kpis));

  return (
    <div className="container-fluid p-0">
      <h1 className="mb-3">Controlez votre business</h1>
      <div className="row">
        {/* {statsData?.map((item, key) => (
          // <Link className="" to={item?.link}>
          <Stat key={key} {...item} />
          // </Link>
        ))} */}

        {kpis &&
          Object.entries(kpis)?.map(([item, valeur], index) => (
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
              <Link
                style={{ textDecoration: "none", height: "100%" }}
                className="d-block"
                to={"/" + item?.toLowerCase()}
              >
                <div className="card card-boutique h-100 shadow p-0">
                  <div className="card-body">
                    <div className="row">
                      <div className="col">
                        <div className="text-xs font-weight-bold color-white text-uppercase">
                          {item}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="para">{valeur?.nombre}</div>

                          {typeof valeur?.montant === "number" ? (
                            <div className="para">{valeur?.montant} FCFA</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}

        <span style={{ height: 50 }} />

        {/* <div className="row">
          <div className="col-xl-6 col-lg-6 col-sm-12 col-xs-12">
            <div className="pie-style w-75">
              <h5 className="fw-600">Situation financière</h5>
              <Pie data={data} />
            </div>
          </div>

          <div
            className="col-xl-6 col-lg-6 col-sm-12 col-xs-12"
            tyle={{ height: 350 }}
          >
            <div className="bar-style w-95">
              <h5 className="fw-600">Chiffres d'affaires</h5>
              <Bar data={dataBar} />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default AccueilPage;
