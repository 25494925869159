import React from 'react'

const BtnLoading = ({ loading, disabled, onClick, text, type, right, bgColor, color }) => {
    return (
        <button
            style={{ position: 'relative', backgroundColor: bgColor, color: color }}
            disabled={loading || disabled}
            onClick={onClick}
            type={type ?? 'submit'}
            className='loading-btn  p-2  d-fle justify-content-betwee align-items-cente w-100 '
        >
            <span className="">{text}</span>
            <div
                style={{ position: 'absolute', right: right, top: 6 }} className="">
                <div
                    style={{ width: "20px", height: "20px" }}
                    className={loading ? "spinner-border fs-6 mt-1" : "d-none"}
                    role="status"
                >
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </button>
    )
}

export default BtnLoading