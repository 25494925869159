import React from 'react'

const AgenceGerantInfos = ({ gerant }) => {
    const { fullName, phone, email, adresse } = gerant
    return (
        <>
            <h2>Gérée par {fullName} </h2>
            <p><span className='fw-bold'>Téléphone :</span> {phone}</p>
            <p><span className='fw-bold'>Email :</span> {email}</p>
            <p><span className='fw-bold'>Adresse :</span> {adresse}</p>
        </>
    )
}

export default AgenceGerantInfos