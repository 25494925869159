import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Modal from '../../components/Modal'
import { isValidText } from '../../utils/form_control';
import ApiController from '../../redux/api_controller/index';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import BtnLoading from '../../components/BtnLoading';

const AjouterCategory = ({ modalIsOpen, setModalIsOpen }) => {
    const { register, reset, formState: { errors }, handleSubmit, setError } = useForm()
    const defaultMessage = 'Ce champ est obligatoire !'
    const currentUser = useSelector(state => state.users.currentUser)
    const agenceList = useSelector((state) => state.agences.listAgence);
    const [loading, setLoading] = useState(false)

    function closeModal() {
        reset()
        setModalIsOpen(false);
    }

    const submit = data => {
        let newData = {
            ...data,
            createdBy: currentUser.id
        }
        setLoading(true)
        ApiController.categories.addNewCategory(newData)
        .then(response => {
            closeModal()
            toast.success('Catégorie ajouté avec succés');
        })
            .catch(err => {
                console.log(err)
                // closeModal()
                toast.error('L\'ajout n\'a pas réuissi');
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const verificate = (data) => {
        if (!isValidText(data.categoryName)) {
            setError('categoryName', { message: 'Nom non valide' })
        }
        else if (!isValidText(data.description)) {
            setError('description', { message: 'Nom non valide' })
        }
        else {
            submit(data)
        }
    }

    return (
        <>
            <Modal
                opened={modalIsOpen}
                title="Ajouter un produit"
                className="modal-container"
                onClosed={closeModal}
            >
                <form className="" onSubmit={handleSubmit(verificate)}>
                    <div className="row my-md-1">
                        <div className="col-12 col-md  ">
                            <label className=''>
                                Nom de la categorie
                                <input type='text' {...register('categoryName', { required: defaultMessage })} className=' p-2' />
                                {errors.categoryName && <span className='text-error--danger'>{errors.categoryName.message}</span>}
                            </label>
                        </div>
                        <div className="col-12 col-md  ">
                            <label className=''>
                                Description
                                <input type='text' {...register('description')} className=' p-2' />
                                {errors.description && <span className='text-error--danger'>{errors.description.message}</span>}
                            </label>
                        </div>
                        <div className="col-12 mt-3">
                            <label htmlFor="">
                                Agence
                                <select
                                    className=""
                                    aria-label="Default select example"
                                    {...register("agenceId", { required: defaultMessage })}
                                >
                                    <option value=''>Selectionnez une agence</option>
                                    {
                                        agenceList?.map((item, idx) => {
                                            return (
                                                <>
                                                    <option value={item.id} key={idx}>{item.nomAgence}</option>
                                                </>
                                            )
                                        })
                                    }

                                </select>
                                {errors.agenceId && <span className='text-error--danger'>{errors.agenceId.message}</span>}
                            </label>        
                        </div>
                    <div className="mt-4">
                        <BtnLoading bgColor="#00BEC9" color="#FFFFFF" right={30} type="submit" loading={loading} text="Enregistrer" />
                    </div>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AjouterCategory