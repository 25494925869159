import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Modal from "../../components/Modal";
import ApiController from "../../redux/api_controller";

const DeleteClient = ({ modalIsOpen, setIsOpen }) => {
  // const dispatch = useDispatch();
  // const currentUser = useSelector((state) => state.users.currentUser);
  const currentClient = useSelector((state) => state.clients.currentClient);


  function closeModal() {
    setIsOpen(false);
  }

  const handleDelete = () => {
    let { id } = currentClient;
    ApiController.clients
      .deleteClient(id)
      .then(() => {
        closeModal()
        toast.success("Client supprimé avec succés");
      })
      .catch((err) => {
        toast.error("ERROR");
        console.log(err)
      });
  };

  return (
    <>
      <Modal
        opened={modalIsOpen}
        className="modal-container"
        onClosed={closeModal}
        title="Confirmer la suppression"
      >
        <div className="row m-0 p-0">
          <p className="text-center">
            Voulez vous vraiment supprimer ce client?
          </p>
          <p className="text-center">
            <button
              type="submit"
              className="bg-secondary text-white shadow rounded border-0 py-2 px-4 mx-3 mt-3"
              onClick={closeModal}
            >
              Annuler
            </button>
            <button
              type="submit"
              className="custom-button-secondary py-2 px-3 mx-3 mt-3"
              onClick={handleDelete}
            >
              Supprimer
            </button>
          </p>
        </div>
      </Modal>
    </>
  );
};

export default DeleteClient;
