export const commandesGenerator = (itemNumber) => {
  let list = [];

  for (let index = 0; index < itemNumber; index++) {
    list.push({
      id: index + 1,
      clients: "Mountaga Diop",
      date_livraison: "11/11/2022",
      montant: "60.000 f",
    });
  }

  return list;
};

export const entreesGenerator = (itemNumber) => {
  let data = [];
  let status = ["Payé", "Avance", "Impayé"];

  for (let index = 0; index < itemNumber; index++) {
    data.push({
      numero_facture: index + 1,
      date: "11/11/2022",
      clients: "Mountaga Diop",
      solde_client: "200.000 f",
      status: status[Math.floor(Math.random() * status.length)],
    });
  }
  return data;
};

export const depensesGenerator = (itemNumber) => {
  let data = [];

  let designation = ["achat machine", "achat fil", "divers", "salaires"];

  for (let index = 0; index < itemNumber; index++) {
    data.push({
      //   id: index + 1,
      date: "11/11/2022",
      designation: designation[Math.floor(Math.random() * designation.length)],
      montant: "50.000 f",
    });
  }

  return data;
};

export const bilanGenerator = (itemNumber) => {
  let data = [];

  for (let index = 0; index < itemNumber; index++) {
    data.push({
      date: "11/11/2022",
      entrees: "200.000 f",
      depenses: "50.000 f",
      benefices: "150.000 f",
    });
  }

  return data;
};
export const productsGenerator = (itemNumber) => {
  let list = [];

  for (let index = 0; index < itemNumber; index++) {
    list.push({
      id: index + 1,
      name: "Coca cola " + index,
      frais: "15 000",
      quantite: "20",
      cout_total: "300 000",
      prix_vente: "20 000",
      total_vente: "400 000",
      is_sold: index % 2 === 0,
    });
  }

  return list;
};
export const clientGenerator = (itemNumber) => {
  let list = [];

  for (let index = 0; index < itemNumber; index++) {
    list.push({
      id: index + 1,
      nom_complet: "Cheikh Ndiaye",
      telephone: "771772099",
      adresse: "Thiaroye",
      ville: "Dakar",
      sexe: "homme",
    });
  }

  return list;
};

export const employeesGenerator = (itemNumber) => {
  let data = [];

  for (let index = 0; index < itemNumber; index++) {
    data.push({
      nom: "Modou Fall",
      specialite: "Tayeur",
      numero: "77 177 20 99",
      adresse: "Sicap foire",
    });
  }

  return data;
};
