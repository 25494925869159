module.exports = {
    
    getAllData(agenceId, currentUserId, ApiController) {
        return new Promise((resolve,reject)=>{
            // ApiController.agences.getAgenceList(token, dispatch)
            // .catch((err) => console.log(err))
            // now we will get the clients list
            // ApiController.clients.getClientsList(id,dispatch,token)
            // .catch(err => reject(err))

            // // now we will get the shops list
            // ReduxAcions.shops.getShopsList(dispatch,token)
            // .catch(err => reject(err))

            // now we will get the shops list
            // ApiController.statistic.getStatistics(id,dispatch,token)
            // .catch(err => reject(err))

            // now we will get the fournisseurs list
            // ApiController.fournisseurs.fetchFournisseurs(id,dispatch, token)
            // .catch(err => reject(err))
            
            //         // now we will get the products list
            // ApiController.produits.getProductsList(id,dispatch,token)
            // .catch(err => reject(err))
            
            // now we will get the ventes list
            // ApiController.ventes.getVentesList(id,dispatch,token)
            // .catch(err => reject(err))
            
            // now we will get the categories list
            // ApiController.categories.getCategoriesList(id,dispatch,token)
            // .catch(err => reject(err))
            
            // now we will get the depenses list
            // ApiController.depenses.getDepenseList(id,dispatch, token)
            // .catch(err => reject(err))
            
            //get company list

            // ApiController.company.getCurrentCompany(id,dispatch,token)
            // .catch( error => reject(error))
            ApiController.gerants.getgerantsList(currentUserId)
            ApiController.clients.getClientsByAgence(agenceId)
            ApiController.fournisseurs.getFournisseursByAgence(agenceId)
            ApiController.produits.getAgenceProducts(agenceId)
            ApiController.ventes.getventesList(agenceId)
            ApiController.depenses.getDepensesByAgence(agenceId)
            ApiController.categories.getCategoriesListByAgence(agenceId)
        })
    }
}