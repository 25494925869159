import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import AddProductToArticlesFacture from './AddProductToArticlesFacture'
import NewFactureEditArticle from './NewFactureEditArticle'
import { resetCurrentFacture } from '../../redux/reducers/factureArticles'


const FactureArticles = ({
    children,
    deleteFactureArticle,
    createNewFactureArticle,
    loadingResource,
    updateArticleQuantityOnChange
}) => {
    const dispatch = useDispatch()
    const [selectComponent, footerComponent] = children
    useEffect(() => {
        dispatch(resetCurrentFacture())
    }, [dispatch])
    return (
        <div className=' facture-articl d-flex flex-wrap gap-2 '>
            <div
                style={{ minWidth: '400px', flex: '1' }}
            >
                <AddProductToArticlesFacture
                    loadingResource={loadingResource}
                    createNewFactureArticle={createNewFactureArticle}
                />
            </div>
            <div
                style={{ maxHeight: '500px', flex: '1', minWidth: '350px' }}
                className=' mb-3 card p-0'
            >
                <h5 className='p-2 py-3 m-0 bg-light text-start fs-6 fw-400'>Rubrique facturation</h5>
                {selectComponent}
                <NewFactureEditArticle
                    loadingResource={loadingResource}
                    deleteFactureArticle={deleteFactureArticle}
                    updateArticleQuantityOnChange={updateArticleQuantityOnChange}
                >
                    {/* <div style={{ position: 'sticky', bottom: '0' }}> */}
                    {footerComponent}
                    {/* </div> */}
                </NewFactureEditArticle>
            </div>

        </div>
    )
}

export default FactureArticles