import React, { useEffect, useState } from "react";
import "./GerantsPage.css";
import AjouterGerant from "./AjouterGerant";
import { useDispatch, useSelector } from "react-redux";
import DataDisplayTemplate from "../../components/DataDisplayTemplate";
import { blockGerant, filterGerantsList } from "../../redux/reducers/gerants";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ApiController from "../../redux/api_controller";


const GerentsPage = () => {
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const { allListGerants, listgerant: listGerants } = useSelector(state => state.gerants)
  const currentUser = useSelector(state => state.users.currentUser)
  const [isBlocked, setIsBlocked] = useState(false);

  const dispatch = useDispatch()

  function openAddModal() {
    setAddModalIsOpen(true);
  }

  useEffect(() => {
    ApiController.gerants.getgerantsList(currentUser.id)
  }, [currentUser.id]);


  const block = (gerantId) => {
    ApiController.gerants.blockGerantRequest(gerantId)
      .then(() => {
        toast.success('gerant bloque avec succes')
        dispatch(blockGerant(gerantId));
        setIsBlocked(true);
      })
      .catch((error) => {
        console.log(error);
        alert(`Erreur lors du blocage du gérant.`);
      });
  };

  // const handleGerantClick = (gerant) => {
  //   dispatch(resetCurrentgerant(gerant));
  //   // history.push('/gerant-details');

  //   console.log(gerant)
  // };

  const unblockGerant = (gerantId) => {
    ApiController.gerants.UnblockGerantRequest(gerantId)
      .then(() => {
        toast.success('gerant debloque avec succes')
        dispatch(unblockGerant(gerantId));
        setIsBlocked(false);
      })
      .catch((error) => {
        console.log(error);
        alert(`Erreur lors du déblocage du gérant.`);
      });
  };

  const columns = [
    {
      dataField: 'id',
      text: 'Gerant ID'
    },
    {
      dataField: 'fullName',
      text: 'Nom complet',
      // filter: textFilter()
    },
    {
      dataField: 'email',
      text: 'Email'
    },
    {
      dataField: 'phone',
      // filter: textFilter(),
      text: 'Telephone',
    },
    {
      dataField: 'agence.nomAgence',
      // filter: textFilter(),
      text: 'Agence',
    },
    {
      dataField: 'is_active',
      text: 'Actions',
      formatter: (cellContent, row) => {
        return (
          <div>
            {row.is_active ?
              <button className="btn btn-danger" onClick={() => block(row.id)}>Bloquer</button> :
              <button className="btn btn-success" onClick={() => unblockGerant(row.id)}>Débloquer</button>
            }  
            <Link to={`${row.id}`}>detail</Link>
          </div>
        );
      }

    }

  ]







  return (
    <div className="gerantsContainer">
      <AjouterGerant
        modalIsOpen={addModalIsOpen}
        setIsOpen={setAddModalIsOpen}
      />
      <DataDisplayTemplate
        onChange={(e) => dispatch(filterGerantsList(e.target.value))}
        title="Gérants"
        description="Créez et assignez des gérants à vos agences ou boutiques."
        onClick={openAddModal}
        btnText=""
        allData={allListGerants}
        data={listGerants}
        columns={columns}
        messageOnEmpty="Pas de gérants"

      />
    </div>
  );
};

export default GerentsPage;
