/** --------------------------------------------------------------------
 * ! LOCAL APIS
 * ---------------------------------------------------------------------
 */
// export const DEFAULT_API = "http://127.0.0.1:8000/api/v1/";
// export const DEFAULT_IMAGE_API = "http://127.0.0.1:8000";

/** --------------------------------------------------------------------
 * ! RECETTE APIS
 * ---------------------------------------------------------------------
 */
// export const DEFAULT_API = 'http://167.99.253.190:8086/api/v1/'
// export const DEFAULT_API = 'http://167.99.253.190:8086'
// Nouvelle api recette: http://167.99.253.190:8087/red/docs/
// export const DEFAULT_API = 'http://167.99.253.190:8087/api/v1/'
// export const DEFAULT_IMAGE_API = 'http://167.99.253.190:8087'

/** --------------------------------------------------------------------
 * ! PARTAGE APIS
 * ---------------------------------------------------------------------
 */
// export const DEFAULT_API = 'http://192.168.68.186:8000/api/v1/'
// export const DEFAULT_IMAGE_API = 'http://192.168.68.186:8000'

/** --------------------------------------------------------------------
 * ! PROD APIS
 * ---------------------------------------------------------------------
 */
export const DEFAULT_API = 'https://api-pack.fewnu.app/api/v1/'
export const DEFAULT_IMAGE_API = 'https://api-pack.fewnu.app'

export const REGISTER_API_ROUTE = DEFAULT_API + "users/";
export const LOGIN_API_ROUTE = DEFAULT_API + "login/";
export const UPDATE_USER_API_ROUTE = DEFAULT_API + "user/"; // + {id} + /
export const CHANGE_USER_PASSWORD_API_ROUTE = DEFAULT_API + "user/password/"; // + {id} + /

export const ADD_CLIENT_API_ROUTE = DEFAULT_API + "clients/";
export const GET_CLIENT_BY_ID_API_ROUTE = DEFAULT_API + "clients/"; // + {id} + /
export const UPDATE_CLIENT_API_ROUTE = DEFAULT_API + "clients/"; // + {id} + /
export const DELETE_CLIENT_API_ROUTE = DEFAULT_API + "clients/"; // + {id} + /
export const GET_CLIENT_BY_AGENCE_API_ROUTE =
  DEFAULT_API + "clients/?agenceId="; // + {agenceId}+ / --> to get clients by agence
export const GET_CLIENT_BY_PROPRIETAIRE_API_ROUTE =
  DEFAULT_API + "clients/proprietaire/"; // + {createdById} + / --> to get clients by createdBy

export const ADD_FOURNISSEUR_API_ROUTE = DEFAULT_API + "fournisseurs/";
export const GET_FOURNISSEUR_BY_ID_API_ROUTE = DEFAULT_API + "fournisseurs/"; // + {id}/
export const UPDATE_FOURNISSEUR_API_ROUTE = DEFAULT_API + "fournisseurs/"; // + {id}/
export const DELETE_FOURNISSEUR_API_ROUTE = DEFAULT_API + "fournisseurs/"; // + {id}/
export const GET_FOURNISSEURS_BY_AGENCE_API_ROUTE =
  DEFAULT_API + "fournisseurs/?agenceId="; // + {agenceId}+ / --> to get clients by agence
export const GET_FOURNISSEUR_BY_PROPRIETAIRE_API_ROUTE =
  DEFAULT_API + "fournisseurs/proprietaire/"; // + {createdById} + / --> to get fournisseur by createdBy

export const ADD_GERANT_API_ROUTE = DEFAULT_API + "gerants/";
export const GET_GERANT_BYID_API_ROUTE = DEFAULT_API + "gerants/"; //+{id}
export const UPDATE_GERANT_BYID_API_ROUTE = DEFAULT_API + "gerants/"; //+{id}
export const GET_GERANT_API_ROUTE = DEFAULT_API + "gerants/?proprietaireId="; // + {id}
export const BLOCK_GERANT_API_ROUTE = DEFAULT_API + "gerants/blocked/"; // + {id}
export const UNBLOCK_GERANT_API_ROUTE = DEFAULT_API + "gerants/dblocked/"; // + {id}

/*
----------------------------------------------------------
! KPIS API ROUTE                                     |
----------------------------------------------------------
*/
export const GET_KPIS_API_ROUTE = DEFAULT_API + "kpi/"; // + {createdBy} + /

/*
----------------------------------------------------------
! CATEGORIES API ROUTE                                     |
----------------------------------------------------------
*/
export const ADD_CATEGORY_API_ROUTE = DEFAULT_API + "category/";
export const GET_CATEGORIES_API_ROUTE = DEFAULT_API + "category/?createdBy="; // + {createdBy} + /
export const GET_CATEGORIES_BY_AGENCE_API_ROUTE = DEFAULT_API + "category/?agenceId="; // + {agenceId} + /

/*
----------------------------------------------------------
! PRODUCTS API ROUTE                                     |
----------------------------------------------------------
*/
export const GET_PRODUCTS_API_ROUTE = DEFAULT_API + "products/";
export const GET_PRODUCTS_BY_AGENCE_API_ROUTE =
  DEFAULT_API + "products/?agenceId="; // + {createdBy} + /
export const ADD_PRODUCT_API_ROUTE = DEFAULT_API + "products/";
export const UPDATE_PRODUCT_API_ROUTE = DEFAULT_API + "products/"; // + {id} + /
export const DELETE_PRODUCT_API_ROUTE = DEFAULT_API + "products/"; // + {id} + /

/*
----------------------------------------------------------
! VENTE API ROUTE                                     |
----------------------------------------------------------
*/
// export const ADD_VENTE_API_ROUTE = DEFAULT_API + 'agences/'
export const GET_VENTE_API_ROUTE = DEFAULT_API + 'ventes/agences/' // + {id} + /
export const ADD_VENTE_API_ROUTE = DEFAULT_API + "ventes/";
export const UPDATE_VENTE_API_ROUTE = DEFAULT_API + "ventes/";
export const GET_VENTE_DETAILS_API_ROUTE = DEFAULT_API + "ventes/details/";
export const DELETE_VENTE_API_ROUTE = DEFAULT_API + "ventes/";
export const CREATE_NEW_VENTE_API_ROUTE = DEFAULT_API + "ventes/articles/";
export const UPDATE_VENTE_ARITCLE_API_ROUTE = DEFAULT_API + "ventes/articles/"; // + {id}/
export const DELETE_VENTE_ARITCLE_API_ROUTE = DEFAULT_API + "ventes/articles/"; 
// export const GET_ALL_VENTE_API_ROUTE = DEFAULT_API + 'agences/'

/*
----------------------------------------------------------
! DEPENSES API ROUTE                                     |
----------------------------------------------------------
*/
export const GET_DEPENSES_BY_AGENCE_API_ROUTE =
  DEFAULT_API + "depenses/agences/";
export const GET_BY_ID_DPENSES_API_ROUTE = DEFAULT_API + "depenses/details/"; // + {id}/
export const ADD_DEPENSES_API_ROUTE = DEFAULT_API + "depenses/";
export const UPDATE_DEPENSES_API_ROUTE = DEFAULT_API + "depenses/"; // + {id}/
export const DELETE_DEPENSES_API_ROUTE = DEFAULT_API + "depenses/"; // + {id}/
export const UPDATE_DEPENSES__ARICLE_API_ROUTE = DEFAULT_API + "depenses/articles/";
export const CREATE_NEW_DEPENSE_ARTICLE_API_ROUTE = DEFAULT_API + "depenses/articles/";
export const DELETE_DEPENSES__ARICLE_API_ROUTE = DEFAULT_API + "depenses/articles/"; // + {id}/

export const INSCRIPTION_PROPRIETAIRE = DEFAULT_API + "proprietaires/";
/*
----------------------------------------------------------
! PROPRIETAIRE API ROUTE                                     |
----------------------------------------------------------
*/
export const GET_PROPRIETAIRE_KPIS = "";

// export const GET_AGENCE_API_ROUTE = DEFAULT_API +'agence/'

export const ADD_AGENCE_API_ROUTE = DEFAULT_API + "agences/";
export const DELETE_AGENCE = DEFAULT_API + "agences/";
export const GET_AGENCE_API_ROUTE = DEFAULT_API + "agences/?proprietaireId=";
export const UPDATE_AGENCE_API_ROUTE = DEFAULT_API + "agences/"; //+{agenceId}
export const GET_ALL_AGENCE_API_ROUTE = DEFAULT_API + "agences/";
export const GET_CURRENT_AGENCE = DEFAULT_API + "agences/";

export const USER_API_ROUTE = DEFAULT_API + "user/";

export const COMMANDE_API_ROUTE = DEFAULT_API + "commandes/";
