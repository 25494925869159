import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import { useDispatch, useSelector } from "react-redux";
import ApiController from "../../redux/api_controller";
import { toast } from "react-toastify";
import { clearCurrentClient } from "../../redux/reducers/clients";
import BtnLoading from "../../components/BtnLoading";

const UpdateClient = ({ modalIsOpen, setIsOpen }) => {

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.users.currentUser);
  const currentClient = useSelector((state) => state.clients.currentClient);
  const agenceList = useSelector((state) => state.agences.listAgence);
  const [loading, setLoading] = useState(false)



  const [client, setClient] = useState({
    adresse: '',
    agenceId: '',
    email: '',
    firstName: '',
    lastName: '',
    telephone: '',
    createdBy: currentUser.id,
  })
  const { adresse, agenceId, email, firstName, lastName, telephone, createdBy } = client

  useEffect(() => {
    setClient(currentClient)
  }, [currentClient])


  function closeModal() {
    dispatch(clearCurrentClient());
    setIsOpen(false);
  }

  const handleChange = (e) => {
    setClient({ ...client, [e.target.name]: e.target.value })
  }

  const submit = (e) => {
    e.preventDefault()
    setLoading(true)

    const formData = new FormData()
    formData.append('firstName', firstName)
    formData.append('lastName', lastName)
    formData.append('email', email)
    formData.append('telephone', telephone)
    formData.append('adresse', adresse)
    formData.append('agenceId', agenceId)
    formData.append('createdBy', createdBy)

    let clientId = currentClient.id;

    ApiController.clients
      .updateClient(clientId, formData)
      .then((response) => {
        closeModal();
        toast.success("Client modifiée avec succés");
      })
      .catch((err) => {
        if (err.response.status === 400) {
          console.log(err.response)
          toast.error({ message: "veuillez remplir tous les champ !" })

        }
      })
      .finally(() => {
        setLoading(false)
      })
  };


  return (
    <Modal
      opened={modalIsOpen}
      title="Modifier le client"
      className="modal-container"
      onClosed={closeModal}
    >
      <form className="py-0 my-0" onSubmit={submit}>
        <div className="row my-md-3">
          <div className="col-12 col-md ">
            <label className="add-client-input">
              Prénom
              <input
                type="text"
                className=""
                name="firstName"
                value={firstName}
                onChange={handleChange}

              />
              {/* <small className='text-danger'>{error.firstName}</small> */}
            </label>
          </div>
          <div className="col-12 col-md">
            <label className="add-client-input">
              Nom
              <input
                type="text"
                className=""
                name="lastName"
                value={lastName}
                onChange={handleChange}
              />
              {/* <small className='text-danger'>{error_list.lastName}</small> */}

            </label>
          </div>
        </div>
        <div className="row my-md-3">
          <div className="col-12 col-md ">
            <label className="add-client-input">
              Email
              <input
                type="email"
                className=""
                name="email"
                value={email}
                onChange={handleChange}
              />
              {/* <small className='text-danger'>{error_list.email}</small> */}

            </label>
          </div>
          <div className="col-12 col-md add-client-input">
            <label className="add-client-input">
              Mobile
              <input
                type="text"
                className=""
                value={telephone}
                name="telephone"
                onChange={handleChange}
              />
              {/* <small className='text-danger'>{error_list.telephone}</small> */}

            </label>
          </div>
        </div>
        <div className="row my-md-3">
          <div className="col-12 col-md add-client-input">
            <label htmlFor="">
              Selectionnez une agence
              <select
                className=""
                aria-label="Default select example"
                name="agenceId"
                value={agenceId}
                onChange={handleChange}
              >
                {/* <option value={agenceId}>{agenceId}</option> */}
                {agenceList.map((item) => {
                  return (
                    <>
                      <option value={item.id}>{item.nomAgence}</option>
                      {/* <small className='text-danger'>{error_list.agenceId}</small> */}

                    </>
                  );
                })}
              </select>
            </label>
          </div>
          <div className="col-12 col-md ">
            <label className="">
              Adresse
              <input
                type="text"
                className=""
                name="adresse"
                value={adresse}
                onChange={handleChange}
              />
              {/* <small className='text-danger'>{error_list.adresse}</small> */}

            </label>
          </div>
        </div>
        <div className="mt-4">
          <BtnLoading bgColor="#00BEC9" color="#FFFFFF" right={30} type="submit" loading={loading} text="Enregistrer" />
        </div>
      </form>
    </Modal>
  );
};

export default UpdateClient;
