const isValidMobilePhone = phone => {
    const validMobilePhoneRegEx = /((\+221|00221)?)((7[7608][0-9]{7}$)|(3[03][98][0-9]{6}$))/;
    return validMobilePhoneRegEx.test(phone);
}
const isValidText = txt => {
    const validText = /^[a-zA-Z éèêëâ]*$/;
    return validText.test(txt);
}
const isValidNumber = txt => {
    const validText = /[0-9]*$/;
    return validText.test(txt);
}
const isValidEmail = txt => {
    const validText = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return validText.test(txt);
}
  
module.exports = { isValidMobilePhone , isValidText,isValidNumber,isValidEmail };