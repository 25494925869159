import axios from 'axios'
import { addNewagence, editAgences, setCurrentAgence, setIdsAgence, setListagences } from '../reducers/agences'
import { store } from '../store/store'

const { ADD_AGENCE_API_ROUTE, GET_AGENCE_API_ROUTE, GET_ALL_AGENCE_API_ROUTE, UPDATE_AGENCE_API_ROUTE, DELETE_AGENCE, GET_AGENCE_KPIS, GET_CURRENT_AGENCE } = require("../../routes/api_routes")

const dispatch = store.dispatch
const agences = {
    // getAllAgenceList(){
    //     return new Promise((resolve,reject)=>{
    //         axios.get(GET_ALL_AGENCE_API_ROUTE)
    //         .then(response => {
    //             // console.log("from agences action ", response.data.data)
    //             if(response.status === 200){
    //                 let agences = response.data.data
    //                 dispatch(setListagences(agences))
    //                 resolve(agences)
    //             }else{
    //                 reject(response)
    //             }
    //         })
    //     })
    // },
    getAgenceList(id) {
        return new Promise((resolve, reject) => {
            axios.get(GET_AGENCE_API_ROUTE + `${(id)}`)
                .then(response => {
                    // console.log("from agence action ", response)
                    if (response.status === 200) {
                        let agences = response.data.data
                        let idsAgence = []
                        agences.forEach(agence => {
                            idsAgence.push(agence.id)
                        })
                        // console.log({ idsAgence });
                        dispatch(setIdsAgence(idsAgence))
                        dispatch(setListagences(agences))
                        resolve(agences)
                    } else {
                        reject(response)
                    }
                })
        })
    },
    registerAgence(data) {
        return new Promise((resolve, reject) => {
            axios.post(ADD_AGENCE_API_ROUTE, data)
                .then(response => {
                    if (response.status === 201) {
                        let agence = response.data.data
                        // console.log('agence: ', agence.data);
                        dispatch(addNewagence(agence))
                        resolve(agence)
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },
    updateAgence({ data, agence_id }) {
        return new Promise((resolve, reject) => {
            axios.put(`${UPDATE_AGENCE_API_ROUTE}${agence_id}/`, data)
                .then(response => {
                    if (response.status === 200) {
                        let agence = response.data.data
                        dispatch(editAgences(agence))
                        resolve(agence)
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },
    deleteAgence(agence_id) {
        return new Promise((resolve, reject) => {
            axios.delete(`${DELETE_AGENCE + agence_id}/`)
                .then(response => {
                    if (response.status === 204) {
                        resolve(response)
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },
    getCurrentAgence(agence_id) {
        return new Promise((resolve, reject) => {
            axios.get(`${GET_CURRENT_AGENCE + agence_id}/`)
                .then(response => {
                    if (response.status === 200) {
                        dispatch(setCurrentAgence(response.data.data))
                        // console.log({ response: response.data.data });
                        resolve(response)
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    }
}
export default agences