import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import CurrentUser from "../hooks/CurrentUser";
import Template from "./Template";
import Navbar from "../components/navbar/Navbar";
import SideBar from "../components/sidebar/SideBar";
import { LOGIN_PATH } from "../utils/navigation_paths";

export default function RequierAuth() {
  const user = CurrentUser();
  const location = useLocation()

  return (user && user?.user_type === 'proprietaire') ? (
    <Template navBar={<Navbar />} sideBar={<SideBar />}>
      <Outlet />
    </Template>
  ) : (
    <Navigate to={LOGIN_PATH} state={{ from: location }} replace />
  );
}
