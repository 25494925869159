import React from "react";
import f_loo from "../../images/f_loo.png";
import Group7 from "../../images/Group7.png";
import { Link } from "react-router-dom";
// import './MdpOublier.css';

function MdpOublier() {
  return (
    <div>
      <div className=" row mdp-top-bar m-0 p-0 shadow">
        <div className="col me-auto text-start d-flex flex-row">
          <img src={f_loo} alt="" />
          <p className="barre-mdp my-2 "></p>
          <b className="custom-color-primary ps-2 pt-2 mt-2 font-Montserrat text-white">
            Fewnu Pack
          </b>
        </div>
        <p className="col-3 d-none d-md-block text-end me-md-3 pt-2">
          <Link
            className="custom-color-primary text-decoration-none font-Montserrat text-white"
            to="/register"
          >
            Inscrivez-vous!
          </Link>
        </p>
      </div>
      <div className="container">
        <div className="row pt-5 mt-5 container">
          <div className="col-md-6">
            <img src={Group7} alt="" />
          </div>
          <div className="col-md-6">
            <div className="card cartes w-75 px-3 py-4 border-0">
              <h5 className="pt-2">Mot de passe oublié?</h5>
              <p className="py-2">
                Pour réinitialiser votre mot de passe entrez votre numéro de
                téléphone
              </p>
              <input
                type="text"
                placeholder="Téléphone"
                className="form-control my-2 border-0"
              />
              <button
                type="submit"
                className="btn btn-default send mt-4 ms-auto text-bold"
              >
                Envoyer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MdpOublier;
