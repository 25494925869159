import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ApiController from '../../redux/api_controller'
import FactureArticles from '../../components/facture/FactureArticles'
import { addNewArticlesToCurrentFacture, removeArticleFromCurrentFacture } from '../../redux/reducers/factureArticles'
import { toast } from 'react-toastify'
import SelectFactureFournisseur from '../../components/facture/fournisseur/SelectFactureFournisseur'
import BtnLoading from '../../components/BtnLoading'

const UpdateDepensePage = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const { disableProduct, currentFacture } = useSelector(state => state.factureArticles)
    const currenteDepense = useSelector(state => state.depenses.currentDepense)
    // const currentVenteId = currentVente.id
    const navigate = useNavigate()

    useEffect(() => {
        setLoading(true)
        ApiController.depenses.getDepenseByID(currenteDepense.id, dispatch).finally(() => setLoading(false))
    }, [currenteDepense.id, dispatch])

    return (
        <div>
            <div className="text-start mb-3">
                <p className='fs-4 px-md-0 fw-bold'>Modification de la facture de dépense</p>
            </div>
            <FactureArticles
                loadingResource={loading}
                createNewFactureArticle={(product) => {
                    dispatch(addNewArticlesToCurrentFacture(product))
                    let index = currentFacture.findIndex(article => article.product.id === product.id)
                    // console.log({ index, product });
                    if (index !== -1) {
                        let article = currentFacture.find(article => article.product.id === product.id)
                        // console.log({ article });
                        ApiController.depenses.updateArticle(article.articleId, { quantity: article.quantity + 1 }, dispatch)
                        return
                    }
                    ApiController.depenses.createNewDepenseArticle(
                        { quantity: 1, productId: product.id, price: product.prixAchat, depense: currenteDepense.id },
                        dispatch
                    )
                        .catch(() => {
                            toast.error("Une erreur est survenue lors de l'ajout d'un nouvel article, veuillez réessayer")
                            // !on supprime le produit sur la currentFacture et on le remet dans la liste des produits en cas d'erreur
                            dispatch(removeArticleFromCurrentFacture({ product }))
                        })
                }}
                deleteFactureArticle={(article) => {
                    dispatch(removeArticleFromCurrentFacture({ product: article.product }))
                    ApiController.depenses.deleteArticle(article, dispatch)
                        .catch(() => {
                            toast.error('Une erreur est survenue lors de la suppression, veuillez réessayer')
                            dispatch(addNewArticlesToCurrentFacture(article.product))
                        })
                }}
                updateArticleQuantityOnChange={(article, data) => {
                    ApiController.depenses.updateArticle(article.articleId, data, dispatch)
                }}
            >
                <SelectFactureFournisseur
                    isUpdating={true}
                />
                <div className='d-flex gap-2'>
                    <BtnLoading
                    onClick={() => navigate(-1)}
                        bgColor="#bc3433"
                        color="#FFFFFF"
                        right={30}
                        loading={false}
                        text="Annuler"
                    />
                <BtnLoading
                    disabled={disableProduct}
                    onClick={() => navigate(-1)}
                    bgColor="#00BEC9"
                    color="#FFFFFF"
                    right={30}
                    loading={loading}
                    text="Modifier"
                />
                </div>
            </FactureArticles>
        </div>
    )
}

export default UpdateDepensePage