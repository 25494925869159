import React from 'react'

const Skeleton = () => {
    return (
        <div className="movie--isloading shadow-sm">
            <div className="loading-image"></div>
            <div className="loading-content">
                <div className="loading-text-container">
                    <div className="loading-main-text"></div>
                    <div className="loading-main-text"></div>
                    {/* <div className="loading-sub-text"></div> */}
                </div>
                {/* <div className="loading-btn"></div> */}
            </div>
        </div>
    )
}

export default Skeleton