import React from 'react'
import ResourceDataMap from './ResourceDataMap'
import EmptyList from '../EmptyList'

const ResourceDataMapperWithEmptyMessage = ({
    isLoading,
    resourceItem: ResourceItem,
    resourceName,
    resourceData = [],
    emptyMessage
}) => {
    // console.log({ resourceData });
    return (
        <>
            {
                isLoading ? <p>loading....</p> :
                    resourceData.length ? <ResourceDataMap
                        resourceItem={ResourceItem}
                        resourceName={resourceName}
                        resourceData={resourceData}
                    /> : <EmptyList message={emptyMessage} />
            }
        </>
    )
}

export default ResourceDataMapperWithEmptyMessage
