import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataDisplayTemplate from "../../components/DataDisplayTemplate";
// import { filterClientsList } from "../../redux/reducers/clients";
import { filterDepenseList, setCurrentDepense } from "../../redux/reducers/depenses";
import { DETAILS_DEPENSE_PATH, NEW_DEPENSE_PATH, UPDATE_DEPENSE_PATH } from "../../utils/navigation_paths";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { resetFactureSelectedClient, resetFactureSelectedFournisseur, setFactureSelectedFournisseur, setIsFactureForDepense, setIsUpdatingFacture } from "../../redux/reducers/factureArticles";
import { IoMdEye } from "react-icons/io";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import { tooltip_options } from "../../utils/tooltip_options";
import ConfirmAction from "../../components/ConfirmAction";
import ApiController from "../../redux/api_controller";
import { useNavigate } from "react-router-dom";
// import { filterFournisseursList } from "../../redux/reducers/fournisseurs";

const DepensesPage = () => {
  const { allListDepenses, listDepense } = useSelector(state => state.depenses)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
  const [selectedDepense, setSelectedDepense] = useState(null)

  useEffect(() => {
    dispatch(setIsFactureForDepense(true))
    dispatch(setIsUpdatingFacture(false))
    dispatch(resetFactureSelectedFournisseur())
    dispatch(resetFactureSelectedClient())
  }, [])

  const navigateToEditFacture = (row) => {
    dispatch(setCurrentDepense(row))
    dispatch(setFactureSelectedFournisseur(null))
    dispatch(setIsUpdatingFacture(true))
    if (row.fournisseur) {
      dispatch(setFactureSelectedFournisseur({ value: row.fournisseur.id, label: `${row.fournisseur.firstName} ${row.fournisseur.lastName}` }))
    }
    navigate(UPDATE_DEPENSE_PATH)
  }

  const showDeleteModal = (row) => {
    setOpenDeleteConfirmation(true)
    setSelectedDepense(row)
  }

  const showDetailsModal = (depense) => {
    dispatch(setCurrentDepense(depense))
    navigate(DETAILS_DEPENSE_PATH)
  }


  const deleteDepenseFunc = () => {
    ApiController.depenses
      .deleteDepense(selectedDepense.id, dispatch)
  }

  const showActionButtons = (cell, row) => {
    return (
      <div >
        <IoMdEye
          style={{ cursor: 'pointer' }}
          data-tip data-for="detailsClientTip"
          className='custom-mouse-clickable mx-3 fs-5 custom-eye-icon'
          onClick={() => showDetailsModal(row)}
        />
        {row.status === 'ENCOURS' ?
          <FiEdit
            style={{ cursor: 'pointer' }}
            data-tip data-for="editClientTip"
            className='custom-mouse-clickable mx-3 fs-5 custom-edit-icon'
            onClick={() => navigateToEditFacture(row)}
          />
          :
          <FiEdit aria-disabled={true} className='custom-mouse-clickable text-secondary mx-3 fs-5' />
        }
        {row.status === 'ENCOURS' ?
          <RiDeleteBinLine
            style={{ cursor: 'pointer' }}
            data-tip data-for="deleteClientTip"
            className='custom-mouse-clickable mx-3 fs-5 custom-delete-icon'
            onClick={() => showDeleteModal(row)}
          />
          :
          <RiDeleteBinLine data-tip data-for="deleteClientTip" className='custom-mouse-clickable mx-3 fs-5 text-secondary' />
        }
        <ReactTooltip id="detailsClientTip" {...tooltip_options}  >
          Détails
        </ReactTooltip>
        <ReactTooltip id="editClientTip" {...tooltip_options}  >
          Modifier
        </ReactTooltip>
        <ReactTooltip id="deleteClientTip" {...tooltip_options}  >
          Supprimer
        </ReactTooltip>
      </div>
    )
  }

  const columns = [
    {
      dataField: "id",
      text: "Numéro",
      headerClasses: 'custom-table-column text-white col-1',
      formatter: (cell, row) => <p className=''
      //  onClick={() => showDetailsModal(row)}
      >{row.matricule}</p>
    },
    {
      dataField: "created_at",
      text: "Date",
      headerClasses: 'custom-table-column text-white col-2',
      formatter: (cell, row) => {
        var selected_date = new Date(row.created_at)
        return (
          <p key={row} className=''
          // onClick={() => showDetailsModal(row)}
          >
            {selected_date?.toLocaleDateString('fr-SN')}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {selected_date?.toLocaleTimeString('fr-SN', { hour: 'numeric', minute: 'numeric' })}
          </p>
        )
      }
    },
    {
      dataField: "fournisseur",
      text: "Fournisseur",
      headerClasses: 'custom-table-column text-white',
      formatter: (cell, row) => <p className=''
      // onClick={() => showDetailsModal(row)}
      >{row.fournisseur?.firstName ? `${row.fournisseur.firstName} ${row.fournisseur.lastName}` : "Pas de fournisseur"}</p>
    },
    {
      dataField: "montant",
      text: "Montant",
      headerClasses: 'custom-table-column text-white col-2',
      formatter: (cell, row) => {
        // let montant_total = row.list_products.reduce((prev, prod) => {
        //   return prev + prod.total
        // }, 0);
        return <p className=''
        // onClick={() => showDetailsModal(row)}
        >{row.montantTotal + ' XOF'}</p>
      }
    },
    {
      dataField: "emis",
      text: "Payé",
      headerClasses: 'custom-table-column text-white col-1',
      formatter: (cell, row) => <p className=''
      // onClick={() => showDetailsModal(row)}
      >{row.status === 'PAYE' ? 'Oui' : 'Non'}</p>
    },
    {
      dataField: "action",
      text: "Actions",
      headerClasses: 'custom-table-column text-white col-2',
      formatter: showActionButtons
    },
  ];
  return <div>
    <ConfirmAction
      modalText="Suppression dépense"
      text="Cette action est irréversible!"
      modalIsOpen={openDeleteConfirmation}
      cancel={() => setOpenDeleteConfirmation(false)}
      closeModal={() => setOpenDeleteConfirmation(false)}
      validate={deleteDepenseFunc}
    />
    <DataDisplayTemplate
      isDataFetching={false}
      onChange={(e) => dispatch(filterDepenseList(e.target.value))}
      title="Dépenses"
      description="Créez, consultez et gérez vos dépenses. "
      link={NEW_DEPENSE_PATH}
      onClick={null}
      btnText=""
      allData={allListDepenses}
      data={listDepense}
      columns={columns}
      messageOnEmpty="Pas de dépenses"
    />
  </div>;
};

export default DepensesPage;
