import React from 'react'
import { BsPhone } from 'react-icons/bs';
import { FiClock, FiEdit2, FiMail, FiTrash } from 'react-icons/fi';
import { MdLocationCity } from 'react-icons/md';
import { useSelector } from 'react-redux'
import { dayJsFormat } from '../../../utils/dayJsFormat';
import { useState } from 'react';
import EditAgence from '../EditAgence';
import ConfirmAction from '../../../components/ConfirmAction';
import ApiController from '../../../redux/api_controller';
import { useAgenceId } from '../../../hooks/useAgenceId';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { AGENCES_PATH } from '../../../utils/navigation_paths';

const dayjs = require('dayjs')

dayJsFormat()

const AgenceParametresPage = () => {
    const { currentAgence } = useSelector(state => state.agences)
    const [isEdit, setIsEdit] = useState(false)
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
    const { agenceId } = useAgenceId()
    const navigate = useNavigate()
    // console.log({ currentAgence });

    const deleteAgence = () => {
        ApiController.agences.deleteAgence(agenceId).then((res) => {
            console.log({ res });
            if (res.status === 204) {
                toast.success('Suppression réuissie !')

                return navigate(AGENCES_PATH)
            }
            return toast.error('Une erreur est survenue lors de la suppression')
        }).catch((err) => {
            return toast.error('Une erreur est survenue lors de la suppression')
        })
    }

    return (
        <div>
            {/* <div className='row'> */}
            <ConfirmAction
                modalText="Supprimer agence"
                text="Voulez-vous vraiment supprimer cette agence?"
                cancel={() => setOpenDeleteConfirmation(false)}
                closeModal={() => setOpenDeleteConfirmation(false)}
                modalIsOpen={openDeleteConfirmation}
                validate={deleteAgence}
            />
            <div className='card col-lg-6'>
                {
                    !isEdit ? <div>
                        <div className='card-header d-flex justify-content-between align-items-center'>
                            <h6>{currentAgence.nomAgence}</h6>
                            <div>
                                <button
                                    onClick={() => setOpenDeleteConfirmation(true)}
                                    className='btn btn-danger me-2'
                                    type="button">
                                    <FiTrash />
                                </button>
                                <button
                                    onClick={() => setIsEdit(true)}
                                    className='btn border hover-1 '
                                    type="button"
                                >
                                    <FiEdit2 />
                                </button>
                            </div>
                        </div>
                        <div className='card-body'>
                            <p className=' fs-6'><FiMail /> {currentAgence.email}</p>
                            <p className='card-text fs-6'><BsPhone /> {currentAgence.phone
                            }</p>
                            <p className='card-text fs-6'><MdLocationCity /> {currentAgence.addresse}</p>

                            <p><FiClock /> <span className='fs-6'>Créée il y a {dayjs(currentAgence.created_at).fromNow(true)}</span></p>
                        </div>
                    </div> : <div className='card-body'>
                        <button
                            onClick={() => setIsEdit(false)}
                            className='btn border hover-1 '
                            type="button"
                        >
                            Annuler
                        </button>
                            <EditAgence setIsEdit={setIsEdit} />
                    </div>
                }
            </div>

            {/* </div> */}
        </div>
    )
}

export default AgenceParametresPage