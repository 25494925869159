import React, { useState } from "react";
import { RiDeleteBinLine } from 'react-icons/ri';
import { FiEdit } from 'react-icons/fi';
import AddClient from "./AddClient";
import { useDispatch, useSelector } from "react-redux";
import { filterClientsList, setCurrentClient } from "../../redux/reducers/clients";
import UpdateClient from "./UpdateClient";
import DataDisplayTemplate from "../../components/DataDisplayTemplate";
import DeleteClient from "./DeleteClient";

const ClientsPage = () => {
  const dispatch = useDispatch();
  const { listClient: clientList, allListClients } = useSelector((state) => state.clients);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  // console.log({ clientList });

  const showUpdateModal = (client) => {
    dispatch(setCurrentClient(client))
    openUpdateClientModal();
  };

  const showDeleteModal = (client) => {
    dispatch(setCurrentClient(client))
    openDeleteClientModal()
  }
  // const showDetailsClient =(client)=>{
  //   dispatch(setCurrentClient(client))
  // }

  // useEffect(() => {
  //   // ! getClients list
  //   setIsClientsFetching(true)
  //   ApiController.clients.getClientsByAgence(agenceId).finally(() => { setIsClientsFetching(false) })
  // }, [agenceId])

  function openDeleteClientModal() {
    setDeleteModalIsOpen(true);
  }


  function openAddClientModal() {
    setAddModalIsOpen(true);
  }

  function openUpdateClientModal() {
    setUpdateModalIsOpen(true);
  }

  const showActionButtons = (cell, row) => {
    return (
      <div >
        {/* <IoMdEye data-tip data-for="detailsClientTip" className='custom-mouse-clickable mx-3 fs-5 custom-eye-icon' onClick={()=>showDetailsClient(row)}/> */}
        <FiEdit data-tip data-for="editClientTip" className='custom-mouse-clickable mx-3 fs-5 custom-edit-icon' onClick={() => showUpdateModal(row)} />
        <RiDeleteBinLine data-tip data-for="deleteClientTip" className='custom-mouse-clickable mx-3 fs-5 custom-delete-icon' onClick={() => showDeleteModal(row)} />

      </div>
    )
  }


  const columns = [
    {
      dataField: 'id',
      text: 'Client ID'
    },
    {
      dataField: 'firstName',
      text: 'Prenom',
      // filter: textFilter()

    },
    {
      dataField: 'lastName',
      text: 'Nom',
      // filter: textFilter()

    },
    {
      dataField: 'email',
      text: 'Email'
    },
    {
      dataField: 'telephone',
      text: 'Telephone'
    },
    {
      dataField: 'adresse',
      text: 'Adresse'
    },
    {
      dataField: 'actions',
      text: 'Actions',
      formatter: showActionButtons
    },
  ];


  return (
    <div className="p-0">
      <AddClient modalIsOpen={addModalIsOpen} setIsOpen={setAddModalIsOpen} />
      <UpdateClient modalIsOpen={updateModalIsOpen} setIsOpen={setUpdateModalIsOpen} />
      <DeleteClient modalIsOpen={deleteModalIsOpen} setIsOpen={setDeleteModalIsOpen} />

      <DataDisplayTemplate
        isDataFetching={false}
        onChange={(e) => dispatch(filterClientsList(e.target.value))}
        title="Clients"
        description="Créez, consultez et gérez vos enregistrements de clients. "
        onClick={openAddClientModal}
        btnText=""
        allData={allListClients}
        data={clientList}
        columns={columns}
        messageOnEmpty="Pas de clients"
      />
    </div>
  );
};

export default ClientsPage;
