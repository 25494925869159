import React, { useState } from "react";
import { useRef } from "react";
import { useMemo } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import useWindowDimensions from "../hooks/useWindowsDimention";

const Template = ({ children, navBar, sideBar }) => {
  const [openedMenu, setOpenedMenu] = useState(false);
  const [minViewPort, setMinViewPort] = useState(false);

  const sidebarRef = useRef(null);

  const { width } = useWindowDimensions();
  const handleResize = () => {
    setOpenedMenu((v) => !v);
  };

  // if (minViewPort) {
  //   window.addEventListener("click", (e) => {
  //   });

  //   console.log('ici');
  // }

  useMemo(() => {
    // console.log("width: " + width);
    if (width <= 900) {
      setMinViewPort(true);
      setOpenedMenu(true);
    } else {
      setMinViewPort(false);
      setOpenedMenu(false);
    }
  }, [width]);

  return (
    <div className="interface-container">
      <div className="navbar-container">{navBar}</div>
      <div className="body-container">
        <div
          className={"sidebar-container "}
          style={{
            width: !openedMenu ? 250 : 60,
          }}
          ref={sidebarRef}
        >
          <div className="menu-controller" onClick={handleResize}>
            {openedMenu ? (
              <AiOutlineArrowRight className="menu-controller-icon" />
            ) : (
              <AiOutlineArrowLeft className="menu-controller-icon" />
            )}
          </div>
          {sideBar}
        </div>
        <div
          className="children-container py-5"
          style={{
            padding: openedMenu
              ? "70px 0px 0 60px"
              : minViewPort
                ? "70px 0px 0 60px"
                : "70px 0px 0 260px",
          }}
        >
          <div className="p-3">
          {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template;
