import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useParams } from 'react-router-dom'
import AgenceDetailsBanner from '../../components/AgenceDetailsBanner'
import AgenceTab from '../../components/AgenceTab'
import banner from '../../images/foto.png'
import { useAgenceId } from '../../hooks/useAgenceId'
import ApiController from '../../redux/api_controller'
import ReduxAction from '../../redux/api_controller/all'

const DetailsAgencePage = () => {
    const currentUser = useSelector(state => state.users.currentUser)
    const { agenceId } = useAgenceId()
    const { allListGerants } = useSelector(state => state.gerants)
    const dispatch = useDispatch();
    const { idAgence, nomAgence } = useParams()
    const gerantDeLagence = allListGerants.filter(gerant => (gerant.agenceId === Number(idAgence) && !gerant.agenceId))[0]
    // console.log({ gerantDeLagence });
    // console.log({ agenceId });

    useEffect(() => {
        // ! getClients list
        // ApiController.gerants.getgerantsList(currentUser.id)
        // ApiController.clients.getClientsByAgence(agenceId)
        // ApiController.fournisseurs.getFournisseursByAgence(agenceId)
        // ApiController.produits.getAgenceProducts(agenceId)
        // ApiController.ventes.getventesList(agenceId)
        // ApiController.depenses.getDepensesByAgence(agenceId)
        ReduxAction.getAllData(agenceId, currentUser.id, ApiController)
    }, [agenceId, currentUser.id, dispatch])

    return (
        <div>
            <AgenceDetailsBanner
                nomAgence={nomAgence}
                fullName={gerantDeLagence?.fullName}
                banner={banner}
                phone={gerantDeLagence?.phone}
                email={gerantDeLagence?.email}
                adresse={gerantDeLagence?.adresse}
            />
            <AgenceTab />
            {/* ********************* outlet *********************** */}
            <div style={{ boxSizing: 'border-box !important', WebkitBoxSizing: 'border-box', MozBoxSizing: 'border-box' }} className='p-0 py-sm-3'>
                <Outlet />
            </div>
        </div>
    )
}

export default DetailsAgencePage