import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import BtnLoading from '../../components/BtnLoading';
import Modal from '../../components/Modal';
import ApiController from '../../redux/api_controller';
import { isValidNumber, isValidText } from '../../utils/form_control';
import { useAgenceId } from '../../hooks/useAgenceId';

const EditProduct = ({ editModalIsOpen, setEditModalIsOpen }) => {
    const currentUser = useSelector(state => state.users.currentUser)
    const agenceList = useSelector((state) => state.agences.listAgence);
    const listCategories = useSelector(state => state.categories.listCategories)
    const currentProduct = useSelector(state => state.produits.currentProduct)
    const [loading, setLoading] = useState(false)
    const { agenceId } = useAgenceId()
    const { register, reset, formState: { errors }, handleSubmit, setError } = useForm({
        defaultValues: {
            name: currentProduct?.name,
            agenceId: currentProduct?.agenceId,
            categoryId: currentProduct?.categoryId,
            quantite: currentProduct?.quantite,
            minStock: currentProduct?.minStock,
            prixAchat: currentProduct?.prixAchat,
            prixVente: currentProduct?.prixVente,
            image: currentProduct?.image
        },
        values: currentProduct
    })

    const defaultMessage = 'Ce champ est obligatoire !'

    function closeModal() {
        reset()
        setEditModalIsOpen(false);

    }

    const submit = (data) => {
        data.createdBy = currentUser.id
        let { name, categoryId, minStock, prixAchat, prixVente, quantite, createdBy } = data

        let formData = new FormData()
        formData.append('name', name)
        formData.append('categoryId', categoryId)
        // formData.append('image',image[0])
        formData.append('minStock', minStock)
        formData.append('prixAchat', prixAchat)
        formData.append('prixVente', prixVente)
        formData.append('quantite', quantite)
        formData.append('createdBy', createdBy)

        setLoading(true)
        ApiController.produits.updateProduct(formData, data.id, agenceId)
            .then(response => {
                closeModal()
                toast.success('Produit modifié avec succés');
                reset()
            })
            .catch(err => {
                closeModal()
                toast.error('L\'ajout n\'a pas réuissi');
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const verificate = (data) => {
        if (false) {
            setError('categoryId', { message: defaultMessage })
        } else if (!isValidText(data.name)) {
            setError('name', { message: 'Nom non valide' })
        } else if (!isValidNumber(data.quantite)) {
            setError('quantite', { message: 'Quantité non valide' })
        } else if (parseInt(data.quantite) <= 0) {
            setError('quantite', { message: 'La valeur minimale est de 1' })
        } else if (!isValidNumber(data.minStock)) {
            setError('minStock', { message: 'Numéro non valide' })
        } else if (parseInt(data.minStock) <= 0) {
            setError('minStock', { message: 'La valeur minimale est de 1' })
        } else if (!isValidNumber(data.prixAchat)) {
            setError('prixAchat', { message: 'Prix non valide' })
        } else if (parseInt(data.prixAchat) <= 0) {
            setError('prixAchat', { message: 'La valeur minimale est de 1' })
        } else if (!isValidNumber(data.prixVente)) {
            setError('prixVente', { message: 'Prix non valide' })
        } else if (parseInt(data.prixVente) <= 0) {
            setError('prixVente', { message: 'La valeur minimale est de 1' })
        } else {
            submit(data)
        }
    }

    return (
        <>
            <Modal
                opened={editModalIsOpen}
                title="Modifier le produit"
                className="modal-container"
                onClosed={closeModal}
            >
                <form className="" onSubmit={handleSubmit(verificate)}>
                    <div className="row my-md-1">
                        <div className="col-12 col-md  ">
                            <label className=''>
                                Nom du produit
                                <input type='text' {...register('name', { required: defaultMessage })} className=' p-2' />
                                {errors.name && <span className='text-error--danger'>{errors.name.message}</span>}
                            </label>
                        </div>
                        <div className="col-12 mt-1 mt-md-0 col-md  ">

                            <label htmlFor="">
                                Agence
                                <select
                                    className=""
                                    aria-label="Default select example"
                                    {...register("agenceId", { required: defaultMessage })}
                                >
                                    {/* <option selected>Selectionnez une agence</option> */}
                                    {
                                        agenceList?.map((item, idx) => {
                                            return (
                                                <>
                                                    <option value={item.id} key={idx}>{item.nomAgence}</option>
                                                </>
                                            )
                                        })
                                    }

                                </select>
                                {errors.agenceId && <span className='text-error--danger'>{errors.agenceId.message}</span>}
                            </label>


                        </div>
                    </div>
                    <div className="row my-md-1">
                        <div className="col-12 mt-1 mt-md-0 col-md  ">
                            <label className=''>
                                Catégorie
                                <select
                                    className=""
                                    aria-label="Default select example"
                                    {...register("categoryId", { required: defaultMessage })}
                                >
                                    {/* <option selected>Selectionnez une agence</option> */}
                                    {
                                        listCategories?.map((item, idx) => {
                                            return (
                                                <>
                                                    <option value={item.id} key={idx}>{item.categoryName}</option>
                                                </>
                                            )
                                        })
                                    }

                                </select>
                                {errors.categoryId && <span className='text-error--danger'>{errors.categoryId.message}</span>}
                            </label>
                        </div>

                        <div className="col-12 mt-1 col-md  ">
                            <label className=''>
                                Quantité en stock
                                <input type='number' {...register('quantite', { required: defaultMessage })} className=' p-2' />
                                {errors.quantite && <span className='text-error--danger'>{errors.quantite.message}</span>}
                            </label>
                        </div>
                        <div className="col-12 mt-1 col-md  ">
                            <label className=''>
                                Quantité en seuil
                                <input type='number' required {...register('minStock', { quantite: defaultMessage })} className=' p-2' />
                                {errors.minStock && <span className='text-error--danger'>{errors.minStock.message}</span>}
                            </label>
                        </div>
                    </div>
                    <div className="row my-md-1">
                        <div className="col-12 mt-1 col-md  ">
                            <label className=''>
                                Prix d'achat
                                <input type='number' {...register('prixAchat', { required: defaultMessage })} className=' p-2' />
                                {errors.prixAchat && <span className='text-error--danger'>{errors.prixAchat.message}</span>}
                            </label>
                        </div>
                        <div className="col-12 mt-1 col-md  ">
                            <label className=''>
                                Prix de vente
                                <input type='number' {...register('prixVente', { required: defaultMessage })} className=' p-2' />
                                {errors.prixVente && <span className='text-error--danger'>{errors.prixVente.message}</span>}
                            </label>
                        </div>
                    </div>
                    <div className="row my-md-1">
                        <div className="col-12 mt-1 col-md  ">
                            <label className=''>
                                Image
                                <input accept='.png,.jpg,.jpeg,.svg' type='file' {...register('image')} className=' p-2' />
                                {errors.image && <span className='text-error--danger'>{errors.image.message}</span>}
                            </label>
                        </div>
                    </div>
                    <p className="text-end mt-4">
                        {/* <button type='submit' className='custom-button-secondary py-2 px-3 rounded-0'>Enregistrer</button> */}
                        {/* <BtnLoading loading={loading} text="Ajouter" bgColor=""  /> */}
                        <BtnLoading
                            bgColor="#00BEC9"
                            color="#FFFFFF"
                            right={30}
                            type="submit"
                            loading={loading}
                            text="Modifier"
                        />
                    </p>
                </form>
            </Modal>
        </>
    )
}

export default EditProduct