import React, { useRef } from 'react'
import { AiFillEdit } from 'react-icons/ai';
import ReactToPrint from 'react-to-print';
import { useAgenceName } from '../../../hooks/useAgenceName';
import { useAgence } from '../../../hooks/useAgence';
import { useAgenceAdresse } from '../../../hooks/useAgenceAdresse';
import { FactureAImprimer } from './FactureAImprimer';
import FactureAMontrer from './FactureAMontrer';
import { IoMdPrint } from 'react-icons/io'

const FactureDetails = ({
    factureProgressBar: FactureProgressBar,
    transactionStatus,
    factureFor,
    totalTransaction,
    fullName,
    phone,
    factureDate,
    dataTable,
    columns,
    transactionDate,
    openPaymentModal,
    onEdit,
    loadingFacture
}) => {

    const componentRef = useRef();
    const { nomAgence } = useAgenceName()
    const { agenceAdresse } = useAgenceAdresse()
    const { agence } = useAgence()

    return (
        <>
            <div className='d-none'>
                <FactureAImprimer
                    factureFor={factureFor}
                    ref={componentRef}
                    nomAgence={nomAgence}
                    factureDate={factureDate?.toLocaleDateString('fr-SN')}
                    agenceAdresse={agenceAdresse}
                    agenceEmail={agence.email}
                    agencePhone={agence.phone}
                    clientFullName={fullName}
                    clientPhone={phone}
                    dataTable={dataTable}
                    columns={columns}
                    totalTransaction={totalTransaction}
                />
            </div>
            <div className='row m-0 p-0 p-0 custom-height-max bg-light text-start'>
                <p className='fs-2 fw-bold my-0'>Détails de la facture </p>
                <p className='mb-4'>Voici votre facture,vous pouvez la télécharger ou l'imorimer directement</p>
                <div className='row m-0 p-0'>
                    <div className="col-12 col-md-12 col-xl-9 p-3 pdf-container mb-4 bg-white shadow-sm border">
                        <div
                            id='facture'
                            className='row m-3'
                        >
                            <FactureAMontrer
                                factureFor={factureFor}
                                loadingFacture={loadingFacture}
                                nomAgence={nomAgence}
                                factureDate={factureDate?.toLocaleDateString('fr-SN')}
                                agenceAdresse={agenceAdresse}
                                agenceEmail={agence.email}
                                agencePhone={agence.phone}
                                clientFullName={fullName}
                                clientPhone={phone}
                                dataTable={dataTable}
                                columns={columns}
                                totalTransaction={totalTransaction}
                            />
                        </div>
                    </div>
                    <div className='col-12 col-md-12 mb-4 col-xl-3 ps-xl-3 shadow-md'>
                        <div className='text-center facture-paiment-card mb-4 custom-card-shadow'>
                            <div className='d-block'>
                                {/* <FactureProgressBar /> */}
                            </div>
                            <div className='bg-white'>
                                <div className="row">
                                    <div className="col text-start px-4 py-2">
                                        <span className='fw-bold'>Payée</span>
                                        <p className=''>{transactionStatus === 'ENCOURS' ? '00' : totalTransaction} XOF</p>
                                    </div>
                                    <div className="col text-end px-4 py-2">
                                        <span className='fw-bold'>En cours</span>
                                        <p className=''>{transactionStatus === 'ENCOURS' ? totalTransaction : '00'} XOF</p>
                                    </div>
                                </div>
                                <div className="row px-4 pb-3">
                                    {transactionStatus === 'ENCOURS' ?
                                        <button onClick={openPaymentModal} className='custom-button-secondary py-2 fw-bold rounded-0 shadow-none'>Paiement facture</button>
                                        :
                                        <p className='fs-90 text-start'>
                                            <span className='text-decoration-underline custom-color-primary'>Payé</span> {transactionDate ? `le ${new Date(transactionDate).toLocaleDateString('fr-SN')} ` : null}
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='text-center bg-white facture-card-links text-start custom-card-shadow'>
                            <div className='row text-start p-3'>
                                <div className="d-flex justify-content-between">
                                    <ReactToPrint
                                        trigger={() => {
                                            return <button className='text-dark fs-70 btn border fs-70 p-2 action-btn'>
                                                <IoMdPrint className='custom-color-primary fs-5' />
                                                <span className='d-inline-block ms-1 fs-6'>Imprimer</span>
                                            </button>;
                                        }}
                                        content={() => componentRef.current}
                                    />
                                    <button
                                        disabled={transactionStatus === 'ENCOURS' ? 0 : 1}
                                        style={{ opacity: transactionStatus === 'ENCOURS' ? 1 : 0.45 }}
                                        onClick={onEdit} className='text-dark action-btn btn border fs-70 p-2'>
                                        <AiFillEdit className='custom-color-primary fs-5 ' />
                                        <span className='d-inline-block ms-1 fs-6'>Modifier</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FactureDetails