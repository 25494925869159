import React from 'react'

const SkeletonTableRow = () => {
    return (
        <div className="skeleton-row">
            <div className="skeleton-table"></div>
            <div className="skeleton-table"></div>
            <div className="skeleton-table"></div>
            <div className="skeleton-table"></div>
            <div className="skeleton-table"></div>
        </div>
    )
}

export default SkeletonTableRow