import React, { useEffect, useState } from 'react'
import { textFilter } from 'react-bootstrap-table2-filter'
import { useSelector } from 'react-redux'
import DataDisplayTemplate from '../../components/DataDisplayTemplate'
import ApiController from '../../redux/api_controller'
import AjouterCategory from './AjouterCategory'

const Category = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const listCategories = useSelector(state => state.categories.listCategories)
    const currentUser = useSelector(state => state.users.currentUser)
    const columns = [
        {
          dataField: 'categoryName',
          text: 'Nom catégorie',
          filter: textFilter()
        },
        {
          dataField: 'description',
          text: 'Description',
          filter: textFilter()
        }
      ];
      useEffect(() => {
        ApiController.categories.getCategoriesByCreatedBy(currentUser.id)
      }, []);
    return (
        <div>
            <AjouterCategory
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
            />
            <DataDisplayTemplate
          title="Catégories"
                description=""
                onClick={() => setModalIsOpen(true)}
          btnText=""
                data={listCategories}
                columns={columns}
          messageOnEmpty="Pas de catégories"

            />
        </div>
    )
}

export default Category