import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { setFactureSelectedFournisseur } from '../../../redux/reducers/factureArticles'
import ApiController from '../../../redux/api_controller'
import AddFactureSelectForm from '../AddFactureSelectForm'
import AddFournisseur from '../../../pages/fournisseurs/AddFournisseur'

const SelectFactureFournisseur = ({ isUpdating }) => {
    const dispatch = useDispatch()
    const { formState: { errors }, setError } = useForm()

    const currentUser = useSelector(state => state.users.currentUser)
    // const currentDepense = useSelector(state => state.depenseFacture.currenteDepense)
    const { currentDepense } = useSelector(state => state.depenses)
    const { selectedFactureFournisseur } = useSelector(state => state.factureArticles)

    // console.log({ selectedFactureFournisseur });

    const { allFournisseur } = useSelector(state => state.fournisseurs) || []
    // const currentVente = useSelector(state => state.ventes.currentVente)

    // console.log({ allFournisseur });

    const [fournisseurModalIsOpen, setFournisseurModalIsOpen] = useState(false)

    const addNewFournisseur = () => {
        setFournisseurModalIsOpen(true)
    }

    const fournisseurOptions = allFournisseur.map(fournisseur => {
        return { value: fournisseur.id, label: fournisseur.firstName + ' ' + fournisseur.lastName }
    })
    // .concat([{ value: '+', label: <p className='custom-mouse-clickable text-secondary p-2 m-0' onClick={addNewFournisseur}><AiOutlinePlusCircle /> Ajouter un fournisseur</p> }])
    // .reverse();

    const changeSelectedFournisseur = (fournisseur) => {
        // console.log({ fournisseur });
        dispatch(setFactureSelectedFournisseur(fournisseur))
        if (isUpdating) {
            ApiController.depenses.updateDepense(currentDepense.id, { fournisseurId: fournisseur.value }, dispatch)
            return
        }
        // dispatch(setSelectedFournisseur(fournisseur))
    }

    return (
        <div className='row p-2'>
            <div className='col-8'>
                <AddFactureSelectForm
                    title="Fournisseurs"
                    concerned="fournisseur"
                    placeholder="--selectionner fournisseur--"
                    // handleSubmit={handleSubmit(submit)}
                    value={selectedFactureFournisseur}
                    onChange={(fournisseur) => fournisseur.value !== '+' && fournisseur.value !== selectedFactureFournisseur?.value && changeSelectedFournisseur(fournisseur)}
                    error={
                        errors.client && !selectedFactureFournisseur && <span className='custom-color-danger text-center'>{errors.fournisseur.message}</span>
                    }
                    modal={<AddFournisseur modalIsOpen={fournisseurModalIsOpen} setIsOpen={setFournisseurModalIsOpen} />}
                    options={fournisseurOptions}
                // firstCondition={isDepenseCreated}
                // secondCondition={isFournisseurChanged}
                // secondConditionProove={isDepenseCreated}
                />
            </div>
            <div className='col-4 ps-2'>
                <button
                    onClick={addNewFournisseur}
                    type="button"
                    className='btn btn-add p-2 px- w-100'
                >
                    + Fournisseur
                </button>
            </div>
        </div>
    )
}

export default SelectFactureFournisseur