import React, { useEffect } from 'react'
import SelectFactureClient from '../../components/facture/client/SelectFactureClient'
import BtnLoading from '../../components/BtnLoading'
import { useState } from 'react'
import FactureArticles from '../../components/facture/FactureArticles'
import { setFactureSelectedClient } from '../../redux/reducers/factureArticles'
import { useDispatch, useSelector } from 'react-redux'
import { useAgenceId } from '../../hooks/useAgenceId'
import CurrentUser from '../../hooks/CurrentUser'
import ApiController from '../../redux/api_controller'
import { useNavigate } from 'react-router-dom'

const AddVenteFacture = () => {
    const { currentFacture, selectedFactureClient } = useSelector(state => state.factureArticles)
    const [loading, setLoading] = useState(false)
    const [isProductloading, setIsProductloading] = useState(false)
    const dispatch = useDispatch()
    const { agenceId } = useAgenceId()
    const currentUser = CurrentUser()
    const navigate = useNavigate()

    // console.log({ currentFacture });

    useEffect(() => {
        dispatch(setFactureSelectedClient(null))
        setIsProductloading(true)
        ApiController.produits.getAgenceProducts(agenceId).then((res) => {
            // console.log({ res });
        }).finally(() => {
            setIsProductloading(false)
        })
    }, [])

    const confirmFacture = () => {
        const articles = currentFacture.map(article => {
            const data = {
                productId: Number(article.product.id),
                quantity: Number(article.quantity),
                price: article.product.prixVente
            }
            return data
        })

        const newVente = {
            clientId: selectedFactureClient ? selectedFactureClient.value : '',
            createdBy: currentUser.id,
            agenceId,
            articles
        }

        setLoading(true)
        ApiController.ventes.createNewVente(newVente, dispatch).then(res => {
            // toast.success('Vente effectuée')
            navigate(-1)
        }).catch(error => {
            // toast.error('Une erreur est survenu')
        })
            .finally(() => {
                setLoading(false)
            })
    }
    return (
        <div className='p-0'>
            <h4 className="agences-title">Nouvelle vente</h4>
            <FactureArticles
                loadingResource={isProductloading}
            >
                <SelectFactureClient />
                <div className='d-flex gap-2'>
                    <BtnLoading
                        onClick={() => navigate(-1)}
                        bgColor="#bc3433"
                        color="#FFFFFF"
                        right={30}
                        loading={false}
                        text="Annuler"
                    />
                <BtnLoading
                    onClick={confirmFacture}
                    bgColor="#00BEC9"
                    color="#FFFFFF"
                    right={30}
                    loading={loading}
                    text="Enregistrer"
                />
                </div>
            </FactureArticles>
        </div>
    )
}

export default AddVenteFacture