import React from 'react'
import Modal from 'react-modal';

export const UpdateVente = ({modalIsOpen,setIsOpen}) => {

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            height:'68%',
            width:'60%',
            minWidth:'330px',
            shadow:'0px 0px 5px black',
            background:'#eee',
            borderRadius:'0px',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    };
    
    function afterOpenModal() {
    }

    function closeModal() {
        // reset()
        setIsOpen(false);
    }


    return (
        <div>
            <Modal
                overlayClassName={'add-client-modal-overlay'}
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
            <form className=" px-md-3 py-0 my-0">
                <div className="row m-0 p-0">
                    <p className="col-9 fs-4 px-3">Modifier Une Vente</p>
                    <p className="col text-end fw-bold fs-4"><span className='close border-dark rounded px-2 custom-mouse-clickable' onClick={closeModal}>X</span></p>
                </div>
                <div className="row my-md-3">
                    
                    <div className="col-12 col-md mx-md-3 ">
                        <label className='add-client-input'>
                            Prénom
                            <input type='text'  className='form-control' />
                            {/* {'errors'.firstName && <span className='custom-color-danger'>{errors.firstName.message}</span>} */}
                        </label>
                    </div>
                    <div className="col-12 col-md mx-md-3">
                        <label className='add-client-input'>
                            Nom
                            <input type='text'  className='form-control' />
                            {/* {errors.lastName && <span className='custom-color-danger'>{errors.lastName.message}</span>} */}
                        </label>
                    </div>
                </div>
                <div className="row my-md-3">
                    <div className="col-12 col-md mx-md-3 ">
                        <label className='add-client-input'>
                            Email
                            <input type='email' className='form-control' />
                            {/* {errors.email && <span className='custom-color-danger'>{errors.email.message}</span>} */}
                        </label>
                    </div>
                    <div className="col-12 col-md mx-md-3 add-client-input">
                        <label className='add-client-input'>
                            Mobile
                            <input type='number'  className='form-control'/>
                            {/* {errors.telephone && <span className='custom-color-danger'>{errors.telephone.message}</span>} */}
                        </label>
                    </div>
                </div>
                <div className="row my-md-3">
                    <div className="col-12 col-md mx-md-3 add-client-input">
                        <label className='add-client-input'>
                            Référence
                            <input type='number' className='form-control' />
                            {/* {errors.reference && <span className='custom-color-danger'>{errors.reference.message}</span>} */}
                        </label>
                    </div>
                    <div className="col-12 col-md mx-md-3 ">
                        <label className=''>
                            Adresse
                            <input type='text'  className='form-control'/>
                            {/* {errors.adresse && <span className='custom-color-danger'>{errors.adresse.message}</span>} */}
                        </label>
                    </div>
                </div>
                <p className="text-end mt-4">
                    <button type='submit' className='custom-button-secondary add  py-2 px-3 mx-3 mt-3'>Enregistrer</button>
                </p>
            </form>
            </Modal>
        </div>
    );
}

export default UpdateVente