
const { TfiShoppingCart } = require('react-icons/tfi')
const { FiUsers } = require('react-icons/fi')
const { AiOutlineCalendar, AiOutlineSetting } = require('react-icons/ai')
const { HiOutlineCube } = require('react-icons/hi')
const { BsCalendarCheck } = require('react-icons/bs')
const { TbClipboardList } = require('react-icons/tb')
const { GiMoneyStack } = require('react-icons/gi')
const { MdOutlineDashboardCustomize, MdCategory } = require('react-icons/md')
const { ACCUEIL_PATH, AGENCES_PATH, GERANT_PATH, VENTES_PATH, DEPENSE_PATH, STOCK_PATH, INVENTAIRE_PATH, PARAMETRE_PATH, BILAN_PATH, CATEGORIES_PATH } = require('./navigation_paths')

export const sidebar_items_top = [
    {
        text: 'Acceuil',
        targetPath: ACCUEIL_PATH,
        icon: MdOutlineDashboardCustomize,
    },
    {
        text: 'Agences',
        targetPath: AGENCES_PATH,
        icon: TfiShoppingCart,
    },
    {
        text: 'Gérants',
        targetPath: GERANT_PATH,
        icon: FiUsers,
    },
    // {
    //     text: 'Clients',
    //     targetPath: CLIENTS_PATH,
    //     icon: FaRegAddressBook,
    // },
    // {
    //     text: 'Fournisseurs',
    //     targetPath: FOURNISSEURS_PATH,
    //     icon: RiUserFollowLine,
    // },
    // {
    //     text: 'Ventes',
    //     targetPath: VENTES_PATH,
    //     icon: AiOutlineCalendar,
    // },
    // {
    //     text: 'Dépenses',
    //     targetPath: DEPENSE_PATH,
    //     icon: GiMoneyStack,
    // },
    // {
    //     text: 'Stock',
    //     targetPath: STOCK_PATH,
    //     icon: HiOutlineCube,
    // },
    // {
    //     text: 'Inventaire',
    //     targetPath: INVENTAIRE_PATH,
    //     icon: TbClipboardList,
    // },
    // {
    //     text: 'Bilan',
    //     targetPath: BILAN_PATH,
    //     icon: BsCalendarCheck,
    // },
    // {
    //     text: 'Produits',
    //     targetPath: PRODUITS_PATH,
    //     icon: FaProductHunt,
    // },
    {
        text: 'Catégories',
        targetPath: CATEGORIES_PATH,
        icon: MdCategory,
    },
]

export const sidebar_items_bottom = [
    // {
    //     text: 'Paramètres',
    //     targetPath: PARAMETRE_PATH,
    //     icon: AiOutlineSetting,
    // },
    // {
    //     text: 'Déconnexion',
    //     targetPath: 'oiu',
    //     icon: AiOutlineLogout,
    // },
]