import React from "react";
import Stat from "../../components/Stat";

function FournisseursKpi() {
  return (
    <div className="container-fluid">
      <h1 className="mb-3" style={{ fontWeight: 400, fontSize: 18 }}>
        Les donnees des differentes FOURNISSEURS
      </h1>
      <Stat label={"Fournisseur"} />
    </div>
  );
}

export default FournisseursKpi;
