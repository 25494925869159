import { createSlice } from "@reduxjs/toolkit/"

let initialState = {
    allListClients: [],
    listClient: [],
    currentClient: {},
}
const clientsSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        setListClients: (state, { payload }) => {
            state.listClient = payload
            state.allListClients = payload
        },
        addNewClient: (state, { payload }) => {
            state.listClient = [...state.allListClients, payload]
            state.allListClients = [...state.allListClients, payload]
        },
        pushToClientsList: (state, { payload }) => {
            state.listClient = [...state.allListClients, ...payload]
        },
        setCurrentClient: (state, { payload }) => {
            state.currentClient = payload

        },
        updateClient: (state, { payload }) => {
            state.currentClient = payload
            let listClient = state.allListClients.filter(item => item.id !== payload.id)
            state.listClient = [...listClient, payload].reverse()
        },

        deleteCurrentClient: (state, { payload }) => {
            state.listClient = state.allListClients.filter(item => item.id !== payload)
        },
        clearCurrentClient: (state) => {
            state.currentClient = {}
        },
        filterClientsList: (state, { payload }) => {
            state.listClient = state.allListClients.filter(item => {
                let data = [item.firstName, item.lastName, item.adresse, item.email, item.phone].join(' ').toLowerCase()
                return data.includes(payload.toLowerCase())
            })
        },
    }
})

export const { addNewClient, setListClients, setCurrentClient, deleteCurrentClient, updateClient, clearCurrentClient, pushToClientsList, filterClientsList } = clientsSlice.actions
export default clientsSlice.reducer