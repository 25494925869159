import { createSlice } from "@reduxjs/toolkit";

const proprietaireSlice = createSlice({
    name: 'proprietaire',
    initialState: { proprietaireKpis: null },
    reducers: {
        setProprietaireKpis: (state, { payload }) => {
            state.proprietaireKpis = payload
        }
    }
})

export const { setProprietaireKpis } = proprietaireSlice.actions

export default proprietaireSlice.reducer