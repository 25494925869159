import React from 'react';
// import './navbar.css'
import { RxDividerVertical } from 'react-icons/rx'
import { MdNotificationsNone } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { clearCurrentUser } from '../../redux/reducers/users';
const Navbar = () => {
    const currentUser = useSelector(state => state.users.currentUser)
    // const navigate = useNavigate()
    const dispatch = useDispatch()

    const logout = () => {
        dispatch(clearCurrentUser())
    }

    return (
        <div className='navbar-container text-white row m-0 p-0'>
            <div className="col text-start p-0">
                <img src={require('../../images/icons/icon1.png')} alt="" />
                <RxDividerVertical size={40} />
                <span className="navbar-title d-none d-sm-inline fw-bold mt-5">Fewnu Compta</span>
                {/* <span className="navbar-title fw-bold mt-5">Fewnu Compta & Facturation</span> */}
            </div>
            <div className="col text-end row align-items-center p-0 m-0 pe-2">
                <div className=''>
                    <MdNotificationsNone size={22} className='mx-2' />
                    <span>
                        <span role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {currentUser?.fullName}
                        </span>
                        <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink">
                            <li
                                style={{ cursor: 'pointer' }}
                                onClick={logout}
                                className='ps-2'
                            >
                                {/* <Link className="dropdown-item" href="#"
                                    onClick={logout}
                                > */}
                                Deconnexion
                                {/* </Link> */}
                            </li>
                            {/* <li><Link className="dropdown-item" href="#">Another action</Link></li>
                            <li><Link className="dropdown-item" href="#">Something else here</Link></li> */}
                        </ul>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Navbar;