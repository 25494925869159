import React, { useState } from 'react';
import Modal from '../../components/Modal';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ApiController from '../../redux/api_controller';
import BtnLoading from '../../components/BtnLoading';
import ResourceDataMap from '../../components/shared/ResourceDataMap';


export default function AjouterGerant({ modalIsOpen, setIsOpen, gerants, onGerantAdd }) {
    const currentUser = useSelector(state => state.users.currentUser)
    const agenceList = useSelector((state) => state.agences.listAgence);
    const [loading, setLoading] = useState(false)
    const { register, reset, formState: { errors }, handleSubmit, setError } = useForm()

    function closeModal() {
        reset()
        setIsOpen(false);

    }

    // console.log({ currentUser });

    const submit = (data) => {
        setLoading(true)
        const newData = {
            agenceId: data.agenceId,
            phone: data.phone,
            fullName: data.fullName,
            password: data.password,
            email: data.email !== '' ? data.email : null,
            adresse: data.adresse,
            proprietaireId: currentUser.id
        }
        // console.log(newData);
        ApiController.gerants.registerGerant(newData)
            .then((res) => {
                // console.log(res)
                closeModal()
                toast.success('Gérant ajouté avec succès');
            })
            .catch((err) => {
                // console.log("ERROR", err);
                if (err.response.status === 400) {
                    if (err.response.data.message.phone) {
                        // console.log('reorr inside');
                        return setError("phone", { type: 'custom', message: "Le numéro de téléphone est déjà pris" })
                    }
                    if (err.response.data.message.email) {
                        return setError("email", { type: 'custom', message: "L'email est déjà pris" })
                    }

                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <>
            <Modal
                opened={modalIsOpen}
                title="Ajouter un gérant"
                className="modal-container"
                onClosed={closeModal}
            >
                <form className=" py-0 my-0" onSubmit={handleSubmit(submit)}>
                    <div className="row">
                        <div className="col-12 col-md-6 mt-3">
                            <label className='add-client-input'>
                                Nom complet
                                <input type='text' className='' {...register("fullName", { required: 'Nom complet obligatoire' })} />
                                {errors.fullName && <span className='text-error--danger'>{errors.fullName.message}</span>}
                            </label>
                        </div>
                        <div className="col-12 col-md-6 mt-3 add-client-input">
                            <label className='add-client-input'>
                                Email
                                <input type='email' className='' {...register("email")} />
                                {errors.email && <span className='text-error--danger'>{errors.email.message}</span>}
                            </label>
                        </div>
                        <div className="col-12 col-md-6 mt-3 ">
                            <label className='add-client-input'>
                                Telephone
                                <input type='tel' className=' w-100' {...register("phone", { required: 'Numero téléphone obligatoire' })} />
                                {errors.phone && <span className='text-error--danger'>{errors.phone.message}</span>}
                            </label>
                        </div>
                        <div className="col-12 col-md-6 mt-3 ">
                            <label className='add-client-input'>
                                Mot de passe
                                <input type='password' className=' w-100' {...register("password", { required: 'Mot de passe obligatoire' })} />
                                {errors.password && <span className='text-error--danger'>{errors.password.message}</span>}
                            </label>
                        </div>
                        <div className="col-12 col-md-6 mt-3 ">
                            <label className=''>
                                Adresse
                                <input type='text' className='' {...register("adresse")} />
                            </label>
                        </div>
                        <div className="col-12 col-md-6 mt-3 add-client-input">
                            <label htmlFor="">
                                Agence
                                <select
                                    className=""
                                    aria-label="Default select example"
                                    {...register("agenceId")}
                                >
                                    <option value=''>Selectionnez une agence</option>
                                    <ResourceDataMap
                                        resourceItem={({ option }) => <option value={option.id}>{option.nomAgence}</option>}
                                        resourceName="option"
                                        resourceData={agenceList}
                                    />
                                    {/* {
                                        agenceList?.map((item, idx) => {
                                            return (
                                                <>
                                                    <option value={item.id} key={idx}>{item.nomAgence}</option>
                                                </>
                                            )
                                        })
                                    } */}

                                </select>
                            </label>
                        </div>

                    </div>
                    <div className="mt-4">
                        <BtnLoading bgColor="#00BEC9" color="#FFFFFF" right={30} type="submit" loading={loading} text="Enregistrer" />
                    </div>
                </form>
            </Modal>
        </>
    )
}
