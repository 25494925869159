import { store } from "../store/store"
import axios from 'axios'
import { addNewvente, deleteCurrentvente, setCurrentVenteDetails, setListventes, updatevente } from "../reducers/ventes"
import { editProductArticleId, setCurrenteFactureForUpdate } from "../reducers/factureArticles"
const { ADD_vente_API_ROUTE, GET_vente_BY_AGENCE_API_ROUTE, GET_VENTE_API_ROUTE, ADD_VENTE_API_ROUTE, DELETE_VENTE_API_ROUTE, CREATE_NEW_VENTE_API_ROUTE, UPDATE_VENTE_ARITCLE_API_ROUTE, GET_VENTE_DETAILS_API_ROUTE, DELETE_VENTE_ARITCLE_API_ROUTE, UPDATE_VENTE_API_ROUTE } = require("../../routes/api_routes")

const dispatch = store.dispatch

const ventes = {
    getventesList(id) {
        return new Promise((resolve, reject) => {
            axios.get(GET_VENTE_API_ROUTE + `${id}/`)
                .then(response => {
                    // console.log(response.data);
                    if (response.status === 200) {
                        let ventes = response.data.data
                        dispatch(setListventes(ventes))
                        resolve(ventes)
                    } else {
                        reject(response)
                    }
                })
                .catch(res => reject(res))
        })
    },
    getventesByAgence(agenceId) {
        return new Promise((resolve, reject) => {
            axios.get(GET_vente_BY_AGENCE_API_ROUTE + `${agenceId}/`)
                .then(response => {
                    if (response.status === 200) {
                        let ventes = response.data
                        dispatch(setListventes(ventes))
                        resolve(ventes)
                    } else {
                        reject(response)
                    }
                })
                .catch(res => reject(res))
        })
    },
    registervente(data) {
        return new Promise((resolve, reject) => {
            axios.post(ADD_vente_API_ROUTE, data)
                .then(response => {
                    if (response.status === 201) {
                        let vente = response.data
                        dispatch(addNewvente(vente))
                        resolve(vente)
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },

    getventeById(id) {
        return new Promise((resolve, reject) => {
            // console.log({ id });
            axios.get(GET_VENTE_DETAILS_API_ROUTE + `${id}/`)
                .then(response => {
                    // console.log({ response });
                    if (response.status === 200) {
                        let vente = response.data
                        // console.log({ vente });
                        dispatch(setCurrentVenteDetails(vente))
                        // dispatch(setCurrentvente(vente))
                        dispatch(setCurrenteFactureForUpdate(vente))
                        resolve(ventes)
                    } else {
                        reject(response)
                    }
                })
                .catch(res => reject(res))
        })
    },
    updatevente(venteId, data, despatch) {
        return new Promise((resolve, reject) => {
            axios.put(UPDATE_VENTE_API_ROUTE + `${venteId}/`, data)
                .then(response => {
                    if (response.status === 200) {
                        let vente = response.data.data
                        dispatch(updatevente(vente))
                        resolve(vente)
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },
    deletevente(vente_id) {
        return new Promise((resolve, reject) => {
            axios.delete(DELETE_VENTE_API_ROUTE + `${vente_id}`)
                .then(response => {
                    if (response.status === 204) {
                        resolve(response)
                        dispatch(deleteCurrentvente(vente_id))
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },
    createNewVente(data, dispatch) {
        return new Promise((resolve, reject) => {
            axios.post(ADD_VENTE_API_ROUTE, data)
                .then(response => {
                    // console.log({ response });
                    if (response.status === 201) {
                        let newVente = response.data
                        console.log({ newVente });
                        dispatch(addNewvente(newVente))
                        // dispatch(setCurrentVent(newVente))
                        resolve(newVente)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    createNewVenteArticle(data, dispatch) {
        return new Promise((resolve, reject) => {
            axios.post(CREATE_NEW_VENTE_API_ROUTE, data)
                .then(response => {
                    if (response.status === 201) {
                        let newArticleCreated = response.data.data
                        // dispatch(addNewVente(newVente))
                        /**
                         * ! ceci permet d'ajouter "articleId" sur le produit qu'on vient d'ajouter
                         */
                        dispatch(editProductArticleId(newArticleCreated))

                        // ! on recupere la vente pour affhicher les derniere modification sur les details de la facture
                        // this.getVenteByID(newArticleCreated.vente, dispatch, token)
                        resolve(newArticleCreated)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    updateArticle(id, data, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.put(UPDATE_VENTE_ARITCLE_API_ROUTE + `${id}/`, data)
                .then(response => {
                    if (response.status === 200) {
                        let article = response.data.data
                        // console.log({ article });
                        resolve(article)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    deleteVenteArticle({ article, vente }, dispatch) {
        return new Promise((resolve, reject) => {
            axios.delete(DELETE_VENTE_ARITCLE_API_ROUTE + `${article.articleId}/`)
                .then(response => {
                    if (response.status === 204) {
                        // console.log({ article, response });
                        // dispatch(deleteArticleByIdSlice(article))
                        // dispatch(removeArticleFromCurrentFacture(article))
                        // ! on recupere la vente pour affhicher les derniere modification sur les details de la facture
                        // this.getVenteByID(vente, dispatch, token)
                        resolve(article)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },

}
export default ventes