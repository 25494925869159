import axios from "axios";
import {
  addNewagence,
  editAgences,
  setCurrentAgence,
  setIdsAgence,
  setListagences,
} from "../reducers/agences";
import { store } from "../store/store";
import { GET_KPIS_API_ROUTE } from "../../routes/api_routes";
import { setListKpis } from "../reducers/kpis";

const dispatch = store.dispatch;
const kpis = {
  gets(id) {
    return new Promise((resolve, reject) => {
      axios.get(GET_KPIS_API_ROUTE + `${id}`).then((response) => {
        console.log("from kpis action ", response);
        if (response.status === 200) {
          let agences = response.data.data;
          //   let idsAgence = [];
          //   agences.forEach((agence) => {
          //     idsAgence.push(agence.id);
          //   });
          // console.log({ idsAgence });
          dispatch(setListKpis(agences));
          //   dispatch(setListagences(agences));
          resolve(agences);
        } else {
          reject(response);
        }
      });
    });
  },
  add(data) {
    return new Promise((resolve, reject) => {
      axios
        .post("*********URL*********", data)
        .then((response) => {
          if (response.status === 201) {
            let agence = response.data.data;
            // console.log('agence: ', agence.data);
            dispatch(addNewagence(agence));
            resolve(agence);
          } else {
            reject(response);
          }
        })
        .catch((error) => reject(error));
    });
  },
  update({ data, id }) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${"*********URL*********"}${id}/`, data)
        .then((response) => {
          if (response.status === 200) {
            let agence = response.data.data;
            dispatch(editAgences(agence));
            resolve(agence);
          } else {
            reject(response);
          }
        })
        .catch((error) => reject(error));
    });
  },
  delete(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${"*********URL*********" + id}/`)
        .then((response) => {
          if (response.status === 204) {
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => reject(error));
    });
  },
  get(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${"*********URL*********" + id}/`)
        .then((response) => {
          // console.log({ response });
          if (response.status === 200) {
            dispatch(setCurrentAgence(response.data.data));
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => reject(error));
    });
  },
};
export default kpis;
