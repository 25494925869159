import { createSlice } from "@reduxjs/toolkit/"
// import { agenceGenerator } from "../../utils/data_generator"

// let data = agenceGenerator(100)

let initialState = {
    currentAgence: {},
    listAgence: [],
    allAgencesList: [],
    idsAgence: []
}
const agenceSlice = createSlice({
    name: 'agences',
    initialState,
    reducers: {
        setListagences: (state, { payload }) => {
            state.listAgence = payload
            state.allAgencesList = payload
        },
        addNewagence: (state, { payload }) => {
            state.listAgence = [...state.listAgence, payload]
            state.allAgencesList = [...state.allAgencesList, payload]
        },
        setCurrentAgence: (state, { payload }) => {
            state.currentAgence = payload
            // let listagence = state.allAgencesList.filter(item => item.id !== payload.id)
            // state.listAgence = [...listagence, payload]
        },
        editAgences: (state, { payload }) => {
            state.currentAgence = payload
            let listagence = state.allAgencesList.filter(item => item.id !== payload.id)
            state.listAgence = [...listagence, payload]
        },
        resetCurrentagence: (state, { payload }) => {
            state.currentAgence = payload
        },
        deleteCurrentagence: (state, { payload }) => {
            state.listAgence = state.allAgencesList.filter(item => item.id !== payload)
        },
        setIdsAgence: (state, { payload }) => {
            state.idsAgence = payload
        }
    }
})

export const { addNewagence, setListagences, setCurrentAgence, deleteCurrentagence, resetCurrentagence, setIdsAgence,editAgences } = agenceSlice.actions
export default agenceSlice.reducer