import React from 'react'
import Modal from './Modal'
import { FiAlertTriangle } from 'react-icons/fi'

const ConfirmAction = ({modalText, text, cancel, validate, modalIsOpen, closeModal }) => {
    return (
        <Modal
            opened={modalIsOpen}
            title={modalText}
            className="modal-container"
            onClosed={closeModal}
        >
            <div className='p-3'>
                <p className='text-center'>{text}</p>
                <p className='text-center text-danger'>
                    <FiAlertTriangle />
                </p>
                <div className='d-flex justify-content-center gap-4 mt-4'>
                    <button onClick={cancel} className='btn btn-danger px-4' type="button">Non</button>
                    <button onClick={validate} className='btn btn-success px-4' type="button">Oui</button>
                </div>
            </div>
        </Modal>
    )
}

export default ConfirmAction