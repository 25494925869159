import React, { useEffect, useState } from 'react'
import FactureArticles from '../../components/facture/FactureArticles'
import SelectFactureClient from '../../components/facture/client/SelectFactureClient'
import BtnLoading from '../../components/BtnLoading'
import { useDispatch, useSelector } from 'react-redux'
import { addNewArticlesToCurrentFacture, removeArticleFromCurrentFacture, setIsFactureForDepense, setIsUpdatingFacture } from '../../redux/reducers/factureArticles'
import ApiController from '../../redux/api_controller'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const UpdateVentePage = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState()
    const [isVenteLoading, setIsVenteLoading] = useState(false)
    const navigate = useNavigate()
    const { currentFacture } = useSelector(state => state.factureArticles)
    const { currentVente } = useSelector(state => state.ventes)
    const currentVenteId = currentVente.id

    // console.log({ currentVenteId });

    useEffect(() => {
        dispatch(setIsFactureForDepense(false))
        dispatch(setIsUpdatingFacture(true))
        setIsVenteLoading(true)
        ApiController.ventes.getventeById(currentVenteId).finally(() => {
            setIsVenteLoading(false)
        })
    }, [])

    return (
        <div className='p-0'>
            <h4 className="agences-title">Mise à jour vente</h4>
            <FactureArticles
                loadingResource={isVenteLoading}
                createNewFactureArticle={(product) => {
                    dispatch(addNewArticlesToCurrentFacture(product))
                    let index = currentFacture.findIndex(article => article.product.id === product.id)
                    // console.log({ index, product });
                    if (index !== -1) {
                        let article = currentFacture.find(article => article.product.id === product.id)
                        // console.log({ article });
                        ApiController.ventes.updateArticle(article.articleId, { quantity: article.quantity + 1 }, dispatch)
                        return
                    }
                    ApiController.ventes.createNewVenteArticle(
                        { quantity: 1, productId: product.id, vente: currentVenteId, price: product.prixVente },
                        dispatch
                    )
                        .catch(() => {
                            toast.error("Une erreur est survenue lors de l'ajout d'un nouvel article, veuillez réessayer")
                            // !on supprime le produit sur la currentFacture et on le remet dans la liste des produits en cas d'erreur
                            dispatch(removeArticleFromCurrentFacture({ product }))
                        })
                }}
                deleteFactureArticle={(article) => {
                    dispatch(removeArticleFromCurrentFacture({ product: article.product }))
                    ApiController.ventes.deleteVenteArticle({ article, vente: currentVenteId }, dispatch)
                        .catch(() => {
                            toast.error('Une erreur est survenue lors de la suppression, veuillez réessayer')
                            dispatch(addNewArticlesToCurrentFacture(article.product))
                        })
                }}
                updateArticleQuantityOnChange={(article, data) => {
                    // console.log('changing: ', { article });
                    ApiController.ventes.updateArticle(article.articleId, data, dispatch)
                }}
            >
                <SelectFactureClient isUpdating={true} />
                <div className='d-flex gap-2'>
                    <BtnLoading
                        onClick={() => navigate(-1)}
                        bgColor="#bc3433"
                        color="#FFFFFF"
                        right={30}
                        loading={false}
                        text="Annuler"
                    />
                <BtnLoading
                    onClick={() => navigate(-1)}
                    bgColor="#00BEC9"
                    color="#FFFFFF"
                    right={30}
                    loading={loading}
                    text="Modifier"
                />
                </div>
            </FactureArticles>
        </div>
    )
}

export default UpdateVentePage