import { createSlice } from "@reduxjs/toolkit/"
import { ACCUEIL_PATH } from "../../utils/navigation_paths"

let initialState = {
    currentItem: ACCUEIL_PATH
}
const navigationSlice = createSlice({
    name:'navigation',
    initialState,
    reducers:{
        setCurrentItem:(state,{payload})=>{
            state.currentItem = payload
        }
    }
})

export const { setCurrentItem} = navigationSlice.actions
export default navigationSlice.reducer