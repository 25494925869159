import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import CurrentUser from '../hooks/CurrentUser'
import { ACCUEIL_PATH } from '../utils/navigation_paths'

const PublicLayout = () => {
    const user = CurrentUser();
    const location = useLocation()
    return !user ? (
        <Outlet />
    ) : <Outlet />
}

export default PublicLayout