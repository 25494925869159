import React, { useState } from 'react'
import DataDisplayTemplate from '../../components/DataDisplayTemplate'
import AjoutProduit from './AjoutProduitModal'
import ApiController from '../../redux/api_controller';
import { useSelector } from 'react-redux';
import { DEFAULT_IMAGE_API } from '../../routes/api_routes';
import { AiFillDelete, AiFillEdit } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { filterProductsList, setCurrentProduct } from '../../redux/reducers/products';
import EditProduct from './EditProduct';
import ConfirmAction from '../../components/ConfirmAction';
import { useAgenceId } from '../../hooks/useAgenceId';

const Produits = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)
  const { listProducts, listProductsAll } = useSelector(state => state.produits)
  const currentUser = useSelector(state => state.users.currentUser)
  const [openValideAction, setOpenValideAction] = useState(false)
  const [productIdToDelete, setProductIdToDelete] = useState(null)
  const dispatch = useDispatch()
  const { agenceId } = useAgenceId()

  // console.log({ listProductsAll });

  function imageFormatter(cell, row) {
    // console.log(`${DEFAULT_IMAGE_API}${cell}`);

    return (
      <>
        <img style={{ maxHeight: '50px' }} className='img-fluid' src={`${DEFAULT_IMAGE_API}${cell}`} alt="" />
        {/* <span>$ {cell} NTD</span> */}
      </>
    );
  }
  function actionsFormatter(cell, row) {

    return (
      <div className='d-flex gap-2'>
        <button onClick={() => {
          // ! recuperer une ligne de produit
          // console.log(row);
          dispatch(setCurrentProduct(row))
          setEditModalIsOpen(true)
        }} className='btn' type="button">
          <AiFillEdit size={20} />
        </button>
        <button onClick={() => {
          setProductIdToDelete(row.id)
          setOpenValideAction(true)
          // ApiController.produits.deleteProduct(row.id, currentUser.id)
        }} className='btn' type="button">
          <AiFillDelete size={20} />
        </button>
      </div>
    );
  }
  const columns = [
    // {
    //   dataField: 'image',
    //   text: 'Image',
    //   formatter: imageFormatter
    // },
    {
      dataField: 'name',
      text: 'Libelle',
      // filter: textFilter()
    },
    {
      dataField: 'quantite',
      text: 'Quantite'
    },
    {
      dataField: 'minStock',
      // filter: textFilter(),
      text: 'Stock minimal',
    },
    {
      dataField: 'prixAchat',
      // filter: textFilter(),
      text: 'Prix achat',
    },
    {
      dataField: 'prixVente',
      // filter: textFilter(),
      text: 'Prix vente',
    },
    {
      // dataField: 'prixVente',
      text: 'Actions',
      formatter: actionsFormatter
    },

  ];

  // useEffect(() => {
  //   // ! getting agence products
  //   setIsProductLoading(true)
  //   ApiController.produits.getAgenceProducts(agenceId).finally(() => { setIsProductLoading(false) })
  // }, [agenceId]);

  return (
    <div>
      <AjoutProduit
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
      />
      <EditProduct
        editModalIsOpen={editModalIsOpen}
        setEditModalIsOpen={setEditModalIsOpen}
      />
      <ConfirmAction
        modalText="Confirmer la suppression"
        modalIsOpen={openValideAction}
        text="Etes vous sûr de supprimer ce produit?"
        cancel={() => setOpenValideAction(false)}
        closeModal={() => setOpenValideAction(false)}
        validate={() => {
          ApiController.produits.deleteProduct(productIdToDelete, agenceId).then(() => {
            setOpenValideAction(false)
          })
        }}
      />
      <DataDisplayTemplate
        isDataFetching={false}
        title="Produits"
        description="Ayez un oeil sur vos produits"
        onChange={(e) => dispatch(filterProductsList(e.target.value))}
        onClick={() => setModalIsOpen(true)}
        btnText="Ajouter un produit"
        allData={listProductsAll}
        data={listProducts}
        columns={columns}
        messageOnEmpty="Pas de produits"

      />
    </div>
  )
}

export default Produits