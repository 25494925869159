import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import EmptyList from './EmptyList'
import filterFactory from 'react-bootstrap-table2-filter';
import { AiFillPlusCircle } from 'react-icons/ai';
import { FiSearch } from 'react-icons/fi';
import { Link } from 'react-router-dom';

const DataDisplayTemplate = ({ title, description, link, btnText, data, onClick, columns, messageOnEmpty, value, onChange, allData, isDataFetching }) => {
    return (
        <div className=''>
            <div className="d-flex justify-content-between align-items-center m-0">
                <div className="">
                    <h4 className="agences-title">{title}</h4>
                </div>
                {
                    link && <Link className="btn-add btn-resize" to={link}>
                        <AiFillPlusCircle className="icon-resize" />
                    </Link>
                }

                {
                    onClick && <button
                        className="btn-add btn-resize"
                        type="button"
                        onClick={onClick}
                    >
                        {btnText} <AiFillPlusCircle className="icon-resize" />
                    </button>
                }
            </div>
            <p className=" text-dark">
                {description}
            </p>
            {
                allData?.length ? <form className='my-2'>
                    <div className='position-relative'>
                        <FiSearch size={20} className='search-icon' />
                        <input
                            onChange={onChange}
                            placeholder='Rechercher'
                            className='filter-btn'
                            type="search" name=""
                            value={value} />
                    </div>
                </form> : null
            }
            <div className="pt-3">
                {
                    !isDataFetching ?
                        data?.length ?
                            <BootstrapTable
                                keyField='id'
                                data={data}
                                columns={columns}
                                pagination={paginationFactory()}
                                filter={filterFactory()}
                            />
                            :
                            <EmptyList message={messageOnEmpty} />
                        : <h1>loading........................</h1>
                }
            </div>
        </div>
    )
}

export default DataDisplayTemplate