import { createSlice } from "@reduxjs/toolkit/"

let initialState = {
    currentProduct: {},
    listProducts: [],
    listProductsAll: []
}
const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setCurrentProduct: (state, { payload }) => {
            state.currentProduct = payload
        },
        setProductsList: (state, { payload }) => {
            state.listProducts = payload
            state.listProductsAll = payload
        },
        addNewProduct: (state, { payload }) => {
            console.log({ payload });
            state.listProducts = [...state.listProductsAll, payload]
            state.listProductsAll = [...state.listProductsAll, payload]
        },
        pushToProductList: (state, { payload }) => {
            state.listProducts = [...state.listProductsAll, ...payload]
            state.listProductsAll = [...state.listProductsAll, ...payload]
        },
        resetCurrentProduct: (state, { payload }) => {
            state.currentProduct = {}
        },
        updtateroduct: (state, { payload }) => {

        },
        filterProductsList: (state, { payload }) => {
            state.listProducts = state.listProductsAll.filter(item => {
                let data = [item.libelle, item.prixAchat, item.prixVente].join(' ').toLowerCase()
                return data.includes(payload.toLowerCase())
            })
        },
    }
})

export const { setCurrentProduct, setProductsList, addNewProduct, resetCurrentProduct, filterProductsBySearch, updtateroduct, pushToProductList, filterProductsList } = productsSlice.actions
export default productsSlice.reducer