import { createSlice } from "@reduxjs/toolkit/";

let initialState = {
  listKpis: [],
  currentKpi: {},
};
const kpiSlice = createSlice({
  name: "kpis",
  initialState,
  reducers: {
    setListKpis: (state, { payload }) => {
      state.listKpis = payload;
    },
    clearCurrentKpis: (state) => {
      state.currentKpi = {};
      state.currentKpi = [];
    },
  },
});

export const { setListKpis, clearCurrentKpis } = kpiSlice.actions;
export default kpiSlice.reducer;
