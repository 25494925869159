import axios from "axios"
import { ADD_DEPENSES_API_ROUTE, CREATE_NEW_DEPENSE_ARTICLE_API_ROUTE, DELETE_DEPENSES_API_ROUTE, DELETE_DEPENSES__ARICLE_API_ROUTE, GET_BY_ID_DPENSES_API_ROUTE, GET_DEPENSES_BY_AGENCE_API_ROUTE, UPDATE_DEPENSES_API_ROUTE, UPDATE_DEPENSES__ARICLE_API_ROUTE } from "../../routes/api_routes"
import { addNewDepense, deleteCurrentDepense, setCurrentDepense, setCurrentDepenseDetails, setListDepense } from "../reducers/depenses"
import { store } from "../store/store"
import { editProductArticleId, setCurrenteFactureForUpdate } from "../reducers/factureArticles"

const dispatch = store.dispatch

const depenses = {
    getDepensesByAgence(agenceId) {
        return new Promise((resolve, reject) => {
            axios.get(GET_DEPENSES_BY_AGENCE_API_ROUTE + `${agenceId}/`)
                .then(response => {
                    if (response.status === 200) {
                        let depenses = response.data.data
                        dispatch(setListDepense(depenses))
                        resolve(depenses)
                    } else {
                        reject(response)
                    }
                })
                .catch(res => reject(res))
        })
    },
    getDepenseByID(id, dispatch, token) {
        return new Promise((resolve, reject) => {
            axios.get(GET_BY_ID_DPENSES_API_ROUTE + `${id}/`, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.status === 200) {
                        let depense = response.data
                        // console.log({ depense });
                        // !pour afficher les details d'une depense
                        dispatch(setCurrentDepenseDetails(depense))
                        // !pour afficher la nouvelle facture de depense
                        dispatch(setCurrenteFactureForUpdate(depense))
                        resolve(depense)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    createNewDepense(data, dispatch) {
        return new Promise((resolve, reject) => {
            axios.post(ADD_DEPENSES_API_ROUTE, data)
                .then(response => {
                    console.log({ response });
                    if (response.status === 201) {
                        let newDepense = response.data
                        // console.log({ newDepense });
                        dispatch(addNewDepense(newDepense))
                        // dispatch(setCurrentDepens(newDepense))
                        resolve(newDepense)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    updateDepense(id, data, dispatch) {
        return new Promise((resolve, reject) => {
            axios.put(UPDATE_DEPENSES_API_ROUTE + `${id}/`, data)
                .then(response => {
                    if (response.status === 200) {
                        let depense = response.data.data
                        // dispatch(updateDepenseSlice(depense))
                        console.log({ depense });
                        dispatch(setCurrentDepense(depense))
                        resolve(depense)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    deleteDepense(id, dispatch) {
        return new Promise((resolve, reject) => {
            axios.delete(DELETE_DEPENSES_API_ROUTE + `${id}/`)
                .then(response => {
                    if (response.status === 204) {
                        dispatch(deleteCurrentDepense(id))
                        resolve(id)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    updateArticle(id, data, dispatch) {
        return new Promise((resolve, reject) => {
            axios.put(UPDATE_DEPENSES__ARICLE_API_ROUTE + `${id}/`, data)
                .then(response => {
                    if (response.status === 200) {
                        let article = response.data.data
                        // console.log({ article });
                        // dispatch(updateDepenseSlice(article))
                        // dispatch(updateArticleByIdSlice(article))
                        resolve(article)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    createNewDepenseArticle(data, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.post(CREATE_NEW_DEPENSE_ARTICLE_API_ROUTE, data, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    console.log({ response });
                    if (response.status === 201) {
                        let newArticleCreated = response.data.data
                        dispatch(editProductArticleId(newArticleCreated))
                        resolve(newArticleCreated)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    deleteArticle(article, dispatch) {
        return new Promise((resolve, reject) => {
            axios.delete(DELETE_DEPENSES__ARICLE_API_ROUTE + `${article.articleId}/`)
                .then(response => {
                    if (response.status === 204) {
                        resolve(article)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
}

export default depenses