import React, { useEffect, useState } from "react";
// import Modal from "react-modal";
import Modal from "../../components/Modal";
import { useSelector } from "react-redux";
// import { useForm } from "react-hook-form";
import ApiController from "../../redux/api_controller";
import { toast } from "react-toastify";
import BtnLoading from "../../components/BtnLoading";


const UpdateFournisseur = ({ modalIsOpen, setIsOpen, onClosed }) => {

  const currentUser = useSelector((state) => state.users.currentUser);
  const agenceList = useSelector((state) => state.agences.listAgence);
  const currentFournisseur = useSelector(
    (state) => state.fournisseurs.currentfournisseur
  );
  const [loading, setLoading] = useState(false)

  const [fournisseur, setFournisseur] = useState({
    adresse: '',
    agenceId: '',
    email: '',
    firstName: '',
    lastName: '',
    telephone: '',
    createdBy: currentUser.id,
  })


  useEffect(() => {
    setFournisseur(currentFournisseur)
  }, [currentFournisseur])

  const { adresse, agenceId, email, firstName, lastName, telephone, createdBy } = fournisseur || {
    adresse: '',
    agenceId: '',
    email: '',
    firstName: '',
    lastName: '',
    telephone: '',
    createdBy: currentUser.id,
  }

  function closeModal() {
    setIsOpen(false);
  }

  const handleChange = (e) => {
    setFournisseur({ ...fournisseur, [e.target.name]: e.target.value })
  }

  const submit = (e) => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append('firstName', firstName)
    formData.append('lastName', lastName)
    formData.append('email', email)
    formData.append('telephone', telephone)
    formData.append('adresse', adresse)
    formData.append('agenceId', agenceId)
    formData.append('createdBy', createdBy)

    let fournisseurId = currentFournisseur.id;

    ApiController.fournisseurs
      .updatefournisseur(fournisseurId, formData)
      .then((response) => {
        closeModal();
        toast.success("Client ajouté avec succés");
      })
      .catch((err) => {
        console.log("ERROR ==>", err);
        toast.error("veuillez remplir tous les champ !");
      })
      .finally(() => {
        setLoading(false)
      })
      ;
  };

  return (
    <Modal
      opened={modalIsOpen}
      title="Modifier ce fournisseur"
      className="modal-container"
      onClosed={closeModal}
    >
      <form className=" px-md-3 py-0 my-0" onSubmit={submit}>
        <div className="row my-md-3">
          <div className="col-12 col-md mx-md-3 ">
            <label className="add-client-input">
              Prénom
              <input
                type="text"
                className=""
                name="firstName"
                value={firstName}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="col-12 col-md mx-md-3">
            <label className="add-client-input">
              Nom
              <input
                type="text"
                className=""
                name="lastName"
                value={lastName}
                onChange={handleChange}
              />
            </label>
          </div>
        </div>
        <div className="row my-md-3">
          <div className="col-12 col-md mx-md-3 ">
            <label className="add-client-input">
              Email
              <input
                type="email"
                className=""
                name="email"
                value={email}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="col-12 col-md mx-md-3 add-client-input">
            <label className="add-client-input">
              Mobile
              <input
                type="text"
                className=""
                value={telephone}
                name="telephone"
                onChange={handleChange}
              />
            </label>
          </div>
        </div>
        <div className="row my-md-3">
          <div className="col-12 col-md mx-md-3 add-client-input">
            <label htmlFor="">
              Selectionnez une agence
              <select

                name="agenceId"
                value={agenceId}
                onChange={handleChange}
              >
                {/* <option value={agenceId}>{agenceId}</option> */}
                {agenceList.map((item) => (
                  <option key={item.id} value={item.id}>{item.nomAgence}</option>
                ))}
              </select>

            </label>
          </div>
          <div className="col-12 col-md mx-md-3 ">
            <label className="">
              Adresse
              <input
                type="text"
                className=""
                name="adresse"
                value={adresse}
                onChange={handleChange}
              />
            </label>
          </div>
        </div>
        <div className="mt-4">
          <BtnLoading bgColor="#00BEC9" color="#FFFFFF" right={30} type="submit" loading={loading} text="Enregistrer" />
        </div>
      </form>
    </Modal>
  );
};

export default UpdateFournisseur;


