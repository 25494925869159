import { store } from "../store/store"
import axios from 'axios'
import { addNewClient, deleteCurrentClient, pushToClientsList, setCurrentClient, setListClients, updateClient } from "../reducers/clients"
const { UPDATE_CLIENT_API_ROUTE, GET_CLIENT_BY_ID_API_ROUTE, GET_CLIENT_BY_PROPRIETAIRE_API_ROUTE, ADD_CLIENT_API_ROUTE, DELETE_CLIENT_API_ROUTE, GET_CLIENT_BY_AGENCE_API_ROUTE } = require("../../routes/api_routes")

const dispatch = store.dispatch

const clients = {
    getClientsList(id) {
        return new Promise((resolve, reject) => {
            axios.get(GET_CLIENT_BY_PROPRIETAIRE_API_ROUTE + `${id}/`)
                .then(response => {
                    if (response.status === 200) {
                        let clients = response.data
                        dispatch(setListClients(clients))
                        resolve(clients)
                    } else {
                        reject(response)
                    }
                })
                .catch(res => reject(res))
        })
    },
    getClientsListByAgence(idsAgence, createdBy) {
        /**
         * !This api call is different to others because it gets all clients based on the agenceId.
         */
        idsAgence.forEach(agenceId => {
            // console.log({ agenceId });
            return new Promise((resolve, reject) => {
                axios.get(GET_CLIENT_BY_AGENCE_API_ROUTE + `${agenceId}/`)
                    .then(response => {
                        if (response.status === 200) {
                            let clients = response.data.data
                            /**
                             * ! Instead of seting directly the clientList from the client reducer, we just have to push in this this
                             * !Then we filter to get only clients that are not created by "Proprietaire"
                            */
                            const clientThatAreNotAddedByProprietaire = clients.filter(client => {
                                return client.createdBy !== createdBy
                            })
                            dispatch(pushToClientsList(clientThatAreNotAddedByProprietaire))
                            resolve(clients)
                        } else {
                            reject(response)
                        }
                    })
                    .catch(res => reject(res))
            })
        })
    },
    getClientsByAgence(agenceId) {
        return new Promise((resolve, reject) => {
            axios.get(GET_CLIENT_BY_AGENCE_API_ROUTE + `${agenceId}`)
                .then(response => {
                    if (response.status === 200) {
                        let clients = response.data.data
                        dispatch(setListClients(clients))
                        resolve(clients)
                    } else {
                        reject(response)
                    }
                })
                .catch(res => reject(res))
        })
    },
    registerClient(data) {
        return new Promise((resolve, reject) => {
            axios.post(ADD_CLIENT_API_ROUTE, data)
                .then(response => {
                    if (response.status === 201) {
                        let client = response.data.data
                        console.log({ client });
                        dispatch(addNewClient(client))
                        resolve(client)
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },

    getClientById(id) {
        return new Promise((resolve, reject) => {
            axios.get(GET_CLIENT_BY_ID_API_ROUTE + `${id}/`)
                .then(response => {
                    if (response.status === 200) {
                        let clients = response.data.data
                        dispatch(setCurrentClient(clients))
                        resolve(clients)
                    } else {
                        reject(response)
                    }
                })
                .catch(res => reject(res))
        })
    },
    updateClient(clientId, data, despatch) {
        return new Promise((resolve, reject) => {
            axios.put(UPDATE_CLIENT_API_ROUTE + `${clientId}/`, data)
                .then(response => {
                    if (response.status === 200) {
                        let client = response.data.data
                        // console.log({ client });
                        dispatch(updateClient(client))
                        resolve(client)
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },
    deleteClient(clieni_id) {
        return new Promise((resolve, reject) => {
            axios.delete(DELETE_CLIENT_API_ROUTE + `${clieni_id}`)
                .then(response => {
                    if (response.status === 204) {
                        resolve(response)
                        dispatch(deleteCurrentClient(clieni_id))
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },
}
export default clients