import React, { useEffect, useState } from "react";
import { AddAgence } from "./AddAgence";
import { useDispatch, useSelector } from "react-redux";
import ApiController from "../../redux/api_controller";
import { AiFillPlusCircle } from "react-icons/ai";
import ResourceDataMapperWithEmptyMessage from "../../components/shared/ResourceDataMapperWithEmptyMessage";
import AgenceCardItem from "./components/AgenceCardItem";
import { cloneElement } from "react";
import { setCurrentAgence } from "../../redux/reducers/agences";

const AgencesPage = () => {
  const currentUser = useSelector(state => state.users.currentUser)
  const userId = currentUser.id
  const agenceList = useSelector(state => state.agences.listAgence)
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  function openAddModal() {
    setAddModalIsOpen(true);
  }

  useEffect(() => {
    // setLoading(true)
    ApiController?.agences?.getAgenceList(Number(userId)).finally(() => setLoading(false))
  }, [])

  const setCurrentAgenceFunc = (agence) => {
    dispatch(setCurrentAgence(agence))
  }

  const ClonedAgenceCardItem = ({ agence }) => cloneElement(<AgenceCardItem agence={agence} />, { setCurrentAgenceFunc })

  return (
    <div className="agencesContainer container-fluid p-0">
      <AddAgence modalIsOpen={addModalIsOpen} setIsOpen={setAddModalIsOpen} />
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="agences-title">Agences</h4>
        <button
          className="btn-add btn-resize"
          type="button"
          onClick={openAddModal}
        >
          <AiFillPlusCircle className="fs-4" />
        </button>
      </div>
      <p className="agences-para mt-2">
        Créez, consultez et gérez vos agences ou boutiques partout où vous
        êtes.
      </p>
      <div className="">
        <div className="row ">
          <ResourceDataMapperWithEmptyMessage
            isLoading={loading}
            resourceItem={ClonedAgenceCardItem}
            resourceData={agenceList}
            resourceName="agence"
            emptyMessage="Pas d'agenges"
          />
        </div>
      </div>
    </div>
  );
};

export default AgencesPage;
