import axios from 'axios'
import { editAgences, setCurrentAgence } from '../reducers/agences'
import { addNewgerant, blockGerant, resetCurrentgerant, setListgerants, unblockGerant } from '../reducers/gerants'
import { store } from '../store/store'

const { GET_GERANT_API_ROUTE, ADD_GERANT_API_ROUTE, BLOCK_GERANT_API_ROUTE, UNBLOCK_GERANT_API_ROUTE, GET_GERANT_BYID_API_ROUTE, UPDATE_AGENCE_API_ROUTE, UPDATE_GERANT_BYID_API_ROUTE } = require("../../routes/api_routes")

const dispatch = store.dispatch
const gerants = {
  getgerantsList(id) {
    return new Promise((resolve, reject) => {
      axios.get(GET_GERANT_API_ROUTE + `${id}`)
        .then(response => {
          // console.log({ response });
          if (response.status === 200) {
            let gerants = response.data.data
            dispatch(setListgerants(gerants))
            resolve(gerants)
          } else {
            reject(response)
          }
        })
        .catch(res => reject(res))
    })
  },
  registerGerant(data) {
    return new Promise((resolve, reject) => {
      axios.post(ADD_GERANT_API_ROUTE, data)

        .then(response => {
          if (response.status === 201) {
            let gerant = response.data.data
            dispatch(addNewgerant(gerant))
            resolve(gerant)
          } else {
            reject(response)
          }
        })
        .catch(error => reject(error))
    })
  },
  getGerantById(gerant_id){
    return new Promise((resolve,reject)=>{
        axios.get(GET_GERANT_BYID_API_ROUTE +`${gerant_id}/`)
        .then(response => {
            if(response.status ===200){
                let gerant = response.data
                dispatch(resetCurrentgerant(gerant))
                resolve(gerant)
            }else{
                reject(response)
            }
        })
        .catch(error => reject(error))
    })
},
  blockGerantRequest(gerantId) {
    axios
      .put(BLOCK_GERANT_API_ROUTE + `${gerantId}/`)
      .then((response) => {
        console.log(response)
        dispatch(blockGerant(response.data));
      })
      .catch((error) => {
        console.log(error)
      });
  },
  UnblockGerantRequest(gerantId) {
    axios
      .put(UNBLOCK_GERANT_API_ROUTE + `${gerantId}/`)
      .then((response) => {
        console.log(response)
        dispatch(unblockGerant(response.data));
      })
      .catch((error) => {
        console.log(error)
      });
  },
  updategerant(gerantId, data){
      return new Promise((resolve,reject)=>{
          axios.put(UPDATE_GERANT_BYID_API_ROUTE + `${gerantId}/`,data)
          .then(response => {
              if(response.status ===200){
                let gerant = response.data.data
                dispatch(setCurrentAgence({
                  ...gerant.agence,
                  agenceId: gerant.agenceId,
                  gerant: {
                    fullName: gerant.fullName,
                    email: gerant.email,
                    phone: gerant.phone,
                    adresse: gerant.adresse
                  }
                }))
                  resolve(gerant)
              }else{
                  reject(response)
              }
          })
          .catch(error => reject(error))
      })
  },
  // deletegerant(gerant_id){
  //     return new Promise((resolve,reject)=>{
  //         axios.delete(`${gerant_API_ROUTE + gerant_id}/`)
  //         .then(response => {
  //             if(response.status ===204){
  //                 resolve(response)
  //             }else{
  //                 reject(response)
  //             }
  //         })
  //         .catch(error => reject(error))
  //     })
  // },
}
export default gerants