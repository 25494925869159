import React from 'react'
import ResourceDataMap from '../../../components/shared/ResourceDataMap'
import { useSelector } from 'react-redux'

const SelectGerant = ({ onChange }) => {
    // !on recupere que les gerants qui n'ont pas encore d'agence
    const listGerants = useSelector(state => state.gerants.listgerant.filter(gerant => !gerant.agenceId))
    return (
        <div className="d-flex align-items-center mb-2">
            <select
                className="form-select me-2"
                onChange={onChange}
            >
                <option value="">Affecter à un gérant</option>
                <ResourceDataMap
                    resourceItem={({ gerant }) => <option value={gerant.id}>{gerant.fullName}</option>}
                    resourceName="gerant"
                    resourceData={listGerants}
                />
            </select>
        </div>
    )
}

export default SelectGerant