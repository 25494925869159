import { PDFExport } from '@progress/kendo-react-pdf'
import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import { BsPhone } from 'react-icons/bs'
import { HiOutlineLocationMarker, HiOutlineMail } from 'react-icons/hi'
import { DEFAULT_IMAGE_API } from '../../../routes/api_routes'
import { useAgence } from '../../../hooks/useAgence'

export const FactureAImprimer = React.forwardRef((props, ref) => {
    const { agence } = useAgence()
    return (
        <div
            // style={{ minHeight: '100vh' }}
            id='facture-a-imprimer'
            className='row m-3 p-4'
            ref={ref} >
            {/* ********************** entreprise **************** */}
            <div className='row'>
                <div className="col-6 text-start">
                    <img
                        src={`${DEFAULT_IMAGE_API}${agence.logo}`}
                        alt="logo entreprise"
                        className="border-ligth text-start h-70git me-3"
                        onError={e => e.target.src = 'https://www.pngitem.com/pimgs/m/150-1503945_transparent-user-png-default-user-image-png-png.png'}
                        style={{ width: '100px' }}

                    />
                    <p className='fw-bold'>{props.nomAgence}</p>
                </div>
                <div className="col-6 text-end">
                    <p className='p-0 m-0'><span className='fw-bold'>Date Facture:</span> {props.factureDate}</p>
                    {/* <p className='p-0 m-0'><span className='fw-bold'>Numero vente:</span> vte-25</p> */}
                    {/* <p className='p-0 m-0'></p> */}
                </div>
            </div>

            <div className='row my-5 mt-2 text-start'>
                <div className='col-6 p-3 ps-0'>
                    <p className='fs-6'><HiOutlineLocationMarker />  {props.agenceAdresse}</p>
                    <p className='fs-6 my-3'> <HiOutlineMail /> {props.agenceEmail}</p>
                    <p className='fs-6'> <BsPhone /> {props.agencePhone}</p>
                </div>
                <div style={{ backgroundColor: '#EFEDED', color: 'black' }} className='col-6 p-3'>
                    <h6 className='fw-bold'>{props.factureFor.toUpperCase()}</h6>
                    <p style={{ fontSize: '14px' }}>Nom: {props.clientFullName}</p>
                    <p style={{ fontSize: '14px' }}>Telephone: {props.clientPhone}</p>
                </div>
            </div>

            <div className='table-a-imprimer'>
                <BootstrapTable
                    striped
                    keyField='table'
                    data={props.dataTable || []}
                    columns={props.columns}
                />
            </div>
            {/* <div>
                    <p className='text-end'>
                        <span className='pt-3 p-2 shadow-sm border'>
                            <span className='total fs-3 fw-bold '>Total {total} XOF </span>
                            <span className='total fs-6 fw-light me-5 mb-5'>({venteTableFormat?.length} article{venteTableFormat?.length > 1 && 's'})</span>
                        </span>
                    </p>
                </div> */}
            <div>
                <div className='d-flex justify-content-end mt-0'>
                    <div style={{ minWidth: '220px' }} className='p-2 shadow-sm border mt-1 text-end'>
                        <span className='total fs-5 fw-bold '>Total {props.totalTransaction} XOF </span>
                        <span className='total fs-6 fw-light me-3 mb-5'>({props.dataTable?.length} article{props.dataTable?.length > 1 && 's'})</span>
                    </div>
                </div>
            </div>

            <p className='mt-5 text-start'>Signature </p>
        </div>

    );
});
