export const DEFAULT_PATH = "*";

/* ===================== AUTH PATHS =================== */
export const LOGIN_PATH = "/login";
export const REGISTER_PATH = "/register";
export const MDPOUBLIE_PATH = "/mdpoublier";
export const RECUPMDP_PATH = "/recupmdp";
export const REINITMDP_PATH = "/reinitmdp";

/* ===================== HOME PATH =================== */
export const ACCUEIL_PATH = "/accueil";

/* ===================== AGENCES PATHS =================== */
export const AGENCES_PATH = "/agences";
export const AGENCES_DETAIL_PATH = "/agences/:idAgence/:nomAgence/"; // + {idAgence/agenceName}
export const ADD_AGENCE_PATH = "/add-agence";

/* ===================== CLIENTS PATHS =================== */
export const CLIENTS_PATH = "clients";
/* ===================== CLIENTS PATHS =================== */
export const CATEGORIES_PATH = "/categories";

/* ===================== GERANT PATHS =================== */
export const GERANT_PATH = "/gerants";
export const AJOUTGERANT_PATH = "/ajoutergerants";
export const DETAILGERANT_PATH = "/gerants/:id/";

/* ===================== FOURNISSEURS PATHS =================== */
export const FOURNISSEURS_PATH = "fournisseurs";

/* ===================== DEPENSE PATHS =================== */
export const DEPENSE_PATH = "depenses";
export const NEW_DEPENSE_PATH = "create";
export const UPDATE_DEPENSE_PATH = "update";
export const DETAILS_DEPENSE_PATH = 'details'

/* ===================== VENTES PATHS =================== */
export const VENTES_PATH = "ventes";
export const NEW_VENTE_PATH = "create";
export const UPDATE_VENTE_PATH = "update";
export const DETAILS_VENTEE_PATH = 'details'
export const REDIRECT_TO_EDIT_VENTE = AGENCES_DETAIL_PATH + VENTES_PATH + UPDATE_VENTE_PATH

/* ===================== STOCK PATHS =================== */
export const STOCK_PATH = "/stock";

/* ===================== INVENTAIRE PATHS =================== */
export const INVENTAIRE_PATH = "/inventaires";

/* ===================== BILAN PATHS =================== */
export const BILAN_PATH = "/bilan";

/* ===================== PARAMETRE PATHS =================== */
export const PRODUITS_PATH = "products";

/* ===================== PARAMETRE PATHS =================== */
export const PARAMETRE_PATH = "/parametres";
export const AGENCE_PARAMETRE_PATH = "parametres";
