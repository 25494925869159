import React from "react";
import { useForm } from "react-hook-form";
import Modal from "../../components/Modal";
import { useSelector } from "react-redux";
import ApiController from "../../redux/api_controller";
import { toast } from "react-toastify";
import BtnLoading from "../../components/BtnLoading";
import { useState } from "react";

export const AddAgence = ({ modalIsOpen, setIsOpen, }) => {
  const currentUser = useSelector(state => state.users.currentUser)
  const { register, reset, formState: { errors }, handleSubmit, setError } = useForm()
  const [loading, setLoading] = useState(false)

  function closeModal() {
    reset()
    setIsOpen(false);
  }

  const submit = (data) => {
    setLoading(true)
    const newData = {
      ...data, proprietaireId: currentUser.id
    }
    ApiController.agences.registerAgence(newData).then(() => {
      closeModal()
      toast.success('Ajout réuissi !')
    })
    .catch((err) => {
      if (err.response.status === 400) {
        if (err.response.data.message.email) {
            return setError("email", { type: 'custom', message: "L'email est déjà pris" })
        }
        if (err.response.data.message.phone) {
              return setError("phone", { type: 'custom', message: "Le numéro de téléphone est déjà pris" })
          }

      }
  })
  .finally(() => {
      setLoading(false)
  })
  }

  return (
    <>

      <Modal
        opened={modalIsOpen}
        title="Créer une agence"
        className="modal-container"
        onClosed={closeModal}
      >
        <form className="py-0 my-0" onSubmit={handleSubmit(submit)}>
          <div className="row my-md-3">
            <div className="col-12 col-md-6 mt-3">
              <label className="add-client-input">
                Nom de l'agence
                <input type="text" className="" {...register("nomAgence", { required: 'nomAgence' })} />
              </label>
            </div>
            <div className="col-12 col-md-6 mt-3">
              <label className="add-client-input">
                Téléphone
                <input type="text" className="" {...register("phone")} />
                {errors.phone && <span className='text-error--danger'>{errors.phone.message}</span>}

              </label>
            </div>
            <div className="col-12 col-md-6 mt-3">
              <label className="add-client-input">
                Email
                <input type="email" className="" {...register("email")} />
                {errors.email && <span className='text-error--danger'>{errors.email.message}</span>}
              </label>
            </div>
            <div className="col-12 col-md-6 mt-3 add-client-input">
              <label className="add-client-input">
                Adresse
                <input type="text" className="" {...register("addresse", { required: 'addresse' })} />
              </label>
            </div>
          </div>
          <p className="text-end mt-4">
            <BtnLoading bgColor="#00BEC9" color="#FFFFFF" right={30} type="submit" loading={loading} text="Enregistrer" />
          </p>
        </form>
      </Modal>



      {/* <Modal
      opened={modalIsOpen}
      title="Créer une agence"
      className="modal-container"
      onClosed={closeModal}
    >
     <form className=" px-md-3 py-0 my-0" onSubmit={handleSubmit(submit)}>
          <div className="row m-0 p-0">
            <p className="col text-end fw-bold fs-4">
              <span
                className="close border-dark rounded px-2 custom-mouse-clickable"
                onClick={closeModal}
              >
                X
              </span>
            </p>
          </div>
          <div className="row my-md-3">
            <div className="col-12 col-md-6 mt-3">
              <label className="add-client-input">
                Nom de l'agence
    
                <input type="text" className="" {...register("nomAgence",{required:'nomAgence'})}/>
              </label>
            </div>
            <div className="col-12 col-md mx-md-3">
              <label className="add-client-input">
                Telephone
                <input type="text" className="" {...register("phone",{required:'phone'})}/>
              </label>
            </div>
          </div>
          <div className="row my-md-3">
            <div className="col-12 col-md-6 mt-3">
              <label className="add-client-input">
                Email
                <input type="email" className="" {...register("email",{required:'email'})}/>
              </label>
            </div>
            <div className="col-12 col-md-6 mt-3add-client-input">
              <label className="add-client-input">
                Addresse
                <input type="text" className="" {...register("addresse",{required:'addresse'})}/>
              </label>
            </div>
          </div>
          <div className="row my-md-3">
            
           
          </div>
          <p className="text-end mt-4">
            <button
              type="submit"
              className="custom-button-secondary add  py-2 px-3 mx-3 mt-3"
            >
              Enregistrer
            </button>
          </p>
        </form>
    </Modal> */}
      {/* <Modal
      opened={modalIsOpen}
      title="Creer un nouveau client"
      className="modal-container"
      onClosed={closeModal}
      >
        <form className=" px-md-3 py-0 my-0" onSubmit={handleSubmit(submit)}>
          <div className="row m-0 p-0">
            <p className="col-9 fs-4 px-3">Créer une agence</p>
            <p className="col text-end fw-bold fs-4">
              <span
                className="close border-dark rounded px-2 custom-mouse-clickable"
                onClick={closeModal}
              >
                X
              </span>
            </p>
          </div>
          <div className="row my-md-3">
            <div className="col-12 col-md-6 mt-3">
              <label className="add-client-input">
                Nom de l'agence
    
                <input type="text" className="" {...register("nomAgence",{required:'nomAgence'})}/>
              </label>
            </div>
            <div className="col-12 col-md mx-md-3">
              <label className="add-client-input">
                Telephone
                <input type="text" className="" {...register("phone",{required:'phone'})}/>
              </label>
            </div>
          </div>
          <div className="row my-md-3">
            <div className="col-12 col-md-6 mt-3">
              <label className="add-client-input">
                Email
                <input type="email" className="" {...register("email",{required:'email'})}/>
              </label>
            </div>
            <div className="col-12 col-md-6 mt-3add-client-input">
              <label className="add-client-input">
                Addresse
                <input type="text" className="" {...register("addresse",{required:'addresse'})}/>
              </label>
            </div>
          </div>
          <div className="row my-md-3">
            
           
          </div>
          <p className="text-end mt-4">
            <button
              type="submit"
              className="custom-button-secondary add  py-2 px-3 mx-3 mt-3"
            >
              Enregistrer
            </button>
          </p>
        </form>
      </Modal> */}
    </>
  );
};
