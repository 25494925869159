import axios from 'axios'
import { setCommandesList } from '../reducers/commandes'
import { store } from '../store/store'

const { COMMANDE_API_ROUTE } = require("../../routes/api_routes")

const dispatch = store.dispatch
const commandes = {
    getCommandesList(){
        return new Promise((resolve,reject)=>{
            axios.get(COMMANDE_API_ROUTE)
            .then(response => {
                console.log(response);
                if(response.status === 200){
                    let commandes = response.data
                    dispatch(setCommandesList(commandes))
                    resolve(commandes)
                }else{
                    reject(response)
                }
            })
            .catch(res => reject(res))
        })
    },
    registerCommande(data){
        return new Promise((resolve,reject)=>{
            axios.post(COMMANDE_API_ROUTE,data)
            .then(response => {
                console.log(response);
                if(response.status ===200){
                    let commande = response.data
                    // dispatch(addNewMesure(mesure))
                    resolve(commande)
                }else{
                    reject(response)
                }
            })
            .catch(error => reject(error))
        })
    },
    updateCommande(data,id){
        return new Promise((resolve,reject)=>{
            axios.put(`${COMMANDE_API_ROUTE}/${id}`,data)
            .then(response => {
                console.log(response);
                if(response.status ===200){
                    let commande = response.data
                    // dispatch(setCurrentMesure(mesure))
                    resolve(commande)
                }else{
                    reject(response)
                }
            })
            .catch(error => reject(error))
        })
    },
    deleteCommande(id){
        return new Promise((resolve,reject)=>{
            axios.delete(`${COMMANDE_API_ROUTE + id}/`)
            .then(response => {
                if(response.status ===204){
                    resolve(response)
                }else{
                    reject(response)
                }
            })
            .catch(error => reject(error))
        })
    },
}
export default commandes