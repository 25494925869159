import React, { useState } from 'react'
import DataDisplayTemplate from '../../components/DataDisplayTemplate';
import UpdateVente from './UpdateVente';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { RiDeleteBinLine } from 'react-icons/ri';
import { tooltip_options } from '../../utils/tooltip_options';
import { FiEdit } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IoMdEye } from 'react-icons/io';
import { filterventesList, setCurrentVenteDetails, setCurrentvente } from '../../redux/reducers/ventes';
import { DETAILS_VENTEE_PATH, NEW_VENTE_PATH, UPDATE_VENTE_PATH } from '../../utils/navigation_paths';
import ConfirmAction from '../../components/ConfirmAction';
import ApiController from '../../redux/api_controller';
import { toast } from 'react-toastify';
import { setFactureSelectedClient, setIsFactureForDepense, setIsUpdatingFacture } from '../../redux/reducers/factureArticles';
import { useEffect } from 'react';

const VenteHomePage = () => {
    const [updateModalIsOpen, setUpdateModalIsOpen] = useState(false);
    const { allListVentes, listVente } = useSelector((state) => state.ventes);
    // console.log(listVente)
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false)
    const [selectedVente, setSelectedVente] = useState(null)

    // console.log({ allListVentes });

    useEffect(() => {
        dispatch(setIsFactureForDepense(false))
        dispatch(setIsUpdatingFacture(false))
    }, [])

    const showDeleteModal = (row) => {
        setOpenDeleteConfirmation(true)
        setSelectedVente(row)
    }

    const deleteVenteFunc = () => {
        ApiController.ventes.deletevente(selectedVente.id).then(() => {
            toast.success("Vente supprimée avec succés")
            setOpenDeleteConfirmation(false)
        })
    }

    const navigateToEditVente = (row) => {
        dispatch(setCurrentvente(row))
        dispatch(setFactureSelectedClient(null))
        dispatch(setIsUpdatingFacture(true))
        if (row.client) {
            dispatch(setFactureSelectedClient({ value: row.client.id, label: `${row.client.firstName} ${row.client.lastName}` }))
        }
        navigate(UPDATE_VENTE_PATH)
    }

    const showDetailsModal = (prod) => {
        dispatch(setCurrentvente(prod))
        console.log({ prod });
        // !reinitialiser le currenteVenteDetails avant d'y acceder
        dispatch(setCurrentVenteDetails({}))
        navigate(DETAILS_VENTEE_PATH)
    }


    const showActionButtons = (cell, row) => {
        return (
            <div >
                <IoMdEye
                    style={{ cursor: 'pointer' }}
                    data-tip data-for="detailsClientTip"
                    className='custom-mouse-clickable mx-3 fs-5 custom-eye-icon'
                    onClick={() => showDetailsModal(row)}
                />
                    <FiEdit
                        style={{ cursor: 'pointer' }}
                        data-tip data-for="editClientTip"
                        className='custom-mouse-clickable mx-3 fs-5 custom-edit-icon'
                        onClick={() => navigateToEditVente(row)}
                />
                    <RiDeleteBinLine
                        style={{ cursor: 'pointer' }}
                        data-tip data-for="deleteClientTip"
                        className='custom-mouse-clickable mx-3 fs-5 custom-delete-icon'
                        onClick={() => showDeleteModal(row)} 
                />
                <ReactTooltip id="detailsClientTip" {...tooltip_options}  >
                    Détails
                </ReactTooltip>
                <ReactTooltip id="editClientTip" {...tooltip_options}  >
                    Modifier
                </ReactTooltip>
                <ReactTooltip id="deleteClientTip" {...tooltip_options}  >
                    Supprimer
                </ReactTooltip>
            </div>
        )
    }


    const columns = [
        {
            dataField: "id",
            text: "Numéro",
            headerClasses: 'custom-table-column text-white col-1',
            formatter: (cell, row) => <p
                className=''
            //  onClick={() => showDetailsModal(row)}
            >{row.id}</p>
        },
        {
            dataField: "created_at",
            text: "Date",
            headerClasses: 'custom-table-column text-white col-2',
            formatter: (cell, row) => {
                var selected_date = new Date(row.created_at)
                return (
                    <p
                        className=''
                    // onClick={() => showDetailsModal(row)}
                    >
                        {selected_date?.toLocaleDateString('fr-SN')}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {selected_date?.toLocaleTimeString('fr-SN', { hour: 'numeric', minute: 'numeric' })}
                    </p>
                )
            }
        },
        {
            dataField: "client",
            text: "Client",
            headerClasses: 'custom-table-column text-white col',
            formatter: (cell, row) => <p
                className=''
                onClick={() => showDetailsModal(row)}
            >{row.client ? row.client.firstName + ' ' + row.client.lastName : "Pas de client "}</p>
        },
        {
            dataField: "montantTotal",
            text: "Montant",
            headerClasses: 'custom-table-column text-white col-2',
            formatter: (cell, row) => {
                // let montant_total = row?.list_products?.reduce((prev, prod) => {
                //   return prev + prod.total
                // }, 0);
                return <p
                    className=''
                // onClick={() => showDetailsModal(row)}
                >{row.montantTotal + ' XOF'}</p>
            }
        },
        {
            dataField: "emis",
            text: "Payé",
            headerClasses: 'custom-table-column text-white col-1',
            formatter: (cell, row) => <p
                className=''
            // onClick={() => showDetailsModal(row)}
            >{row.status === 'ENCOURS' ? 'Non' : 'Oui'}</p>
        },
        {
            dataField: "action",
            text: "Actions",
            headerClasses: 'custom-table-column text-white col-2',
            formatter: showActionButtons
        },
    ];


    return (
        <div className="p-0">
            <ConfirmAction
                modalText="Suppression vente"
                text="Cette action est irréversible!"
                modalIsOpen={openDeleteConfirmation}
                cancel={() => setOpenDeleteConfirmation(false)}
                closeModal={() => setOpenDeleteConfirmation(false)}
                validate={deleteVenteFunc}
            />
            {/* <AddVente modalIsOpen={addModalIsOpen} setIsOpen={setAddModalIsOpen} /> */}
            {updateModalIsOpen && (
                <UpdateVente
                    modalIsOpen={updateModalIsOpen}
                    setIsOpen={setUpdateModalIsOpen}
                />
            )}



            <DataDisplayTemplate
                isDataFetching={false}
                onChange={(e) => dispatch(filterventesList(e.target.value))}
                title="Ventes"
                description="Créez, consultez et gérez vos enregistrements de ventes. "
                link={NEW_VENTE_PATH}
                // onClick={() => navigate(NEW_VENTE_PATH)}
                allData={allListVentes}
                data={listVente}
                columns={columns}
                messageOnEmpty="Pas de ventes"

            />
        </div>
    );
}

export default VenteHomePage