import { createSlice } from "@reduxjs/toolkit/"

let initialState = {
    currentCategory: {},
    listCategories: [],
    listCategoriesAll: []
}
const categorySlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        setCurrentCategory: (state, { payload }) => {
            state.currentCategory = payload
        },
        setCategoriesList: (state, { payload }) => {
            state.listCategories = payload
            state.listCategoriesAll = payload
        },
        addNewCategory: (state, {payload}) => {
            state.listCategories = [...state.listCategoriesAll, payload]
            state.listCategoriesAll = [...state.listCategoriesAll, payload]
        },
        resetCurrentCategory: (state, { payload }) => {
            state.currentCategory = {}
        },
        filterCategoriesBySearch: (state, { payload }) => {
            state.listCategories = state.listCategoriesAll.filter(item => {
                let fields = payload.fields.map((field) => item[field])

                let data = fields.join(' ').toLowerCase()
                return data.includes(payload.value.toLowerCase())
            })
        },
    }
})

export const { setCurrentCategory, setCategoriesList, addNewCategory, resetCurrentCategory, filterCategoriesBySearch } = categorySlice.actions
export default categorySlice.reducer