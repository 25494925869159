import { store } from "../store/store"
import axios from 'axios'
import { addNewfournisseur, deleteCurrentfournisseur, pushToFournisseurList, setCurrentfournisseur, setListfournisseurs, updatefournisseur } from "../reducers/fournisseurs"
const { GET_FOURNISSEUR_BY_PROPRIETAIRE_API_ROUTE, ADD_FOURNISSEUR_API_ROUTE, DELETE_FOURNISSEUR_API_ROUTE, UPDATE_FOURNISSEUR_API_ROUTE, GET_FOURNISSEUR_BY_ID_API_ROUTE, GET_FOURNISSEURS_BY_AGENCE_API_ROUTE } = require("../../routes/api_routes")

const dispatch = store.dispatch

const fournisseurs = {
    getfournisseurList(id) {
        return new Promise((resolve, reject) => {
            axios.get(GET_FOURNISSEUR_BY_PROPRIETAIRE_API_ROUTE + `${id}/`)
                .then(response => {
                    if (response.status === 200) {
                        let fournisseurs = response.data
                        dispatch(setListfournisseurs(fournisseurs))
                        resolve(fournisseurs)
                    } else {
                        reject(response)
                    }
                })
                .catch(res => reject(res))
        })
    },
    getFournisseursListByAgence(idsAgence, createdBy) {
        /**
         * !This api call is different to others because it gets all fournisseurs based on the agenceId.
         */
        idsAgence.forEach(agenceId => {
            return new Promise((resolve, reject) => {
                axios.get(GET_FOURNISSEURS_BY_AGENCE_API_ROUTE + `${agenceId}/`)
                    .then(response => {
                        if (response.status === 200) {
                            let fournisseurs = response.data
                            /**
                             * ! Instead of seting directly the clientList from the client reducer, we just have to push in this this
                             * !Then we filter to get only fournisseurs that are not created by "Proprietaire"
                            */
                            const fournisseurThatAreNotAddedByProprietaire = fournisseurs.filter(client => {
                                return client.createdBy !== createdBy
                            })
                            // console.log({ fournisseurThatAreNotAddedByProprietaire });
                            dispatch(pushToFournisseurList(fournisseurThatAreNotAddedByProprietaire))
                            resolve(fournisseurs)
                        } else {
                            reject(response)
                        }
                    })
                    .catch(res => reject(res))
            })
        })
    },
    getFournisseursByAgence(agenceId) {
        return new Promise((resolve, reject) => {
            axios.get(GET_FOURNISSEURS_BY_AGENCE_API_ROUTE + `${agenceId}`)
                .then(response => {
                    if (response.status === 200) {
                        let fournisseurs = response.data.data
                        dispatch(setListfournisseurs(fournisseurs))
                        resolve(fournisseurs)
                    } else {
                        reject(response)
                    }
                })
                .catch(res => reject(res))
        })
    },
    registerfournisseur(data) {
        return new Promise((resolve, reject) => {
            axios.post(ADD_FOURNISSEUR_API_ROUTE, data)
                .then(response => {
                    if (response.status === 201) {
                        let fournisseur = response.data.data
                        dispatch(addNewfournisseur(fournisseur))
                        resolve(fournisseur)
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },

    getfournisseurById(id) {
        return new Promise((resolve, reject) => {
            axios.get(GET_FOURNISSEUR_BY_ID_API_ROUTE + `${id}/`)
                .then(response => {
                    if (response.status === 200) {
                        let fournisseurs = response.data.data
                        dispatch(setCurrentfournisseur(fournisseurs))
                        resolve(fournisseurs)
                    } else {
                        reject(response)
                    }
                })
                .catch(res => reject(res))
        })
    },
    updatefournisseur(fournisseurId, data, despatch) {
        return new Promise((resolve, reject) => {
            axios.put(UPDATE_FOURNISSEUR_API_ROUTE + `${fournisseurId}/`, data)
                .then(response => {
                    if (response.status === 200) {
                        let fournisseur = response.data.data
                        dispatch(updatefournisseur(fournisseur))
                        resolve(fournisseur)
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },
    deletefournisseur(fournisseurId) {
        return new Promise((resolve, reject) => {
            axios.delete(DELETE_FOURNISSEUR_API_ROUTE + `${fournisseurId}/`)
                .then(response => {
                    if (response.status === 204) {
                        resolve(response)
                        dispatch(deleteCurrentfournisseur(fournisseurId))
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },
}
export default fournisseurs