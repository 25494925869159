import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DataDisplayTemplate from "../../components/DataDisplayTemplate";
import ApiController from '../../redux/api_controller';


const GerantDetail = () => {
    const currentGertant = useSelector(state => state.gerants.currentgerant)
    
    const { id } = useParams();
    const [gerant, setGerant] = useState(null);
  
    useEffect(() => {
      ApiController.gerants.getGerantById(id)
        .then((response) => {
          setGerant(response.data);
        })
        .catch((error) => {
          console.log(error);
          alert(`Erreur lors de la récupération des informations du gérant ${id}.`);
        });
    }, [id]);
  
    if (!gerant) {
      return <div>Chargement en cours...</div>;
    }
    

  return (
    <div className="gerantsContainer">
    <DataDisplayTemplate
    //   onChange={(e) => dispatch(filterGerantsList(e.target.value))}
      title={`Transaction du gérant ${gerant.fullName}`}
    //   description="Créez et assignez des gérants à vos agences ou boutiques."
    //   onClick={openAddModal}
    //   btnText="Ajouter un gérant"
    //   allData={allListGerants}
    //   data={listGerants}
    //   columns={columns}
      messageOnEmpty="Pas de Gérants"
    />
  </div>
  )
}

export default GerantDetail