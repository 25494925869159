import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route } from "react-router-dom";
import {
  navigation_private_routes,
  navigation_public_routes,
} from "./routes/navigation_routes";
import { ToastContainer } from "react-toastify";
import RequierAuth from "./layouts/RequierAuth";
import PublicLayout from "./layouts/PublicLayout";

function App() {
  return (
    <div className="App container-fluid m-0 p-0">
      <Routes>
        {/* Private Routes */}
        <Route element={<RequierAuth />}>
          {navigation_private_routes.map((item, index) => {
            if (item.children) {
              return item.children.map((child, i) => {
                if (child.children) {
                  return child.children.map(childEl => {
                    return (
                      <Route path={item.path} element={item.element} key={i}>
                        <Route path={child.path} element={child.element} >
                          <Route path={childEl.path} element={childEl.element} />
                        </Route>
                      </Route>
                    )
                  })
                }
                return (
                  <Route path={item.path} element={item.element} key={i}>
                    <Route path={child.path} element={child.element} />
                  </Route>
                )
              })
            }
            return <Route path={item.path} element={item.element} key={index} />
          })}
        </Route>
        {/* Public routes */}
        <Route path="/" element={<PublicLayout />}>
          {navigation_public_routes.map((item, index) => (
            <Route path={item.path} element={item.element} key={index} />
          ))}
        </Route>
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
