import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { filterProductsNotAddedToCurrentFacture } from '../../redux/reducers/factureArticles'

const FilterProduct = () => {
    let inputRef = useRef(null)
    const dispatch = useDispatch()
    const [search, setSearch] = useState('')
    useEffect(() => {
        inputRef.current?.focus()
        /* --------------------------------------------------------------------
        ! On ajoute search comme dependance car au moment ou on fait le filtre
        ! le composant <NewFactureArticles /> rerend et son input est au focus
        ----------------------------------------------------------------------*/
    }, [search])
    return (
        <form className='mb-3 w-100'>
            <input
                ref={inputRef}
                value={search}
                onChange={(e) => {
                    setSearch(e.target.value)
                    dispatch(filterProductsNotAddedToCurrentFacture(e.target.value))
                }}
                type="serach"
                placeholder='Rechercher produit'
                className='form-control p-2'
            />
        </form>
    )
}

export default FilterProduct