import React from "react";
import Stat from "../../components/Stat";

function VenteKpi() {
  return (
    <div className="container-fluid">
      <h1 className="mb-3" style={{ fontWeight: 400, fontSize: 18 }}>
        Les donnees des differentes VENTES
      </h1>
      <Stat label={"Ventes"} />
    </div>
  );
}

export default VenteKpi;
