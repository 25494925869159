import React from "react";
import { AiOutlineLogout } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { clearCurrentUser } from "../../redux/reducers/users";
// import './sidebar.css'
import {
  sidebar_items_bottom,
  sidebar_items_top,
} from "../../utils/sidebar_items";

const SideBar = () => {
  let defaultItemClass = "item px-2 row m-0 p-0 align-items-center fw-600";
  let actifItemClass = "actif " + defaultItemClass;
  const dispatch = useDispatch()

  const logout = () => {
    dispatch(clearCurrentUser())
  }
  return (
    <div className="sidebar d-flex text-start pt-4 h-100 shadow">
      <div className="sidebar_items_top fs-90 row m-0 p-0">
        {sidebar_items_top.map((item, index) => (
          <NavLink
            key={index}
            to={item.targetPath}
            className={({ isActive }) =>
              isActive ? actifItemClass : defaultItemClass
            }
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "nowrap",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <div className="icon" style={{ width: 50 }}>
              {<item.icon className="icon" />}
            </div>
            <div
              className=""
              style={{
                position: "absolute",
                left: 50,
              }}
            >
              <span className="">{item.text}</span>
            </div>
          </NavLink>
        ))}
      </div>
      <div className="sidebar_items_bottom">
        {sidebar_items_bottom.map((item, index) => (
          <NavLink
            key={index}
            to={item.targetPath}
            className={({ isActive }) =>
              isActive ? actifItemClass : defaultItemClass
            }
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "nowrap",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <div className="icon" style={{ width: 50 }}>
              {<item.icon className="icon" />}
            </div>
            <div
              className=""
              style={{
                position: "absolute",
                left: 50,
              }}
            >
              <span className="">{item.text}</span>
            </div>
          </NavLink>
        ))}
        {/* <div onClick={logout} className="d-flex align-items-center px-4 fw-bold logout-btn-container">
          <AiOutlineLogout size={25} />
          <button className="btn" type="button">
            Deconnexion
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default SideBar;
