import { createSlice } from "@reduxjs/toolkit/"



const user = JSON.parse(localStorage.getItem('currentUser'))
const initialState = {
    currentUser: user ? user : null
}

const autSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCurrentUser: (state, { payload }) => {
            state.currentUser = payload
        },
        clearCurrentUser: (state) => {
            state.currentUser = null
            localStorage.clear()
        }

    }
})


export const { setCurrentUser, clearCurrentUser } = autSlice.actions
export default autSlice.reducer