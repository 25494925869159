import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import navigation from "../reducers/navigation";
import users from "../reducers/users";
import agences from "../reducers/agences";
import gerants from "../reducers/gerants";
import clients from "../reducers/clients";
import fournisseurs from "../reducers/fournisseurs";
import categories from "../reducers/categories";
import produits from "../reducers/products";
import ventes from "../reducers/ventes";
import depenses from "../reducers/depenses";
import kpis from "../reducers/kpis";
import proprietaire from "../reducers/proprietaire";
import factureArticles from "../reducers/factureArticles";

const persistConfig = {
  key: "root",
  storage,
};

const combinedReducer = combineReducers({
  factureArticles,
  proprietaire,
  users,
  gerants,
  agences,
  clients,
  fournisseurs,
  navigation,
  categories,
  produits,
  ventes,
  depenses,
  kpis,
});

const rootReducer = (state, action) => {
  if (action.type === "store/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
