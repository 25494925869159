import axios from "axios"
const { INSCRIPTION_PROPRIETAIRE, LOGIN_API_ROUTE, RESET_PASSWORD_API_ROUTE, UPDATE_USER_API_ROUTE, RESET_PASSWORD_API_ROUTE_SENT } = require("../../routes/api_routes")
const { setCurrentUser } = require("../reducers/users")
const Cookie = require("js-cookie")


const users = {
    updateUser(current_user, token, dispatch) {
        return new Promise((resolve, reject) => {
            axios.put(UPDATE_USER_API_ROUTE + `${current_user.id}`, current_user, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => {
                    if (response.status === 200) {
                        let user = response.data
                        let userData = {
                            id: current_user.id,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.email,
                            token: current_user.token,
                        }
                        localStorage.setItem('currentUser', JSON.stringify(userData))
                        dispatch(setCurrentUser({ ...current_user, ...user }))
                        resolve(user)
                    } else {
                        reject(response)
                    }
                })
                .catch(res => reject(res))
        })
    },
    resetPssword(data) {

        return new Promise((resolve, reject) => {
            axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
            axios.defaults.xsrfCookieName = "csrftoken";
            axios.defaults.withCredentials = true
            const csrftoken = Cookie.get('csrftoken')
            console.log(csrftoken)
            const formData = new FormData()
            formData.append('email', data.email)
            axios.post(RESET_PASSWORD_API_ROUTE, formData, { headers: { "csrftoken": csrftoken } },)
                .then(response => {
                    console.log("responet first", response)
                })
                .then(respone => {
                    axios.get(RESET_PASSWORD_API_ROUTE_SENT).then(res => console.log(res))
                    // axios.post(RESET_PASSWORD_API_ROUTE,data)
                    // if(response.data){
                    //     let user = response.data
                    //     resolve(user)
                    // }else{
                    //     reject(response)
                    // }
                })
                .catch(error => reject(error))
        })
    },
    registerUser(data) {
        return new Promise((resolve, reject) => {
            axios.post(INSCRIPTION_PROPRIETAIRE, data)
                .then(response => {
                    if (response.data) {
                        let user = response.data
                        resolve(user)
                    } else {
                        reject(response)
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    loginUser(data, dispatch) {
        return new Promise((resolve, reject) => {
            axios.post(LOGIN_API_ROUTE, data)
                .then(response => {
                    if (response.data.token) {
                        let user = response.data
                        let userData = {
                            id: user.id,
                            fullName: user.fullName,
                            token: user.token,
                            user_type: "proprietaire"
                        }
                        localStorage.setItem('currentUser', JSON.stringify(userData))

                        var bcrypt = require('bcryptjs');
                        var salt = bcrypt.genSaltSync(10);
                        var hash = bcrypt.hashSync(JSON.stringify(userData), salt);
                        localStorage.setItem('hash', hash)
                        dispatch(setCurrentUser(userData))
                        resolve(user)
                    } else {
                        reject(response)
                    }
                })
                .catch(err => reject(err))
        })
    },
    // changePassword(id,data,token){
    //     return new Promise((resolve,reject)=>{
    //         axios.put(CHANGE_USER_PASSWORD_API_ROUTE+`${id}`,data)
    //         .then(response => {
    //             console.clear();
    //             console.log(response);
    //         })
    //         .catch(err=>{
    //             console.log(err);
    //             reject(err)
    //         })
    //     })
    // },

    registerProprietaire(data) {
        return new Promise((resolve, reject) => {
            axios.post(INSCRIPTION_PROPRIETAIRE, data)
                .then(response => {
                    if (response.data) {
                        let user = response.data
                        resolve(user)
                    } else {
                        reject(response)
                    }
                })
                .catch(error => reject(error))
        })
    },
}
export default users