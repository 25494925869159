import { createSlice } from "@reduxjs/toolkit/"

let initialState = {
    allListVentes: [],
    listVente: [],
    currentVente: {},
    currentVenteDetails: []
}
const ventesSlice = createSlice({
    name: 'ventes',
    initialState,
    reducers: {
        setListventes: (state, { payload }) => {
            state.listVente = payload.sort((a, b) => new Date(a) - new Date(b))
            state.allListVentes = payload.sort((a, b) => new Date(a) - new Date(b))
        },
        addNewvente: (state, { payload }) => {
            state.listVente = [...state.allListVentes, payload].sort((a, b) => new Date(a) - new Date(b))
            state.allListVentes = [...state.allListVentes, payload].sort((a, b) => new Date(a) - new Date(b))
        },
        pushToventesList: (state, { payload }) => {
            state.listVente = [...state.allListVentes, ...payload].sort((a, b) => new Date(a) - new Date(b))
        },
        setCurrentvente: (state, { payload }) => {
            state.currentVente = payload

        },
        updatevente: (state, { payload }) => {
            state.currentVente = payload
            let listvente = state.allListVentes.filter(item => item.id !== payload.id)
            state.listVente = [...listvente, payload].sort((a, b) => new Date(a) - new Date(b))
        },
        setCurrentVenteDetails: (state, { payload }) => {
            // console.log({ 'currentVenteDetails:': payload });
            state.currentVenteDetails = payload
        },
        deleteCurrentvente: (state, { payload }) => {
            state.listVente = state.allListVentes.filter(item => item.id !== payload)
        },
        clearCurrentvente: (state) => {
            state.currentVente = {}
        },
        filterventesList: (state, { payload }) => {
            state.listVente = state.allListVentes.filter(item => {
                let data = [item.client?.firstName, item.client?.lastName, item.adresse, item.email, item.phone].join(' ').toLowerCase()
                return data.includes(payload.toLowerCase())
            }).sort((a, b) => new Date(a) - new Date(b))
        },
    }
})

export const { addNewvente, setListventes, setCurrentVenteDetails, setCurrentvente, deleteCurrentvente, updatevente, clearCurrentvente, pushToventesList, filterventesList } = ventesSlice.actions
export default ventesSlice.reducer