import { createSlice } from "@reduxjs/toolkit/";

let initialState = {
  listfournisseur: [],
  allFournisseur: [],
  currentfournisseur: {},
};
const fournisseurSlice = createSlice({
  name: "fournisseurs",
  initialState,
  reducers: {
    setListfournisseurs: (state, { payload }) => {
      state.listfournisseur = payload;
      state.allFournisseur = payload;
    },
    addNewfournisseur: (state, { payload }) => {
      state.listfournisseur = [...state.allFournisseur, payload];
      state.allFournisseur = [...state.allFournisseur, payload];
    },
    pushToFournisseurList: (state, { payload }) => {
      state.listfournisseur = [...state.allFournisseur, ...payload];
      state.allFournisseur = [...state.allFournisseur, ...payload];
    },
    setCurrentfournisseur: (state, { payload }) => {
      state.currentfournisseur = payload;
    },
    updatefournisseur: (state, { payload }) => {
      state.currentfournisseur = payload;
      let listfournisseur = state.allFournisseur.filter(
        (item) => item.id !== payload.id
      );
      state.listfournisseur = [...listfournisseur, payload].reverse();
    },
    deleteCurrentfournisseur: (state, { payload }) => {
      state.listfournisseur = state.allFournisseur.filter(
        (item) => item.id !== payload
      );
    },
    clearCurrentfournisseur: (state) => {
      state.currentfournisseur = {};
    },
    filterFournisseursList: (state, { payload }) => {
      state.listfournisseur = state.allFournisseur.filter((item) => {
        let data = [
          item.firstName,
          item.lastName,
          item.adresse,
          item.email,
          item.phone,
        ]
          .join(" ")
          .toLowerCase();
        return data.includes(payload.toLowerCase());
      });
    },
  },
});

export const {
  addNewfournisseur,
  setListfournisseurs,
  setCurrentfournisseur,
  deleteCurrentfournisseur,
  updatefournisseur,
  clearCurrentfournisseur,
  pushToFournisseurList,
  filterFournisseursList,
} = fournisseurSlice.actions;
export default fournisseurSlice.reducer;
